



import React, { useState, useEffect, useCallback, useRef} from "react";


import classNames from "classnames";

import { getTimeDisplay, ellipseText } from "../../utils/tools";

export function NewsItem({ item, addClass }) {


	const openNews = (l0, l1) => {

        let u = l0 && l0.url !== undefined ? l0.url : l1.url;
        window.open(
            u,
            "news"
            // 'toolbar=0,status=0,width=600,height=700,left=0,top=100'
        );

    }

	const placeholderImage = require(`../../media/media_default_image.jpg`);

	const onImageError = (e) => {
		e.target.src = placeholderImage
	}

    return (

		<div className={classNames("news-item", addClass && addClass)}>
			<div className="item-header">
				<div className="data-panel transparent flex-row align center">
					<img className="profile-image" src={require(`../../icons/news/logo_${item.username}.png`)}/>
					<p style={{marginLeft:7}}>{item.channelTitle}</p>
				</div>
				<div className="data-value">
				{getTimeDisplay(item.created)}
				{/*{getTimeDiff(now,item.created) >= 1/60 ? " ago" : ""}*/}
				</div>
			</div>

			<p style={{fontSize:'16px',padding: item.og && item.og.ogDescription !== undefined ? '3px 11px 3px 11px' : '3px 11px 33px 11px'}}>{item.message}</p>

			{item.og && item.og.ogDescription !== undefined ? (
				<p style={{fontSize:14, opacity:.8,padding: '3px 11px 33px 11px'}}>{ellipseText(item.og.ogDescription)}</p>
			):null}

			{ item.linkUrls.length > 0 || (item.webpage && item.webpage.url !== undefined) ? (
				<div className="web-link">
					<div className="coin-links">
						{ item.linkUrls.map((l, i) => (
							<div key={`nl-${i}`} onClick={() => openNews(l, item.webpage)} style={{maxWidth:200}} className="coin-link">
								{ l.source && l.source !== "null" ? (
									<img onError={onImageError} className="profile-image" style={{marginRight:7,maxHeight:27}} src={require(`../../icons/news/logo_${l.source}.png`)}/>
								):null}

								{/*<p>{item.linkUrls[0].source}</p>*/}
								<div className="svg-icon">
									<div className="icon-button-link"/>

									{/*<svg width="1024px" height="1024px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
										<path d="M698.026667 597.333333C701.44 569.173333 704 541.013333 704 512 704 482.986667 701.44 454.826667 698.026667 426.666667L842.24 426.666667C849.066667 453.973333 853.333333 482.56 853.333333 512 853.333333 541.44 849.066667 570.026667 842.24 597.333333M622.506667 834.56C648.106667 787.2 667.733333 736 681.386667 682.666667L807.253333 682.666667C766.293333 753.066667 701.013333 807.68 622.506667 834.56M611.84 597.333333 412.16 597.333333C407.893333 569.173333 405.333333 541.013333 405.333333 512 405.333333 482.986667 407.893333 454.4 412.16 426.666667L611.84 426.666667C615.68 454.4 618.666667 482.986667 618.666667 512 618.666667 541.013333 615.68 569.173333 611.84 597.333333M512 851.626667C476.586667 800.426667 448 743.68 430.506667 682.666667L593.493333 682.666667C576 743.68 547.413333 800.426667 512 851.626667M341.333333 341.333333 216.746667 341.333333C257.28 270.506667 322.986667 215.893333 401.066667 189.44 375.466667 236.8 356.266667 288 341.333333 341.333333M216.746667 682.666667 341.333333 682.666667C356.266667 736 375.466667 787.2 401.066667 834.56 322.986667 807.68 257.28 753.066667 216.746667 682.666667M181.76 597.333333C174.933333 570.026667 170.666667 541.44 170.666667 512 170.666667 482.56 174.933333 453.973333 181.76 426.666667L325.973333 426.666667C322.56 454.826667 320 482.986667 320 512 320 541.013333 322.56 569.173333 325.973333 597.333333M512 171.946667C547.413333 223.146667 576 280.32 593.493333 341.333333L430.506667 341.333333C448 280.32 476.586667 223.146667 512 171.946667M807.253333 341.333333 681.386667 341.333333C667.733333 288 648.106667 236.8 622.506667 189.44 701.013333 216.32 766.293333 270.506667 807.253333 341.333333M512 85.333333C276.053333 85.333333 85.333333 277.333333 85.333333 512 85.333333 747.52 276.48 938.666667 512 938.666667 747.52 938.666667 938.666667 747.52 938.666667 512 938.666667 276.48 747.52 85.333333 512 85.333333Z">
										</path>
									</svg>*/}
								</div>
							</div>
						))}

					</div>
				</div>
			):null}

		</div>




    )
}
