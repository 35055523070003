import React, { useEffect, useState, useCallback, useMemo } from "react";

import { useWallet } from "../../hooks/useWallet";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";
import { useCore } from "../../hooks/useCore";

import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";
import { PortfolioToken } from "../../components/Dex/PortfolioToken";
import { TokenDataTabs } from "../../components/Dex/TokenDataTabs";
import { DexPairItem } from "../../components/Dex/DexPairItem";

import Blockies from 'react-blockies';
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js';
import { ContractAddressToolbar } from "../../components/Dex/ContractAddressToolbar";

import classNames from "classnames";


import { calculateOriginalFromPercent, stableTokens } from "../../utils/tools";
import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";
import { supportedChainsPortfolio } from "../../utils/data";


// import {
//   helloWorldContract,
//   connectWallet,
//   updateMessage,
//   loadCurrentMessage,
//   getCurrentWalletConnected,
// } from "./util/interact.js";


var parse = require('html-react-parser');

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");


export function Portfolio({ noTitle=false }) {

    let {
        walletAddress,
        chainId,
        fetchTokenBalances,
        chainTotalBalances,
        user,
        ethPrice,
        ethData,
        solPrice,
        solData,
        balanceEth,
        balancesNative,
        balancesAlchemy,
    } = useWallet();

    let {
        handleSidebarRight
    } = useCore();

    let {
        fetchPortfolioTokens,
        portfolioTokens,
        tokenPairs,
        token,
        ethFactor
    } = useDex();

    const navigator = useNavigator();

    let { contractAddress, handleContractAddress, screens } = useNavigator();

    const [ totalBalance, setTotalBalance ] = useState(0);
    const [ totalDefi, setTotalDefi] = useState(0);
    const [ totalEth, setTotalEth] = useState(0);
    const [ totalBase, setTotalBase] = useState(0);
    const [ totalSol, setTotalSol] = useState(0);
    const [ gettingBalances, setGettingBalances] = useState(false);
    const [ totalPercent, setTotalPercent ] = useState([0,0,0,0]);




    useEffect(() => {

        if(walletAddress && portfolioTokens){
            fetchPortfolioTokens(walletAddress);
        }

        // handleSidebarRight(false)

    }, [ ]);



    useEffect(() => {
        let b = 0;
        if(chainTotalBalances){
            for(var key in chainTotalBalances){
                if(chainTotalBalances[key]){
                    b += chainTotalBalances[key];
                }

            }
        }
        setTotalBalance(b);

    }, [ chainTotalBalances ]);

    const getPortfolioBalances = async () => {

        let ba = "";
        let bcount = 0;

        for(var j=0;j<portfolioTokens.length;j++){
            if(bcount > 0){
                ba+=",";
            }
            // if(balancesAlchemy[portfolioTokens[j].caToken] == undefined){
            let pt = portfolioTokens[j];
            if(pt.pair){
                console.log(pt)
                let chn = pt.pair.chainId;
                ba += pt.address + `:${chn}`;
                bcount+=1;
            }
            
            // }
        }

        await fetchTokenBalances(walletAddress, ba);

    }

    useMemo( () => {

        if(walletAddress && !portfolioTokens){
            fetchPortfolioTokens(walletAddress);
        }


        if(walletAddress && portfolioTokens && !gettingBalances){
            setGettingBalances(true);
            getPortfolioBalances();
        }

        if(portfolioTokens){

            // all totals in eth.
            var totalDefi = 0;
            var totalEth = 0;
            var totalBase = 0;
            var totalSol = 0;
            
            

            let tokenEthValues = {
                "total": [],
                "eth": [],
                "base": [],
                "sol": []
            };
                
            let tokenPercents = {
                "total": [],
                "eth": [],
                "base": [],
                "sol": []
            };

            let ethValues = {
                "total":{
                    m5: 0,
                    h1: 0,
                    h6: 0,
                    h24: 0
                },
                "eth":{
                    m5: 0,
                    h1: 0,
                    h6: 0,
                    h24: 0
                },
                "base":{
                    m5: 0,
                    h1: 0,
                    h6: 0,
                    h24: 0
                },
                "sol":{
                    m5: 0,
                    h1: 0,
                    h6: 0,
                    h24: 0
                }
            }

            for(var key in supportedChainsPortfolio){
                let chain = supportedChainsPortfolio[key];
                let ethValue = 0;
                console.log(balancesNative[key],chain.name)
                if(chain.name == "ethereum"){
                    ethValue = balancesNative[key];
                    totalEth += ethValue;
                }else if(chain.name == "base"){
                    ethValue = balancesNative[key];
                    totalBase += ethValue;
                }else if(chain.name == "solana"){
                    ethValue = (balancesNative[key] * solPrice)/ethPrice;
                    totalSol += balancesNative[key];
                }

                let tv = {
                    m5: ethValue,
                    h1: ethValue,
                    h6: ethValue,
                    h24: ethValue
                }
                let tp = {};
                if(chain.name == "ethereum" || chain.name == "base"){
                    tp = {...ethData}
                }else if(chain.name == "solana"){
                    tp = {...solData}
                }
                for(var key in tv){
                    ethValues[chain.key][key] += tv[key]
                }

                tokenEthValues[chain.key].push(tv);
                tokenPercents[chain.key].push(tp);

                tokenEthValues.total.push(tv);
                tokenPercents.total.push(tp);

                ethValues.total.m5 += tv.m5;
                ethValues.total.h1 += tv.h1;
                ethValues.total.h6 += tv.h6;
                ethValues.total.h24 += tv.h24;
            }


            for(var i=0;i<portfolioTokens.length;i++){

                let tb = 0;
                let ethValue = 0;
                let token = portfolioTokens[i];
   
                tb = token.pair?.data && balancesAlchemy[token.address] !== undefined ? parseInt(balancesAlchemy[token.address].balance) / (10**token.decimals) : 0;

                if(token.pair?.chainId == "ethereum"){
                    ethValue = !token.pair.data ? 0 : token.pair.data.quoteToken.symbol == "WETH" ? tb*token.pair.data.priceNative*ethFactor : (tb*token.pair.data.priceUsd*ethFactor) / ethPrice;
                    totalEth += ethValue;
                }else if(token.pair?.chainId == "base"){
                    ethValue = !token.pair.data ? 0 : token.pair.data.quoteToken.symbol == "WETH" ? tb*token.pair.data.priceNative*ethFactor : (tb*token.pair.data.priceUsd*ethFactor) / ethPrice;
                    totalBase += ethValue;
                }else if(token.pair?.chainId == "solana"){
                    ethValue = !token || !token.pair || !token.pair.data ? 0 : (tb * token.pair.data.priceUsd)/ethPrice;
                    let sv = !token || !token.pair || !token.pair.data ? 0 : (tb * token.pair.data.priceUsd)/solPrice;
                    totalSol += sv;
                }
    
                let tv = {
                    // m5: calculateOriginalFromPercent(ethValue, token.pair.data.priceChange.m5),
                    // h1: calculateOriginalFromPercent(ethValue, token.pair.data.priceChange.h1),
                    // h6: calculateOriginalFromPercent(ethValue, token.pair.data.priceChange.h6),
                    // h24: calculateOriginalFromPercent(ethValue, token.pair.data.priceChange.h24)
                    m5: ethValue,
                    h1: ethValue,
                    h6: ethValue,
                    h24: ethValue
                }

                let tp = {
                    // eth: ethValue,
                    m5: token.pair.data.priceChange.m5,
                    h1: token.pair.data.priceChange.h1,
                    h6: token.pair.data.priceChange.h6,
                    h24: token.pair.data.priceChange.h24
                }

                if(token.pair?.chainId == "ethereum"){
                    ethValues.eth.m5 += tv.m5;
                    ethValues.eth.h1 += tv.h1;
                    ethValues.eth.h6 += tv.h6;
                    ethValues.eth.h24 += tv.h24;
                    tokenEthValues.eth.push(tv);
                    tokenPercents.eth.push(tp);
                }else if(token.pair?.chainId == "base"){
                    ethValues.base.m5 += tv.m5;
                    ethValues.base.h1 += tv.h1;
                    ethValues.base.h6 += tv.h6;
                    ethValues.base.h24 += tv.h24;
                    tokenEthValues.base.push(tv);
                    tokenPercents.base.push(tp);
                }else if(token.pair?.chainId == "solana"){
                    ethValues.sol.m5 += tv.m5;
                    ethValues.sol.h1 += tv.h1;
                    ethValues.sol.h6 += tv.h6;
                    ethValues.sol.h24 += tv.h24;
                    tokenEthValues.sol.push(tv);
                    tokenPercents.sol.push(tp);
                }

                tokenEthValues.total.push(tv);
                tokenPercents.total.push(tp);

                ethValues.total.m5 += tv.m5;
                ethValues.total.h1 += tv.h1;
                ethValues.total.h6 += tv.h6;
                ethValues.total.h24 += tv.h24;

            }

            let pPercents = {
                total:{},
                eth:{},
                base:{},
                sol:{}
            };
            let times = ["m5","h1","h6","h24"];

            console.log(ethValues,tokenEthValues)
            for(var i=0;i<times.length;i++){
                let tpercents = 0;
                for(var key in tokenEthValues){
                    let tcpercents = 0;
                    for(var j=0;j<tokenEthValues[key].length;j++){
                        let ptemp = (tokenEthValues[key][j][times[i]]/ethValues[key][times[i]]) * tokenPercents[key][j][times[i]];
                        tpercents += ptemp;
                        tcpercents += ptemp;
                        // console.log(ptemp)
                    }
                    pPercents[key][times[i]] = tcpercents;
                }
               
                // pPercents.total[times[i]] = tpercents;
            }

            
            
            // add stable token balances usdc, usdt and dai
            for(var key in stableTokens){
                for(var subkey in stableTokens[key]){
                    if(balancesAlchemy[stableTokens[key][subkey]]?.balance !== undefined
                    && Number(balancesAlchemy[stableTokens[key][subkey]].balance) > 0){
                        let decimals = subkey == "dai" ? 18 : 6;
                        if(key=="ethereum"){
                            totalEth += (Number(balancesAlchemy[stableTokens[key][subkey]].balance)/10**decimals)/ethPrice;
                        }else if(key=="base"){
                            totalBase += (Number(balancesAlchemy[stableTokens[key][subkey]].balance)/10**decimals)/ethPrice;
                        }else if(key=="solana"){
                            totalSol += (Number(balancesAlchemy[stableTokens[key][subkey]].balance)/10**decimals)/solPrice;
                        }
                    }
                }
               
            }

            totalDefi = totalEth + totalBase + (totalSol*solPrice)/ethPrice;

            console.log(pPercents)
            setTotalPercent(pPercents);
            setTotalEth(totalEth);
            setTotalDefi(totalDefi);
            setTotalSol(totalSol);
            setTotalBase(totalBase);

        }

    }, [ walletAddress, portfolioTokens, balancesAlchemy ]);

    useEffect(() => {

        const interval = setInterval(getCurrentPortfolioDex, 60000);
        return () => clearInterval(interval);

    }, [ portfolioTokens ]);



    const getCurrentPortfolioDex = useCallback( async () => {

        fetchPortfolioTokens(walletAddress);

    }, [walletAddress, portfolioTokens]);

    const onDexTokenClick = async (t) => {
        console.log(t);
    }

    return (

            <div style={{borderRadius:0}} className="data-panel portfolio-container">
                <div style={ noTitle ? {height:'auto',paddingTop:22,paddingBottom:17} : {} } className="portfolio-header">
                    { !noTitle ? (
                        <h2>Portfolio</h2>
                    ):null}

                    <div className="meta-tag-primary">
                        totals
                    </div>
                
                    <div className="data-panel flex-column">
                        <div style={{marginTop:11,marginLeft:11}} className="flex-row data-value currency">
                            <div className="flex-row eth-balance">
                                <h6 style={{opacity:.5,marginRight:17}}>Total</h6>
                                <p style={{fontSize:'1.3rem'}} className="usd-balance">

                                    <>
                                        <span className="small">Ξ</span>{totalDefi < .5 ? totalDefi.toFixed(4) : totalDefi.toFixed(4)}
                                    </>

                                </p>
                                <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                <p style={{marginLeft:17,fontSize:'1.3rem'}} className="usd-balance"> {parse(formatCurrency((totalDefi * ethPrice), "USD"))}</p>
                            </div>
                        </div>
                        
                        {totalPercent.total?.m5 !== undefined ? (
                            <TokenDataTabs
                                addClass="bookend"
                                addStyle={{maxWidth:244, paddingLeft:0,marginLeft:11}}
                                title="price"
                                values={[totalPercent.total.m5,
                                    totalPercent.total.h1,
                                    totalPercent.total.h6,
                                    totalPercent.total.h24]}
                                valuesFormat={['percent','percent','percent','percent']}
                                labels={['5m','1h','6h','24h']}
                            />

                        ):null}

                    </div>
                    
                    <div style={{marginTop:11,paddingTop:11,position:"relative"}} className="data-panel flex-column">
                        
                        <div style={{
                            position:'absolute',
                            top:13,
                            right:13
                        }}>
                            <img data-tip={`ethereum`} style={{filter:"grayscale(1)",transform:'scale(1.33)'}} className="chain-icon" src={require(`../../icons/logo_metamask.png`)}/>
                        </div>
                        
                        <div className="flex-row">
                            <div className="data-panel opaque">
                                <div style={{alignItems: 'center'}} className="data-row align center">
                                    <Blockies
                                        seed={walletAddress !== undefined ? walletAddress+"love" : "love"}
                                        size={7}
                                        scale={5}
                                        // color="#dfe"
                                        // bgColor="#ffe"
                                        // spotColor="#abc"
                                        className="identicon"
                                    />
                                    <ContractAddressToolbar
                                    // label="pair"
                                    walletAddress={true}
                                    type="address"
                                    small="true"
                                    ca={`${walletAddress}`}/>
                                    {/* <p style={{marginLeft:7}}>{walletAddress}</p> */}
                                </div>
                            </div>
                        </div>


                        <div style={{marginTop:11,paddingTop:11}} className="data-panel flex-column">
                            <div style={{marginTop:3,marginLeft:11}} className="flex-row data-value currency">
                                <div className="flex-row eth-balance">
                                    
                                    <img data-tip={`ethereum`} style={{filter:"grayscale(1)",marginRight:17}} className="chain-icon" src={require(`../../icons/logo_chain_ethereum.png`)}/>
                                
                                    <p style={{fontSize:'1rem'}} className="usd-balance">

                                        <>
                                            <span className="small">Ξ</span>{totalEth < .5 ? totalEth.toFixed(4) : totalEth.toFixed(4)}
                                        </>

                                    </p>
                                    <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                    <p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance"> {parse(formatCurrency((totalEth * ethPrice), "USD"))}</p>
                                </div>
                            </div>
                            { totalPercent.eth !== undefined ? (
                                <TokenDataTabs
                                    addClass="bookend"
                                    addStyle={{maxWidth:244, paddingLeft:0,marginLeft:11}}
                                    title="price"
                                    values={[
                                        totalPercent.eth.m5,
                                        totalPercent.eth.h1,
                                        totalPercent.eth.h6,
                                        totalPercent.eth.h24]}
                                    valuesFormat={['percent','percent','percent','percent']}
                                    labels={['5m','1h','6h','24h']}
                                />
                            ):null}    

                        </div>         
                            
                        { totalBase > 0 ? (
                            <div style={{marginTop:11}} className="data-panel flex-column">
                                <div style={{marginTop:11,marginLeft:11}} className="flex-row data-value currency">
                                    <div className="flex-row eth-balance">
                                        
                                        <img data-tip={`base`} style={{filter:"",marginRight:17}} className="chain-icon" src={require(`../../icons/logo_chain_base.png`)}/>
                                    
                                        <p style={{fontSize:'1rem'}} className="usd-balance">

                                            <>
                                                <span className="small">Ξ</span>{totalBase < .5 ? totalBase.toFixed(4) : totalBase.toFixed(4)}
                                            </>

                                        </p>
                                        <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                        <p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance"> {parse(formatCurrency((totalBase * ethPrice), "USD"))}</p>
                                    </div>
                                </div>
                                { totalPercent.base !== undefined ? (
                                    <TokenDataTabs
                                        addClass="bookend"
                                        addStyle={{maxWidth:244, paddingLeft:0,marginLeft:11}}
                                        title="price"
                                        values={[totalPercent.base.m5,
                                            totalPercent.base.h1,
                                            totalPercent.base.h6,
                                            totalPercent.base.h24]}
                                        valuesFormat={['percent','percent','percent','percent']}
                                        labels={['5m','1h','6h','24h']}
                                    />
                                ):null}
                            </div>
                        ):null}
                        

                        
                        

                    </div>
                    

                    { user && user.walletAddressSolana ? (
                        <div style={{marginTop:11,paddingTop:11}} className="data-panel flex-column">
                            <div className="flex-row">
                                <div className="data-panel opaque">
                                    <div style={{alignItems: 'center'}} className="data-row align center">
                                        <Blockies
                                            seed={user.walletAddressSolana ? user.walletAddressSolana+"love" : "love"}
                                            size={7}
                                            scale={5}
                                            // color="#dfe"
                                            // bgColor="#ffe"
                                            // spotColor="#abc"
                                            className="identicon"
                                        />
                                        <ContractAddressToolbar
                                        // label="pair"
                                        chain="solana"
                                        type="address"
                                        small="true"
                                        ca={`${user.walletAddressSolana}`}/>
                                        {/* <p style={{marginLeft:7}}>{walletAddress}</p> */}
                                    </div>
                                </div>
                            </div>


                            <div style={{marginLeft:11,marginTop:11}} className="flex-row data-value currency">
                                <div className="flex-row eth-balance">
                                    
                                    <img data-tip={`base`} style={{filter:"grayscale(1)",marginRight:17}} className="chain-icon" src={require(`../../icons/logo_chain_solana.png`)}/>
                                
                                    <p style={{fontSize:'1rem'}} className="usd-balance no-prefix">

                                        <>
                                            {totalSol < .5 ? totalSol.toFixed(4) : totalSol.toFixed(4)}
                                        </>

                                    </p>
                                    <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                    <p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance"> {parse(formatCurrency((totalSol * solPrice), "USD"))}</p>
                                </div>
                            </div>

                            { totalPercent.sol !== undefined ? (
                                <TokenDataTabs
                                    addClass="bookend"
                                    addStyle={{maxWidth:244, paddingLeft:0,marginLeft:11}}
                                    title="price"
                                    values={[totalPercent.sol.m5,
                                        totalPercent.sol.h1,
                                        totalPercent.sol.h6,
                                        totalPercent.sol.h24]}
                                    valuesFormat={['percent','percent','percent','percent']}
                                    labels={['5m','1h','6h','24h']}
                                />
                            ):null}

                        </div>
                    ):null}
                    

                    
                   

                    
                    

                </div>
                <div style={{}} className="portfolio-tokens">

                    <div style={{pointerEvents:'none'}} className="portfolio-token-item data-panel">
                        <div className="meta-tag-primary">
                            native ETH
                        </div>                   
                        <div style={{justifyContent:'flex-start',alignItems:'center',padding:'3px 13px'}} className="data-item row full">
                            {/* <img className="chain-icon large" src={require(`../../icons/logo_chain_${chains[chainId].image}.png`)}/> */}
                            <img className="chain-icon large" src={require(`../../icons/logo_chain_ethereum.png`)}/>
                            <div style={{marginRight:17,marginLeft:11,minWidth:69}} className="data-item">
                                <div className="data-title">USD</div>
                                <div className={classNames("price data-value", )}>{parse(condenseZeros(formatCurrency(ethPrice, "USD")))}</div>
                            </div>

                            <TokenDataTabs
                                addClass="bookend"
                                addStyle={{minWidth:252,marginLeft:24}}
                                title="price"
                                values={[ethData.m5,
                                    ethData.h1,
                                    ethData.h6,
                                    ethData.h24]}
                                valuesFormat={['percent','percent','percent','percent']}
                                labels={['5m','1h','6h','24h']}
                            />

                        </div>

                    </div>

                    <div style={{paddingBottom:11,maxHeight:144,flexDirection:'column'}} className="token-balance-dex data-panel small">
                        <div style={{textAlign:'left'}} className="data-value currency">


                            <div style={{marginTop:4,justifyContent:"flex-start"}} className="flex-row justify">
                            <img data-tip={`ethereum`} style={{filter:"grayscale(1)"}} className="chain-icon" src={require(`../../icons/logo_chain_ethereum.png`)}/>
                                <p style={{fontSize:'1rem',marginBottom:0,marginLeft:7}} className="usd-balance">

                                    <>
                                        Ξ{balanceEth.toFixed(4)}
                                    </>


                                </p>
                                <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                <p style={{marginBottom:0,marginLeft:17,fontSize:'1rem'}} className="usd-balance"> {parse(formatCurrency((balanceEth*ethPrice).toFixed(2), "USD"))}</p>
                                {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}


                            </div>
                        </div>
                        { balancesNative["0x2105"] > 0 ? (
                            <div style={{marginTop:8,textAlign:'left'}} className="data-value currency">

                                <div style={{marginTop:0,justifyContent:"flex-start"}} className="flex-row justify">
                                    <img data-tip={`base`} style={{filter:""}} className="chain-icon" src={require(`../../icons/logo_chain_base.png`)}/>
                                    <p style={{fontSize:'1rem',marginBottom:0,marginLeft:7}} className="usd-balance">

                                        <>
                                            Ξ{balancesNative["0x2105"].toFixed(4)}
                                        </>


                                    </p>
                                    <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                    <p style={{marginBottom:0,marginLeft:17,fontSize:'1rem'}} className="usd-balance"> {parse(formatCurrency((balancesNative["0x2105"]*ethPrice).toFixed(2), "USD"))}</p>
                                    {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}


                                </div>
                            </div>
                        ):null}
                        

                    </div>
                    

                    { balancesNative["sol"] > 0 ? (
                        <>
                            <div style={{pointerEvents:'none'}} className="portfolio-token-item data-panel">
                                <div className="meta-tag-primary">
                                    native SOL
                                </div>
                                <div style={{justifyContent:'flex-start',alignItems:'center',padding:'3px 13px'}} className="data-item row full">
                                    {/* <img className="chain-icon large" src={require(`../../icons/logo_chain_${chains[chainId].image}.png`)}/> */}
                                    <img style={{filter:"grayscale(1)"}} className="chain-icon large" src={require(`../../icons/logo_chain_solana.png`)}/>
                                    <div style={{marginRight:17,marginLeft:11,minWidth:69}} className="data-item">
                                        <div className="data-title">USD</div>
                                        <div className={classNames("price data-value", )}>{parse(condenseZeros(formatCurrency(solPrice, "USD")))}</div>
                                    </div>

                                    <TokenDataTabs
                                        addClass="bookend"
                                        addStyle={{minWidth:252,marginLeft:24}}
                                        title="price"
                                        values={[solData.m5,
                                            solData.h1,
                                            solData.h6,
                                            solData.h24]}
                                        valuesFormat={['percent','percent','percent','percent']}
                                        labels={['5m','1h','6h','24h']}
                                    />

                                </div>

                            </div>


                            <div style={{paddingBottom:11,maxHeight:144,flexDirection:'column'}} className="token-balance-dex data-panel small">

                                <div style={{marginTop:8,textAlign:'left'}} className="data-value currency">

                                    <div style={{marginTop:0,justifyContent:"flex-start"}} className="flex-row justify">
                                        <img data-tip={`solana`} style={{filter:"grayscale(1)",transform:"scale(.88)",opacity:.8}} className="chain-icon" src={require(`../../icons/logo_chain_solana.png`)}/>
                                        <p style={{fontSize:'1rem',marginBottom:0,marginLeft:7}} className={classNames("usd-balance","no-prefix")}>

                                            <>
                                                {balancesNative["sol"].toFixed(4)}
                                            </>


                                        </p>
                                        <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                        <p style={{marginBottom:0,marginLeft:17,fontSize:'1rem'}} className="usd-balance"> {parse(formatCurrency((balancesNative["sol"]*solPrice).toFixed(3), "USD"))}</p>
                                        {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}


                                    </div>
                                </div>
                           
                            </div>
                                                        
                        </>
                    ):null}


                    
                        <>
                            <div style={{pointerEvents:'none',paddingTop:11,paddingBottom:11}} className="portfolio-token-item data-panel">
                                <div className="meta-tag-primary">
                                    stable coins
                                </div>
                                <div style={{justifyContent:'flex-start',alignItems:'center',padding:'3px 13px'}} className="data-item row full">
                                    {/* <img className="chain-icon large" src={require(`../../icons/logo_chain_${chains[chainId].image}.png`)}/> */}
                                    <img style={{}} className="chain-icon large" src={require(`../../icons/logo_usdc.png`)}/>
                                    <div style={{marginRight:25,marginLeft:11,minWidth:69}} className="data-item">
                                        <div className="data-title">USDC</div>
                                        {/* <div className={classNames("price data-value", )}>$1</div> */}
                                    </div>

                                    <img style={{}} className="chain-icon large" src={require(`../../icons/logo_usdt.png`)}/>
                                    <div style={{marginRight:17,marginLeft:11,minWidth:69}} className="data-item">
                                        <div className="data-title">USDT</div>
                                        {/* <div className={classNames("price data-value", )}>$1</div> */}
                                    </div>

                                    <img style={{}} className="chain-icon large" src={require(`../../icons/logo_dai.png`)}/>
                                    <div style={{marginRight:17,marginLeft:11,minWidth:69}} className="data-item">
                                        <div className="data-title">DAI</div>
                                        {/* <div className={classNames("price data-value", )}>$1</div> */}
                                    </div>

                                </div>

                            </div>


                            <div style={{paddingBottom:11,paddingTop:11,maxHeight:144,flexDirection:'row',alignItems:'flex-start'}} className="token-balance-dex data-panel small">
                                <div style={{minWidth:139}} className="flex-column">
                                    { Object.keys(stableTokens).map((key, i) => {
                                        if(balancesAlchemy[stableTokens[key].usdc] !== undefined && Number(balancesAlchemy[stableTokens[key].usdc].balance) > 0 ){
                                            return (
                                            
                                                <div style={{marginTop:3,marginBottom:3,justifyContent:"flex-start",display:"flex",flexDirection:"row",alignItems:"center"}} className="flex-row justify data-value currency">
                                                    <img data-tip={`${key}`} style={{filter:"",transform:"scale(.88)",opacity:.8}} className="chain-icon" src={require(`../../icons/logo_chain_${key}.png`)}/>
                                                    <p style={{marginBottom:0,marginLeft:17,fontSize:'1rem'}} className="usd-balance"> ${parse(formatNumberScale(Number(balancesAlchemy[stableTokens[key].usdc].balance)/10**6))}</p>
                                                    {/* <p style={{marginBottom:0,marginLeft:17,fontSize:'1rem'}} className="usd-balance "> <span style={{fontSize:14}} className="small mute">$</span>{(Number(balancesAlchemy[stableTokens[key].usdc].balance)/10**6).toFixed(2)}</p> */}
                                                    {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}
            
            
                                                </div>
                                            )
                                        }else{
                                            return null
                                        }
                                    
                                    })}

                                </div>


                                <div style={{minWidth:129}} className="flex-column">
                                    { Object.keys(stableTokens).map((key, i) => {
                                        if(balancesAlchemy[stableTokens[key].usdt] !== undefined && Number(balancesAlchemy[stableTokens[key].usdt].balance) > 0  ){
                                            return (
                                            
                                                <div style={{marginTop:3,marginBottom:3,justifyContent:"flex-start",display:"flex",flexDirection:"row",alignItems:"center"}} className="flex-row justify data-value currency">
                                                    <img data-tip={`${key}`} style={{filter:"",transform:"scale(.88)",opacity:.8}} className="chain-icon" src={require(`../../icons/logo_chain_${key}.png`)}/>
                                                    
                                                    <p style={{marginBottom:0,marginLeft:17,fontSize:'1rem'}} className="usd-balance"> ${parse(formatNumberScale(Number(balancesAlchemy[stableTokens[key].usdt].balance)/10**6))}</p>
                                                    {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}
            
            
                                                </div>
                                            )
                                        }else{
                                            return null
                                        }
                                    
                                    })}

                                </div>


                                <div className="flex-column">
                                    { Object.keys(stableTokens).map((key, i) => {
                                        if(balancesAlchemy[stableTokens[key].dai] !== undefined && Number(balancesAlchemy[stableTokens[key].dai].balance) > 0  ){
                                            return (
                                            
                                                <div style={{marginTop:3,marginBottom:3,justifyContent:"flex-start",display:"flex",flexDirection:"row",alignItems:"center"}} className="flex-row justify data-value currency">
                                                    <img data-tip={`${key}`} style={{filter:"",transform:"scale(.88)",opacity:.8}} className="chain-icon" src={require(`../../icons/logo_chain_${key}.png`)}/>
                                                    
                                                    <p style={{marginBottom:0,marginLeft:17,fontSize:'1rem'}} className="usd-balance"> ${parse(formatNumberScale(Number(balancesAlchemy[stableTokens[key].dai].balance)/10**18))}</p>
                                                    {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}
            
            
                                                </div>
                                            )
                                        }else{
                                            return null
                                        }
                                    
                                    })}

                                </div>
                                
                                
                           
                            </div>
                                                        
                        </>
                  

                    

                    <div style={{pointerEvents:'none',height:15,position:'relative',borderRadius:0}} className="data-panel">
                        <div className="meta-tag-primary left">
                            tokens
                        </div>
                    </div>

                    { user && portfolioTokens && (
                        <>

                            { portfolioTokens.filter(n => n.pair?.data).map((t,i) => (
                                <DexPairItem
                                    key={`folio${i}`}
                                    i={i}
                                    view="portfolio"
                                    hideRank={true}
                                    passClick={onDexTokenClick}
                                    removeOption="portfolio"
                                    pairData={t.pair?.data}
                                    token={t}
                                    pair={null}
                                    coreToken={token}
                                />

                            ))}
                        </>
                    )}
                    {/*<PortfolioToken token={t} key={i}/>*/}
                </div>
            </div>

    );
}


{/*







    async function mint() {
        setMinting(true);
        const myAccount = "0x57bAe5267E7bB04EAA7431C9f32E2C3445411ca8"; //  Test wallet two
        const price = "0.01"; // This is the price in ETH

        // let obj = {
        //     to: myAccount,
        //     from: account,
        //     value: web3.utils.toWei(price, "ether"), // Needs to be converted to Wei units
        //     gas: 85000, // Eth
        //     gasLimit: 100000
        // };
        //
        // console.log(obj)


        // const nonce = await library.eth.getTransactionCount(account, 'latest'); // nonce starts counting from 0

        await library.provider.request({
            method: "getTransactionCount",
            // params: [{ chainId: c.hex.length ? c.hex : chainData[c.name].params[0].chainId }],
            // params: ["latest"]
        });



        // const transaction = {
        //  'to': myAccount, // faucet address to return eth
        //  'from': account,
        //  'value': web3.utils.toWei(price, "ether"),
        //  'gas': 30000,
        //  'maxFeePerGas': 1000000108,
        //  'nonce': '500',
        //  // optional data field to send message or execute smart contract
        // };
        //
        // await library.provider.request({
        //     method: "sendTransaction",
        //     // params: [{ chainId: c.hex.length ? c.hex : chainData[c.name].params[0].chainId }],
        //     params: transaction
        // });


        // const signedTx = await web3.eth.accounts.signTransaction(transaction, PRIVATE_KEY);

        // await library.eth.sendTransaction(transaction, function(error, hash) {
        //     if (!error) {
        //       console.log("🎉 The hash of your transaction is: ", hash, "\n Check Alchemy's Mempool to view the status of your transaction!");
        //     } else {
        //       console.log("❗Something went wrong while submitting your transaction:", error)
        //     }
        // });












        // await window.ethereum.enable();

        // const sendHash = library.provider.sendTransaction(obj);
        // console.log('txnHash is ' + sendHash);
        //
        //
        // console.log(window.ethereum)

        // await library.eth.sendTransaction(obj, async (e, tx) => {
        //     if (e) {
        //         alert(`Something went wrong! Try switching accounts - ${e}`);
        //         console.log("ERROR->", e);
        //         setMinting(false);
        //     } else {
        //         setMinting(false);
        //     }
        // });
    }*/}
