import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";

import { useNavigator } from "../../hooks/useNavigator";

import { useDex } from "../../hooks/useDex";


import { TokenSymbolIcon } from "../Dex/TokenSymbolIcon";
import { DexPairCard } from "../Dex/DexPairCard";
import { TokenDataTabsInteractive } from "../Dex/TokenDataTabsInteractive";
import { TickerBlock } from "../../components/Dex/TickerBlock";
import { DexPairItem } from "../../components/Dex/DexPairItem";
import { DexPairItemList } from "../../components/Dex/DexPairItemList";
import { NewsItem } from "../../components/Social/NewsItem";
import ResizePanel from "react-resize-panel";

import toast from 'react-simple-toasts';

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";

import ReactTooltip from 'react-tooltip';

import classNames from "classnames";

import { getTimeDisplay, getTimeDiff, dataTimeLabels } from "../../utils/tools";


import { ReactComponent as Newspaper} from "../../icons/svgs/newspaper.svg";
import {ReactComponent as TrendingIcon} from '../../icons/svgs/trending.svg';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ListPresentation } from "../Dex/ListPresentation";

var parse = require('html-react-parser');

export function HomeMeta({ addClass, disabled=false, visible=false }) {

    const navigator = useNavigator();
    let { contractAddress, handleContractAddress, screens } = useNavigator();
    let {
        dexView,
        ethTrending,
        ethTrendingKey,
        handleEthTrendingKey,
        ethTrendingTokens,
        fetchEthTrendingTokens,
        token,
        homePartners,
        homeMeta,
        
        fetchHomeMeta,
        tokenList,
        listTokens,
        // editFilters,
        // handleEditFilters,
        // tokenFilters,
    } = useDex();

    const {
        walletAddress,
        featuredMetaLimit,
        adminWallet
    } = useWallet();

    const {
        fullscreen,
        toggleFullscreen,
        modalVisible,
        handleModalVisible,
        qrModal,
        handleQrModal,
        handleQrurl,
        handleModalTitle,
        news,
        pageSize
    } = useCore();

    const [listDataTime, setListDataTime] = useState('h1');
    const [trendingChannel, setTrendingChannel] = useState('bt');

    const [fmlDataTime, setFmlDataTime ] = useState('h24');
    const [fmlDataSort, setFmlDataSort ] = useState('desc');

    const [trendingDataTime, setTrendingDataTime] = useState('h24');
    const [trendingDataSort, setTrendingDataSort] = useState('desc');

    useEffect(() => {
       
        // if(!homeMetaList){
            fetchHomeMeta();
        // }


        ReactTooltip.rebuild();
    }, [] );


    useEffect(() => {
        let interval = null;
        if(adminWallet){
            interval = setInterval(getHomeMeta, 60000);
        }else{
            interval = setInterval(getHomeMeta, 60000);
        }
        // const interval = setInterval(getHomeMeta, 60000);
        return () => clearInterval(interval);
        

    }, [ ]);

    const getHomeMeta = () => {
        fetchHomeMeta();
    }

    // useEffect(() => {
    //
    //
    //     if( ethTrending ){
    //         fetchEthTrendingTokens();
    //     }
    //
    // }, [ ethTrending, ethTrendingKey ]);

    // const passTagClick = (e, tag) => {
    //     console.log(e.target, tag);
    // }

    const onListTimeClick = (v) => {
        console.log(v);
        setListDataTime(v);
    }


    const onFmlTimeClick = (v) => {
        setFmlDataTime(v);
        
    }

    const onTrendingTimeClick = (v) => {
        setTrendingDataTime(v);
    }

    const toggleTrendingDataSort = () => {
        let fmls = '';
        if(trendingDataSort == "desc"){
            fmls = "asc";
        }else{
            fmls = "desc";
        }
        setTrendingDataSort(fmls);
    }

    const toggleFmlDataSort = () => {
        let fmls = '';
        if(fmlDataSort == "desc"){
            fmls = "asc";
        }else{
            fmls = "desc";
        }
        setFmlDataSort(fmls);
    }


    const handleDragStart = (e) => e.preventDefault();

    // const items = [
    //     <img src={require(`../../media/photos/website/qrcode.jpg`)} onDragStart={handleDragStart} role="presentation" />,
    //     <img src={require(`../../media/photos/website/qrcode.jpg`)} onDragStart={handleDragStart} role="presentation" />,
    //     <img src={require(`../../media/photos/website/qrcode.jpg`)} onDragStart={handleDragStart} role="presentation" />,
    // ];
    
    const onFeaturedTokenClick = () => {
        console.log('lets go please')
    }

    const now = new Date();

    


    const items =[
        <div className="feature-sponsor">
            <div className="sponsor-logo">
                <img src={require(`../../media/photos/partners/logo_paal.png`)} style={{pointerEvents:"none",zIndex:2,position:"absolute"}} />
            </div>
            {listTokens.length > 1 ? (
                <div style={{position:"absolute", bottom: 4, left: 4}}>
                    <DexPairCard addStyle={ walletAddress ? {} : {pointerEvents:'none'}} t={listTokens[0]}/>
                </div>
               
            ):null}
            
            <p className="sponsor-caption">Paal is a leading edge AI solution.</p>
            <img src={require(`../../media/photos/vignette_16-9.png`)} style={{pointerEvents:"none",zIndex:2,position:"absolute"}} />
            <img src={require(`../../media/photos/partners/bg_paal.jpg`)} onDragStart={handleDragStart} role="presentation" />
        </div>,
        <div className="feature-sponsor">
            <div className="sponsor-logo">
                <img src={require(`../../media/photos/partners/logo_otsea.png`)} style={{pointerEvents:"none",zIndex:2,position:"absolute"}} />
            </div>
            <p className="sponsor-caption">Paal is a leading edge AI solution.</p>
            <img src={require(`../../media/photos/vignette_16-9.png`)} style={{pointerEvents:"none",zIndex:2,position:"absolute"}} />
            <img src={require(`../../media/photos/partners/bubbles.jpg`)} onDragStart={handleDragStart} role="presentation" />
        </div>,
        <div className="feature-sponsor">
            <img src={require(`../../media/photos/vignette_16-9.png`)} style={{pointerEvents:"none",zIndex:2,position:"absolute"}} />
            <img src={require(`../../media/photos/partners/street_bokeh.jpg`)} onDragStart={handleDragStart} role="presentation" />
        </div>,
        <div style={{backgroundImage: 'linear-gradient(315deg, rgba(139,6,177,1) 0%, rgba(0,68,103,1) 99%) !important'}}/>
    ]

    return (
        <div className="flex-stage">

            {homeMeta.fml ? (
                <ListPresentation list={homeMeta.fml}/>
            ):null}
            

            { homeMeta && homeMeta.fmt !== undefined && homeMeta.showFmt && homeMeta.fmt ? (
                <div style={{margin: !walletAddress ? 22 : 7}} className="flex-column">
                    <div style={{marginBottom:7}} className="feature-card">
                        <div className="feature-card-title">
                            <div className="ticker-overlay"/>
                            <div style={{display:"flex"}} className="text-content">
                                <p>featured token</p>
                            </div>
                        </div>
                    </div>

                    { pageSize.width < 720 ? (
                        <DexPairCard addStyle={ walletAddress ? {} : {pointerEvents:'none'}} t={homeMeta.fmt}/>
                    ):(
                        <DexPairItem
                            withSpace={true}
                            rankAnimation={false}
                            hideRank={true}
                            passClick={onFeaturedTokenClick}
                            pairData={homeMeta.fmt.pair.data}
                            pair={homeMeta.fmt.pair}
                            coreToken={token}
                            addClass={'no-margin'}
                        />
                    )}
                    
                      
                    
                </div>
            ):null}

            {/* { homeMeta && homeMeta.fml?.tokens.length ? (
                
                <div style={{margin: !walletAddress ? 22 : 7}} className="flex-column">
                    <div className="feature-card">
                        <div style={{minHeight: 22}} className="feature-card-title">
                            <div style={{zIndex:-1}} className="ticker-overlay"/>
                            <div style={{paddingTop: homeMeta.fml.tags?.length ? 4 : 4 }} className="text-content">
                                <p>featured meta</p>
                                {homeMeta && homeMeta.fml?.name ? (
                                    <div className="flex-row full justify space-between">
                                        <h6 style={{margin:0,marginRight:7}}>{homeMeta.fml.name}</h6>
                                    
                                    
                                        
                                    </div>
                                ):null}

                                { homeMeta.fml.xSpace ? (
                                    <p>{homeMeta.fml.xSpace}</p>
                                ):null}

                                <div style={{marginTop:7}} className="flex-row">
                                
                                
                                    <TokenDataTabsInteractive
                                        title="price"
                                        bookend={true}
                                        addStyle={{borderRadius:'4px',overflow:'hidden'}}
                                        passClick={onFmlTimeClick}
                                        buttonValue={fmlDataTime}
                                        buttonValues={['m5','h1','h6','h24']}
                                        values={ ['5m','1h','6h','24h']}
                                        valuesFormat={['string','string','string','string']}
                                        labels={['','','','']}
                                    />

                                    <div style={{borderRadius:'4px',overflow:'hidden', marginLeft:7}} className="data-row interactive-items">
                                        <div onClick={toggleFmlDataSort} style={{display:"flex",alignItems:'center',justifyContent:'center'}} className="data-item">
                                            <div className={fmlDataSort == "desc" ? "icon-arrow-down" : "icon-arrow-up"}/>
                                        </div>
                                    </div>

                                    { homeMeta.fml.tokens.length > featuredMetaLimit ? (
                                        <div style={{marginLeft:11}} className="flex-row align center">
                                            <p>{featuredMetaLimit} <span className="mute">of</span> {`${homeMeta.fml.tokens.length}`}</p>
                                        </div>
                                    ):null}
                                    

                                    
                                    
                                </div>
                                
                                
                            </div>

                            { homeMeta.fml.tags?.length ? (
                                <div style={{position:"absolute",top:4,right:0,zIndex:111}} className="flex-row">
                            
                                    { homeMeta.fml.tags
                                
                                    .map((tag,i) => (
                                        
                                        <div key={i} style={{pointerEvents:'none', background: homeMeta.fml.color }} className="social-item-tag hash"><span className="mute">#</span>{tag}</div>
                                        
                                    ))}

                                
                                </div>
                            ):null}

                            
                            
                        </div>

                        
                        
                    </div>

                    
                    { homeMeta && homeMeta.fml?.tokens.length ? (
                        <div className="feature-card-tokens">
                        
                            { homeMeta.fml.tokens
                            .filter(n => n.pair?.data || n.defaultPair?.data )
                            .sort((a, b) => {
                                if(fmlDataSort == "desc"){
                                    return b.defaultPair.data.priceChange[`${fmlDataTime}`] > a.defaultPair.data.priceChange[`${fmlDataTime}`] ? 1 : -1;
                                }else{
                                    return b.defaultPair.data.priceChange[`${fmlDataTime}`] < a.defaultPair.data.priceChange[`${fmlDataTime}`] ? 1 : -1;
                                }
                            })
                            .slice(0, featuredMetaLimit)
                            .map((t,i) => (
                                
                                <DexPairCard key={`fml${i}`} dataTime={fmlDataTime} dataTimeLabel={dataTimeLabels[`${fmlDataTime}`]} addStyle={ walletAddress ? {} : {pointerEvents:'none'}} t={t}/>
                                
                            ))}
                        </div>
                    ):null}
                    
                </div>
            ):null} */}
            


            <div style={{margin: !walletAddress ? 22 : 7 }} className="flex-column">
                <div className="feature-card">
                    <div style={{minHeight:49}} className="feature-card-title">
                        <div style={{zIndex:-1}} className="ticker-overlay"/>
                        <div style={{display:"flex"}} className="text-content flex-row align center justify space-between">
                            <div className="flex-row">
                                <p>trending</p>
                                <div style={{marginLeft:7}} className="rank-number rank-1">1</div>
                            </div>
                            
                            <div className="flex-row">
                                <TokenDataTabsInteractive
                                    title="price"
                                    bookend={true}
                                    addStyle={{borderRadius:'4px',overflow:'hidden'}}
                                    passClick={onTrendingTimeClick}
                                    buttonValue={trendingDataTime}
                                    buttonValues={['m5','h1','h6','h24']}
                                    values={ ['5m','1h','6h','24h']}
                                    valuesFormat={['string','string','string','string']}
                                    labels={['','','','']}
                                />

                                <div style={{borderRadius:'4px',overflow:'hidden', marginLeft:7}} className="data-row interactive-items">
                                    <div onClick={toggleTrendingDataSort} style={{display:"flex",alignItems:'center',justifyContent:'center'}} className="data-item">
                                        <div className={trendingDataSort == "desc" ? "icon-arrow-down" : "icon-arrow-up"}/>
                                    </div>
                                </div>


                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>


           
                { homeMeta && homeMeta.trending?.length ? (
                    <div className="feature-card-tokens">

                        { homeMeta.trending
                        .filter(n => n.pair?.data || n.defaultPair?.data )
                        .filter(n => n.pair?.data.liquidity.usd > 5000 || n.defaultPair?.data.liquidity.usd > 5000 )
                        .sort((a,b) => {
                            const trendDiff = b.trending?.length - a.trending?.length;
  
                            if (trendDiff !== 0) {
                                return trendDiff;
                            }

                            // If lengths are the same, sort by valueField
                            if(trendingDataSort == "desc"){
                                return b.defaultPair.data?.priceChange[`${trendingDataTime}`] - a.defaultPair.data?.priceChange[`${trendingDataTime}`];
                            }else{
                                return a.defaultPair.data?.priceChange[`${trendingDataTime}`] - b.defaultPair.data?.priceChange[`${trendingDataTime}`];
                            }
                            
                        })

                        // .sort((a, b) => b.trending?.length > a.trending?.length ? 1 : b.defaultPair.data?.priceChange.h24 > a.defaultPair.data?.priceChange.h24 ? 1 : -1)
                        .map((t,i) => (  

                            <DexPairCard key={`tr${i}`} dataTime={trendingDataTime} dataTimeLabel={dataTimeLabels[`${trendingDataTime}`]} addStyle={ walletAddress ? {} : {pointerEvents:'none'}} t={t}/>
                       
                        ))}
                    </div>
                ):null}
            </div>

            
            <div style={{margin:!walletAddress ? 22 : 7}} className="flex-column">
                <div className="feature-card">
                    <div className="feature-card-title">
                        <div style={{zIndex:-1}} className="ticker-overlay"/>
                        <div className="text-content">
                            <p>top movers</p>
                        </div>
                    </div>
                    
                </div>
                { homeMeta && homeMeta.topm?.length ? (
                    <div className="feature-card-tokens">
                        
                    
                        { homeMeta.topm
                            .filter(n => n.pair?.data || n.defaultPair?.data )
                            // .sort((a, b) => b.defaultPair.data.priceChange.h24 > a.defaultPair.data.priceChange.h24 ? 1 : -1)
                            .map((t,i) => (
                            
                            <DexPairCard key={`topm${i}`} addStyle={ walletAddress ? {} : {pointerEvents:'none'}} t={t}/>
                            
                        ))}
                    </div>
                ):null}
            </div>
           
            
            <div className="flex-row" style={{height:222}}/>
            {/* <div style={{margin:!walletAddress ? 22 : 7}} className="flex-column">

                <div  style={{marginBottom:7}} className="feature-card">
                    <div style={{minHeight:33}} className="feature-card-title">
                        <div className="ticker-overlay"/>
                        <div className="text-content">
                            <p>featured partners</p>
                           
                        </div>
                        
                    </div>
                    
                </div>
                
                <div className="feature-card">
                   
                    <AliceCarousel
                    // autoPlay={true}
                    autoPlayInterval={2500}
                    infinite={true}
                    mouseTracking
                    items={items}
                    />
                    <div className="feature-card-footer">
                        copy goes here.
                    </div>
                </div>

            </div> */}
        </div>
    )
}



