import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import Toggle from 'react-toggle'

export function InputToggle({ title, value, field, onChange, disabled, icon=null, iconColors=['#444','green'] }) {
    const [content, setContent] = useState(value || "");

    const inputField = useRef(null);

    useEffect(() => {
        // console.log(field, value)
        if(content !== value){
            setContent(value);
        }
    }, [ value ]);

    useEffect(() => {
        // console.log(field, value)
        if(content !== value){
            setContent(value);
        }
    }, []);


    function handleChange(e) {
        let rv = {};
        rv[field] = e.target.checked;
        // console.log(!e.target.value)
        onChange(rv);
    }

    return (
        <div className="input-group">

                {title && <label>{title}</label>}
                
                <div className="flex-row">
                    <Toggle
                        checked={content}
                        icons={false}
                        onChange={handleChange} />

                    { icon ? (
                        <>
                            { icon=="dot" ? (
                                <div style={{marginLeft:4, marginTop:6, transform:'scale(.9)', opacity: content ? 1 : .2 }} className={classNames("green-circle large", content && "animated")}>
                                
                                </div>
                            ):(
                                <div style={{marginLeft:4, transform:'scale(.9)', opacity: content ? 1 : .2 }} className="svg-icon small">
                                    <div className={`${icon}`}></div>
                                </div>
                            )}
                        </>
                        
                        
                    ):null}
                   
                    
                </div>
                

        </div>
    );
}
