import React from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";

import { addCommas } from "../../utils/tools";

import classNames from "classnames";

var parse = require('html-react-parser');

export function TokenDataTabs({ values, valuesFormat, labels, addClass, addStyle, bookend }) {

    return (
        <div style={ addStyle ? addStyle : {} } className={classNames("data-row tabs bg flex-row justify space-around", addClass && addClass, bookend && "bookend")}>
            { values.map(( v, i) => (
                <div key={i} className="data-item" style={{minWidth: `${(100/values.length)}%`}} >
                    <div className="data-title">{labels[i]}</div>

                    <div className="data-value percent">
                        { valuesFormat[i] == "percent" ? (
                            <>
                                {parse(formatPercent(String(values[i])))}
                            </>
                        ) : valuesFormat[i] == "currency" ? (
                            <>
                                {parse(condenseZeros(formatCurrency(values[i], "USD")))}
                            </>
                        ) : valuesFormat[i] == "currencymin" ? (
                            <>
                                <span className="small">$</span>{parse(formatCurrencyScale(v))}

                            </>
                        ) : valuesFormat[i] == "numbermin" ? (
                            <>
                                {parse(formatNumberScale(v))}
                            </>
                        ) : (
                            <>
                                {addCommas(values[i])}
                            </>
                        )}
                    </div>


                </div>
            ))}
        </div>
    );
}
