import React, { useEffect, useState } from "react";
// import { useNavigate, NavLink } from "react-router-dom";


import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";

import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";

import { InputTextField } from "../../components/Input/InputTextField";
import { InputToggle } from "../../components/Input/InputToggle";
import { InputTabs} from "../../components/Input/InputTabs";
import { InputSelect} from "../../components/Input/InputSelect";
import { InputColorPick } from "../../components/Input/InputColorPick";
import { TokenList } from "../../components/Dex/TokenList";
import Blockies from 'react-blockies';
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js';
import { ContractAddressToolbar } from "../../components/Dex/ContractAddressToolbar";

import { sfx } from "../../utils/data";

import useSocketContext from '../../hooks/useSocketContext';

import classNames from "classnames";
import toast from 'react-simple-toasts';


export function Settings() {
    const socket = useSocketContext();
    const { token } = useSocial();
    const { tokenPair, newPairs } = useDex();
    const { user, handleUser, walletAddress, profile, handleProfile, handleUpdateProfile } = useWallet();
    const { handlePlaySound, sidebarLeft } = useCore();

    const [ sfxNames, setSfxNames ] = useState([]);
    const [ sfxValues, setSfxValues ] = useState([]);

    const [apeMode, setApeMode] = useState(false);

    useEffect(() => {
        if(user){
            let tp = {...user};
            handleProfile(tp);

            let snames = [];
            let svalues = [];

            for(var key in sfx){
                snames.push(sfx[key].name);
                svalues.push(key);
            }

            setSfxNames(snames);
            setSfxValues(svalues);


        }

    }, [ user ]);

    const updateProfile = () => {
        let p = {
            _id: user._id,
            ...profile,
        }
        if(p.walletAddressSolana?.length < 44){
            p.walletAddressSolana = "";
            handleProfile(p);
            if(p.walletAddressSolana?.length > 0){
                toast("Invalid wallet address");
            }
            
        }
        
        

        handleUpdateProfile(p);
        toast("Profile saved");

    }


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          // handleSubmit();
        }
    }

    const toggleApeMode = () => {
        console.log('ape it');

        setApeMode(!apeMode);
    }

    const pingTest = () => {
        socket.emit(
          'TEST_PING',
          {
            wa: 'word up',
            room: "lobby",
            // roomUrl: `${process.env.REACT_APP_URL_VIDEO}/${spaceContext.token}`,
            // domain: 'http://localhost:3000',
          }
      )
    }


    const onChangeSound = (v) => {
        
        let s = {...profile.sfx};
        for(var key in v){
            handlePlaySound(v[key]);
            s[key] = v[key];
        }



        handleProfile({
            ...profile,
            sfx: {
                ...profile.sfx,
                ...s
            },
            isDirty: true
        });
    }

    const togglePhotoBg = () => {
     
        handleProfile({
            ...profile,
            photobg: !profile.photobg,
            isDirty: true
        });
    }

    const toggleWalletBlur = () => {
     
        handleProfile({
            ...profile,
            walletBlur: !profile.walletBlur,
            isDirty: true
        });
    }

    const toggleBalanceBlur = () => {
     
        handleProfile({
            ...profile,
            balanceBlur: !profile.balanceBlur,
            isDirty: true
        });
    }

    const toggleMockPortfolio = () => {
     
        handleProfile({
            ...profile,
            mockPortfolio: !profile.mockPortfolio,
            isDirty: true
        });
    }

    const handleColorSelection = (key) => (value) => {
        let u = {...profile};
        u[key] = value;
        u.isDirty = true;
        handleProfile(u);


        // autoUpdateThemeDebounced({ [key]: value });
    };

    const handleProfileUpdate = (key) => (value) => {

        // autoSaveMap({ [key]: value });
    };

    const handleZoomRangeUpdate = (value) => {
        // console.log(value);
        // let zupdate = {
        //     minZoom: value[0],
        //     maxZoom: value[1]
        // }
        // console.log(zupdate);
        // if(map){
        //     autoSaveMap(zupdate);
        // }

    }


    const onSolWalletChange = (v) => {
        console.log(v)
        handleProfile({
            ...profile,
            walletAddressSolana: v,
            isDirty: true
        });
    }

    const validateSolWallet = (e) => {
  
        let re = /^[a-zA-Z0-9]*$/i;
        console.log('validate',e)
        let dataCheck = re.test(e);
        console.log(dataCheck)
        if(e.length > 44){
            return false;
        }
        return dataCheck;
    }


    return (
        <Layout>
            <div className="profile-panel">
            {/*<div className={classNames("profile-panel", !sidebarLeft && "collapsed")}>*/}
                {/*<Helmet><title>0xdΞx : Settings</title>
                </Helmet>*/}
                <div className="flex-row">
                    <div className="profile-container">
                        <div className="input-group">
                            <label>Wallet</label>
                            <div className="flex-row">
                                <div className="data-panel">
                                    <div style={{alignItems: 'center'}} className="data-row align center">
                                        <Blockies
                                            seed={walletAddress !== undefined ? walletAddress+"$" : "love"}
                                            size={7}
                                            scale={5}
                                            // color="#dfe"
                                            // bgColor="#ffe"
                                            // spotColor="#abc"
                                            className="identicon"
                                        />
                                        <ContractAddressToolbar
                                        // label="pair"
                                        walletAddress={true}
                                        type="address"
                                        ca={`${walletAddress}`}/>
                                        {/* <p style={{marginLeft:7}}>{walletAddress}</p> */}
                                    </div>

                                </div>

                            </div>

                        </div>
                        {/*<div className="input-switch-group">
                            <label>Platform</label>
                            <InputTabs
                                onChange={handleProfileUpdate("theme")}
                                // value={profile.theme}
                                tabs={['dark', 'light']}
                                tabsValues={['dark', 'light']}
                            />


                        </div>*/}

                        {/*<div className="input-switch-group">
                            <label>Zoom Range</label>
                            <InputRangeSlider
                                onChange={handleVolumeRangeUpdate}
                                min={0}
                                max={100}
                                step={1}
                                // value={min: map.minZoom, max: map.maxZoom}
                                value={[map.minZoom, map.maxZoom]}

                            />


                        </div>*/}
                        { profile ? (

                            <>

                                <div className="input-switch-group">
                                    <div className="flex-row full justify space-between align center">
                                        <h2>Theme</h2>
                                        <button onClick={updateProfile} style={{minWidth: 120}} disabled={!profile.isDirty}>save{profile.isDirty}</button>
                                    </div>

                                    <InputTextField
                                        title={'Solana Wallet Address'}
                                        // disabled={!theme.allowEmbedButtonText}
                                        checkmark={profile.walletAddressSolana?.length > 0}
                                        checkClass={profile.walletAddressSolana?.length > 0 && profile.walletAddressSolana?.length < 44 ? 'red' : 'green'}
                                        validate={validateSolWallet}
                                        onChange={onSolWalletChange}
                                        placeholder='Paste here'
                                        value={profile.walletAddressSolana}
                                    />

                                    <InputToggle
                                        title="Photo background"
                                        value={profile.photobg}
                                        onChange={togglePhotoBg}/>
                                    <InputToggle
                                        title="Mock portfolio"
                                        value={profile.mockPortfolio}
                                        onChange={toggleMockPortfolio}/>

                                    <InputToggle
                                        title="Wallet Blur"
                                        value={profile.walletBlur}
                                        onChange={toggleWalletBlur}/>     
                                    <InputToggle
                                        title="Balance Blur"
                                        value={profile.balanceBlur}
                                        onChange={toggleBalanceBlur}/>     
                                    <InputColorPick
                                        title="Site #1"
                                        onChange={handleColorSelection("colorbg1")}
                                        value={profile.colorbg1}
                                        mode="rgba"
                                    />

                                    <InputColorPick
                                        title="Site #2"
                                        onChange={handleColorSelection("colorbg2")}
                                        value={profile.colorbg2}
                                        mode="rgba"
                                    />

                                    <InputColorPick
                                        title="Portfolio"
                                        onChange={handleColorSelection("colorbgportfolio")}
                                        value={profile.colorbgportfolio}
                                        mode="rgba"
                                    />
                                </div>

                                <div className="input-switch-group">
                                    <h2>Sounds &nbsp;🔊</h2>
                                    <InputSelect
                                        title={"New Pair"}
                                        field="newpair"
                                        onChange={onChangeSound}
                                        value={profile.sfx.newpair}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                    <InputSelect
                                        title={"News"}
                                        field="news"
                                        onChange={onChangeSound}
                                        value={profile.sfx.news}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                    <InputSelect
                                        title={"Calls"}
                                        field="call"
                                        onChange={onChangeSound}
                                        value={profile.sfx.call}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                    <InputSelect
                                        title={"Price Up"}
                                        field="priceup"
                                        onChange={onChangeSound}
                                        value={profile.sfx.priceup}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                    <InputSelect
                                        title={"Price Down"}
                                        field="pricedown"
                                        onChange={onChangeSound}
                                        value={profile.sfx.pricedown}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />


                                </div>




                            </>



                        ):null}



                        {/*{token && (

                            <Blockies
                                seed={`love&hope37${token.pair.data.baseToken.address}`}
                                size={9}
                                scale={99}
                                // color="#dfe"
                                // bgColor="#ffe"
                                // spotColor="#abc"
                                className="identiconlarge"
                            />

                        )}*/}
                    </div>


                </div>




            </div>


            <div style={{alignItems:'flex-end'}} className="flex-column">

                <div style={{margin:20,padding:20}} className="data-panel">
                    <div style={{margin:20,padding:20}} className="token-balance-dex">
                        <p>Portfolio</p>
                    </div>
                </div>



                <div className="">

                </div>
            </div>

        </Layout>
    );
}
