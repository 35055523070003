import React, { useRef, useEffect, useState } from 'react';


import { InputTextField } from "../Input/InputTextField";
import { InputTextArea } from "../Input/InputTextArea";
import { InputToggle } from "../Input/InputToggle";
import { InputColorPick } from "../Input/InputColorPick";
import { InputSelect } from '../Input/InputSelect';

import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";
import { useForm } from "react-hook-form";

import { getRandomLoudColorHex } from '../../utils/tools';

export function FormTokenList({ closeModal=null, mode="edit" }) {
    
    let {
        dexSearchPairs,
        dexSearch,  // string
        searchDex, // function to search
        handleDexSearch,
        handleTokenPair,
        handleTokenToolbarVisible,
        loading,
        tokenLists,
        createTokenList,
        deleteTokenList,
        editTokenList,
        tokenList,
        handleTokenList,
        token,
        caToken
    } = useDex();

    const {
        walletAddress,
        adminWallet
    } = useWallet();

    const [ tl, setTl] = useState({
        name:"",
        description:"",
        // color:"#24EA35",
        color: getRandomLoudColorHex(),
        private: false,
        live: false,
        admin: false,
        api: false,
    })

    useEffect(() => {
        if(mode == "edit"){
            console.log(tokenList)
            setTl(tokenList);
        }
    }, []);

    const { register, handleSubmit, errors } = useForm();

   

    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
           
        }
    };

    const handleTl = (val) => {
        setTl({
            ...tl,
            ...val,
            isDirty: true

        })
    }

    const onTokenListTitleChange = (v) => {
        handleTl({ title: v });
    }

    const onTokenListNameChange = (v) => {
        handleTl({ name: v });
    }

    const onTokenListDescChange = (v) => {
        handleTl({ description: v });
    }

    const onViewLimitChange = (v) => {
        handleTl({ viewLimit: v });
    }

    const onTokenListColorChange = (v) => {
        handleTl({ color: v });
    }

    const toggleListAdmin = () => {
     
        handleTl({ admin: !tl.admin });
    }

    const toggleListPrivate = () => {
     
        handleTl({ private: !tl.private });
    }

    const toggleListLive = () => {
        handleTl({ live: !tl.live });
    }

    const handleDeleteList = () => {
        if(window.confirm(`Delete ${tl.name}?`)){
            deleteTokenList({id: tl._id, name: tl.name})
        }
        
    }

    const onTokenListSubmit = async () => {
        let c = {
            ...tl,
            walletAddress: walletAddress,
        }
        console.log(c,mode)
        if(mode=="edit"){
            editTokenList(c);
        }else{
            createTokenList(c);
        }
        
        // setTokenListTitle('');
        if(closeModal){
            closeModal();
        }
        
    };

    const lstart = 3;
    const lend = 25;

    // Create an array with values from start to end
    const vLimits = Array.from({ length: lend - lstart + 1 }, (v, i) => i + lstart);

    return (
        <>
            <h6 style={{margin:16}}>{mode == "edit" ? "Edit" : "Create"} List</h6>
            
            <InputTextField
                title={'Title'}
                onChange={onTokenListTitleChange}
                placeholder='e.g. "Featured List"'
                value={tl.title}
                onKeyPress={_handleKeyDown}
                autoComplete="off"

                // focus={true}
            />
            <InputTextField
                title={'Name'}
                onChange={onTokenListNameChange}
                placeholder='e.g. "My Favorites"'
                value={tl.name}
                onKeyPress={_handleKeyDown}
                autoComplete="off"

                // focus={true}
            />
            
            
            <InputTextArea
                title={'Description'}
                onChange={onTokenListDescChange}
                placeholder='e.g. "My list of favorites" #favorites'
                value={tl.description}
                // onKeyPress={_handleKeyDown}
                autoComplete="off"

                // focus={true}
            />

            <InputColorPick
            title="Color"
            onChange={onTokenListColorChange}
            value={tl.color}
            disableAlpha={true}
            />
            <div className="input-group-row">
                <InputToggle
                title="Live"
                value={tl.live}
                icon={"dot"}
                iconColors={["#777","#4f9542"]}
                onChange={toggleListLive}/>       

                <InputToggle
                title="Private"
                value={tl.private}
                icon={"icon-button-lock"}
                onChange={toggleListPrivate}/> 

                <InputSelect
                    title={'View Limit'}
                    onChange={onViewLimitChange}
                    value={tl.viewLimit}
                    options={vLimits}
                    optionsValues={vLimits}
                    
                    addClass={`button-icon-select small`}
                    addStyleInputGroup={{padding:"0 8px"}}
                    
                />

                    

                { adminWallet ? (
                    <InputToggle
                    title="Admin List"
                    value={tl.admin}
                    onChange={toggleListAdmin}/>
                ):null}


            </div>
           

            {/*    <div className="input-group">
                <input
                    name="sceneTitle"
                    // ref={register({ required: true })}
                    className="large"
                    placeholder='e.g. "New Scene"'
                    type="text"
                    onKeyDown={_handleKeyDown}
                />
                <label>Scene Name</label>

                {errors.title && (
                    <span class="error">This field is required</span>
                )}

            </div>*/}
            <div className="input-group">
                <button
                    className="large action"
                    onClick={handleSubmit(onTokenListSubmit)}
                    disabled={!tl.isDirty || tl.name.length < 3}
                >
                    {mode == "edit" ? "Save" : "Create"}
                </button>
                { mode == "edit" ? (
                    <button
                        className="large action danger"
                        style={{marginTop:0}}
                        onClick={handleDeleteList}
                        disabled={false}
                    >
                        Delete
                    </button>
                ):null}
            </div>
        </>
    );
}













