import React, { useState, useEffect } from "react";

import { useMarket } from "../../hooks/useMarket";
import { blockieSeed } from "../../utils/data";

import classNames from "classnames";
import Blockies from 'react-blockies';
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js'

import ethLogo from "../../icons/logo_chain_ethereum.png";
import { zeroxdexAddress } from "../../utils/tools";

export function TokenSymbolIcon({ token, ca, symbol, name, active, addClass=null, maxWidth=null, minWidth=null,includeQuote=null }) {

    let { coins, coinSymbols } = useMarket();

    const [imageError, setImageError ] = useState(false);
    const [imageProcessed, setImageProcessed] = useState(false);
    const [triedOnce, setTriedOnce] = useState(false);

    // const tokenImageUrl = (token && coins[(coinSymbols[symbol.toLowerCase()])] !== undefined)
    //     ? coins[(coinSymbols[symbol.toLowerCase()])].image : token && token.image && token.image !== undefined ? token.image : '';

    const isEth = token?.pair?.data.baseToken.symbol == "WETH";

    const ta = token?.pair?.data.baseToken.address ? token?.pair?.data.baseToken.address : ca;

    const tokenImageUrl = token && (token.address == zeroxdexAddress || ca == zeroxdexAddress) ? require('../../icons/logo_0xdex_100.jpg')
    : token && token.cg && token.cg.image !== undefined && token.cg.image.small !== "missing_small.png" ? token.cg.image.small
    : token && token.iconImage ? `${process.env.REACT_APP_MEDIA_URL}${token.iconImage}` : `${process.env.REACT_APP_MEDIA_URL}${ca}.png`;



    useEffect(() => {

       if(imageError && token.iconImage){
        setImageError(false);
        setImageProcessed(false)
       }
        
    }, [ token?.iconImage ]);


    const onImageError = (e) => {
        setImageError(true);
        setImageProcessed(true);
        if(!triedOnce){
            setTimeout(() => {
                setImageProcessed(false);
                setImageError(false);
            }, 200)
            setTriedOnce(true);
        }
    }
    const onImageLoad = (e) => {
       
        setImageProcessed(true);
    }

    return (
        <div style={{padding: '3px 7px', maxWidth: maxWidth ? maxWidth : 'initial', minWidth: minWidth ? minWidth : 'initial'}} className={classNames("token-symbol-icon data-row flex-row align center", active && "active", addClass && addClass)}>

            <div style={{ display: 'flex'}} className={classNames("data-icon round", tokenImageUrl && "coin-icon-wrapper")}>
                <>

                    { isEth ? (
                        <img className="coin-icon" src={ethLogo}/>
                    ):(
                        <>
                            { tokenImageUrl && !imageError ? (

                                <img style={{opacity: imageProcessed ? 1 : 0}} className="coin-icon" onLoad={onImageLoad} onError={onImageError} src={tokenImageUrl}/>

                            ):(
                                <img style={{borderRadius: '50%',margin: 3,marginRight:9,maxWidth:'45px'}} src={makeBlockiesUrl(blockieSeed + ta)} className="coin-icon"/>
                            )}
                        </>
                    )}


                    {/*):(
                        <Blockies
                            seed={`love&hope37${ca}`}
                            size={9}
                            scale={12}
                            // color="#dfe"
                            // bgColor="#ffe"
                            // spotColor="#abc"
                            className="identicon"
                        />
                    )}*/}
                </>
            </div>
            <div className="data-column" style={{marginLeft:0}}>
                {/* <p>{token.iconImage} - {imageProcessed ? "t" : "f"} - {imageError ? "t" : "f"}</p> */}
                <div style={{maxWidth: maxWidth ? (maxWidth-55) : '173px'}} className="coin-name">{isEth ? "Ethereum" : name}</div>
                <div style={{marginTop:2}} className="coin-symbol">{isEth ? "ETH" : symbol}{includeQuote ? ` / ${token.pair.data.quoteToken.symbol}` : ""}</div>
            </div>

        </div>
    );
}
