

export const listViews = [
	"trending",
	"news",
	"calls",
	"portfolio",
	"newpairs",
	"settings"
]


export const fishEmojis = ['🦐','🐟','🐬','🦈','🐋'];

export const heroImages = [
	'iceland_mars.jpg',
	'iceland_glacier.jpg',
	'iceland_glacier2.jpg',
	'iceland_mirror.jpg',
	'himalayan_heaven.jpg',
	'himalayan_cauldron.jpg',
	'himalayan_glaciers.jpg',
	'himalayan_kniferidge.jpg',
	'himalayan_towers.jpg',
	'himalayan_ridge.jpg',
	'himalayan_peak.jpg',
	// 'iceland_road.jpg',
	'iceland_ice.jpg',
	// 'iceland_scape.jpg',
	// 'craters_moon.jpg',
	'wallowas.jpg',
	// 'easternoregon.jpg'
]




export const tgSnipers = {
	"ethereum": {
		"prophet": {
			name:"ProphetBot",
			tgUrl: "https://t.me/officialprophetbot?start="
		},
		"uni": {
			name:"Unibot",
			tgUrl: "https://t.me/unibotsniper_bot?start=helloworld123-"
		},
		"maestro": {
			name:"Maestro",
			tgUrl: "https://t.me/MaestroSniperBot?start="
		},
		"banana": {
			name:"BananaSniper",
			tgUrl: "https://t.me/BananaGunSniper_bot?start=ref_helloworld123_"
		},
		// "wagie": {
		// 	name:"Wagie",
		// 	tgUrl: "https://t.me/wagiebot?start=helloworld123"
		// },
		"otsea": {
			name:"OTSea",
			tgUrl: "https://t.me/OTSeaMiniBot/orders?startapp="
		}
	},
	"solana": {
		"uni": {
			name: "Unibot Solana",
			tgUrl: "https://t.me/solana_unibot?start=r-"
		}
	}
	
}

export const sfx = {
	"null": {
		name: ": none :"
	},
	"sonar":{
		name: "Sonar",
	},
	"elevator":{
		name: "Elevator",
	},
	"store":{
		name: "Store",
	},
	"bubble":{
		name: "Bubble",
	},
	"message":{
		name: "Message",
	},
	"notification":{
		name: "Notification",
	},
	"pen":{
		name: "Pen",
	},
	"space":{
		name: "Space",
	},
	"static":{
		name: "Static",
	},
	"discord":{
		name: "Discord"
	},
	"owl_eastern_screech":{
		name: "Eastern Screech Owl"
	},
	"owl_long_eared":{
		name: "Long Eared Owl"
	},
	"owl_barred":{
		name: "Barred Owl"
	},
	"owl_snowy":{
		name: "Snowy Owl"
	},
	"ape01":{
		name: "Ape 1"
	},
	"gorilla01":{
		name: "Ape 2"
	},
	"gorilla03":{
		name: "Ape 3"
	}
}

export const swaps = {
	uniswap: {
		url: "https://app.uniswap.org/#/swap",
		chains: ['ethereum','polygon','optimism','arbitrum','gnosis','moonbeam'],
	},
	sushiswap: {
		url: "https://app.sushi.com/swap",
		chains: ['ethereum','fantom','bsc','polygon','avalanche','arbitrum','optimism'],
	},
	x7swap: {
		url: "https://app.x7.finance/#/swap/",
		chains: ['ethereum']
	},
	// kibaswap: {
	// 	url: "https://kibainu.com/#/swap",
	// 	chains: ['ethereum']
	// },
	otsea: {
		url: "https://otsea.io/mini-market?tokenAddress=",
		chains: ['ethereum'],
		custom: "addToken",
	},
	pancakeswap: {
		url: "https://pancakeswap.finance/swap",
		chains: ['bsc'],
	},
	apeswap: {
		url: "https://apeswap.finance/swap",
		chains: ['ethereum','polygon','bsc']
	},
	biswap: {
		url: "https://exchange.biswap.org/#/swap",
		chains: ['bsc']
	},
	spookyswap: {
		url: "https://spooky.fi/#/swap",
		chains: ['fantom'],
	},
	quickswap: {
		url: "https://quickswap.exchange/#/swap",
		chains: ['polygon'],
	},
	traderjoe: {
		url: "https://traderjoexyz.com/avalanche/trade",
		chains: ['avalanche'],
	},
	oneinchswap: {
		url: "https://app.1inch.io/#/1/unified/swap",
		chains: ['ethereum','polygon','bsc']
	},
	shibaswap: {
		url: "https://shibaswap.com/#/swap",
		chains: ['ethereum','polygon'],
	},
	dogeswap: {
		url: "https://www.doge-swap.com/exchange/#/swap",
		chains: ['ethereum','polygon'],
	},
	jupiterswap: {
		url: "https://jup.ag/swap/",
		chains: "solana"
	},
	raydiumswap: {
		url: "https://raydium.io/swap/",
		chains: "solana"
	},
	baseswap: {
		url: "https://baseswap.fi/swap",
		chains: "base"
	}
}
// ▴
// ▾
// ▲
// ▼
// worthy chains to look at
// optimism, arbitrum

// url to post a tweet
// https://twitter.com/intent/tweet?text=text%20goes%20here.%20%24byte%20&url=http%3A%2F%2Furl%20goes%20here&hashtags=hashtag1%2Chashtag2%2Chashtag3

export const chainImages = {
	"1": "ethereum",
	"56": "binance",
	"137": "polygon",
	"250": "fantom",
	"43114": "avalanche"
}


// Network Name : XRPL EVM Sidechain
// New RPC URL : https://rpc-evm-sidechain.xrpl.org
// Chain ID : 1440002
// Currency Symbol : XRP
// Block Explorer : https://evm-sidechain.xrpl.org

export const chainMeta = {
	"ethereum":{
		blockExplorer: "https://etherscan.io/",
		blockExplorerName: "etherscan",
		swaps: ['uniswap','x7swap','sushiswap','otsea'],
		scan: 'https://tokensniffer.com/token/eth/',
		scanImage: 'tokenSniffer'
	},
	"solana":{
		blockExplorer: "https://solscan.io/",
		blockExplorerName: "solscan",
		swaps: ['jupiterswap','raydiumswap'],
		scan: 'https://solsniffer.com/scanner/',
		scanImage: 'solSniffer'
	},
	"base":{
		blockExplorer: "https://basescan.org/",
		blockExplorerName: "basescan",
		swaps: ['uniswap','sushiswap','baseswap'],
		scan: 'https://tokensniffer.com/token/base/',
		scanImage: 'tokenSniffer'
	},
	"bsc":{
		blockExplorer: "https://bscscan.com/",
		blockExplorerName: "bscscan",
		swaps: ['pancakeswap','sushiswap','kibaswap'],
		scan: 'https://tokensniffer.com/token/eth/',
		scanImage: 'tokenSniffer'
	},
	"avalanche":{
		blockExplorer: "https://snowtrace.io/",
		blockExplorerName: "snowtrace",
		swaps: ['uniswap'],
		scan: 'https://tokensniffer.com/token/avalanche/',
		scanImage: 'tokenSniffer'
	},
	"blast":{
		blockExplorer: "https://blastscan.io/",
		blockExplorerName: "blastscan",
		swaps: ['uniswap'],
		scan: 'https://tokensniffer.com/token/blast/',
		scanImage: 'tokenSniffer'
	},
}

export const blockieSeed = "love&hope37";


export const chains = {

	// add iconUrls: []

	"0x1": {
		name: "Ethereum Mainnet",
		nameid: "eth",
		image: "ethereum",
		id: "1",
		hex: "0x1",
		rpcUrls: ["https://mainnet.infura.io/v3/"],
		blockExplorerUrls: ["https://etherscan.io"],
		swaps:['uni'],
		nativeCurrency: {
		    "symbol": "ETH",
		    "name": "Ethereum",
		    "decimals": 18,
		}
	},
	// "flashbots": {
	// 	name: "Flashbots RPC",
	// 	nameid: "flashbots",
	// 	image: "flashbots",
	// 	id: "1",
	// 	hex: "0x1",
	// 	rpcUrls: ["https://rpc.flashbots.net"],
	// 	blockExplorerUrls: ["https://etherscan.io"],
	// 	swaps:['uni'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "mevblocker": {
	// 	name: "MEVBlocker RPC",
	// 	nameid: "mevblocker",
	// 	image: "mevblocker",
	// 	id: "1",
	// 	hex: "0x1",
	// 	rpcUrls: ["https://rpc.mevblocker.io"],
	// 	blockExplorerUrls: ["https://etherscan.io"],
	// 	swaps:['uni'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x5": {
	// 	name: "Ethereum Goerli Testnet",
	// 	note: "testnet",
	// 	nameid: "goerli",
	// 	image: "ethereum",
	// 	id: "5",
	// 	hex: "0x5",
	// 	test: "ethereum",
	// 	rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
	// 	blockExplorerUrls: [],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "flashbotsgoerli": {
	// 	name: "Flashbots RPC Goerli",
	// 	nameid: "flashbotsgoerli",
	// 	image: "flashbots",
	// 	id: "5",
	// 	hex: "0x5",
	// 	test: "flashbots",
	// 	rpcUrls: ["https://rpc-goerli.flashbots.net"],
	// 	blockExplorerUrls: ["https://rpc.ankr.com/eth_goerli"],
	// 	swaps:['uni'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x15f902": {
	// 	name: "XRPL EVM",
	// 	nameid: "xrp",
	// 	image: "xrp",
	// 	id: "1440002",
	// 	hex: "0x15f902",
	// 	rpcUrls: ["https://rpc-evm-sidechain.xrpl.org"],
	// 	blockExplorerUrls: ["https://evm-sidechain.xrpl.org"],
	// 	swaps:['uni'],
	// 	nativeCurrency: {
	// 	    "symbol": "XRP",
	// 	    "name": "Ripple",
	// 	    "decimals": 18,
	// 	}
	// },
	"0x2105": {
		name: "Base Mainnet",
		nameid: "base",
		image: "base",
		id: "8453",
		hex: "0x2105",
		rpcUrls: ["https://mainnet.base.org"],
		blockExplorerUrls: ["https://basescan.org"],
		swaps:['leet','rocket'],
		nativeCurrency: {
		    "symbol": "ETH",
		    "name": "Ethereum",
		    "decimals": 18,
		}
	},
	// "0xa4b1": {
	// 	name: "Arbitrum One",
	// 	nameid: "arbitrum",
	// 	image: "arbitrum",
	// 	id: "42161",
	// 	hex: "0xa4b1",
	// 	rpcUrls: ['https://arb1.arbitrum.io/rpc'],
	// 	blockExplorerUrls: ['https://arbiscan.io/','https://explorer.arbitrum.io/'],
	// 	swaps:['sushi'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x66eed": {
	// 	name: "Arbitrum Goerli",
	// 	nameid: "arbitrum",
	// 	image: "arbitrum",
	// 	id: "421613",
	// 	hex: "0x66eed",
	// 	rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/goerli/public',
	// 	'https://arbitrum-goerli.public.blastapi.io'
	// 	],
	// 	blockExplorerUrls: [],
	// 	swaps:['sushi'],
	// 	nativeCurrency: {
	// 	    "symbol": "AGOR",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x7d0": {
	// 	name: "Dogechain Mainnet",
	// 	nameid: "doge",
	// 	image: "dogechain",
	// 	id: "2000",
	// 	hex: "0x7d0",
	// 	rpcUrls: ['https://rpc-sg.dogechain.dog',
	// 	'https://rpc01.dogechain.dog',
	// 	'https://rpc-us.dogechain.dog'],
	// 	blockExplorerUrls: ['https://explorer.dogechain.dog/'],
	// 	nativeCurrency: {
	// 	    "symbol": "DOGE",
	// 	    "name": "Dogecoin",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x171": {
	// 	name: "PulseChain Mainnet",
	// 	nameid: "pulsechain",
	// 	image: "pulsechain",
	// 	id: "369",
	// 	hex: "0x171",
	// 	rpcUrls: ['https://rpc.pulsechain.com'],
	// 	blockExplorerUrls: ['https://scan.pulsechain.com','https://beacon.pulsechain.com/'],
	// 	nativeCurrency: {
	// 	    "symbol": "PLS",
	// 	    "name": "Pulse",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x3af": {
	// 	name: "PulseChain Testnet",
	// 	note: "testnet v4",
	// 	nameid: "pulsechain",
	// 	image: "pulsechain",
	// 	id: "943",
	// 	hex: "0x3af",
	// 	rpcUrls: ['https://rpc.v4.testnet.pulsechain.com'],
	// 	blockExplorerUrls: ['https://scan.v4.testnet.pulsechain.com'],
	// 	nativeCurrency: {
	// 	    "symbol": "PLS",
	// 	    "name": "Pulse",
	// 	    "decimals": 18,
	// 	}
	// },

	// "0xa": {
	// 	name: "Optimism",
	// 	nameid: "optimism",
	// 	image: "optimism",
	// 	id: "10",
	// 	hex: "0xa",
	// 	rpcUrls: ['https://mainnet.optimism.io'],
	// 	blockExplorerUrls: ['https://explorer.optimism.io'],
	// 	swaps:['sushi'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },



	// "0x38": {
	// 	name: "Binance Smart Chain",
	// 	nameid: "bsc",
	// 	image: "binance",
	// 	id: "56",
	// 	hex: "0x38",
	// 	rpcUrls: ["https://bsc-dataseed.binance.org"],
	// 	blockExplorerUrls: ["https://bscscan.com"],
	// 	nativeCurrency: {
	// 	    "symbol": "BNB",
	// 	    "name": "BNB",
	// 	    "decimals": 18, // 9 on Binance Chain?
	// 	}
	// },
	// "0x89": {
	// 	name: "Polygon Mainnet",
	// 	nameid: "polygon",
	// 	image: "polygon",
	// 	id: "137",
	// 	hex: "0x89",
	// 	rpcUrls: ["https://polygon-rpc.com"],
	// 	blockExplorerUrls: ["https://polygonscan.com"],
	// 	nativeCurrency: {
	// 	    "symbol": "MATIC",
	// 	    "name": "MATIC",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0xfa": {
	// 	name: "Fantom Opera",
	// 	nameid: "fantom",
	// 	image: "fantom",
	// 	id: "250",
	// 	hex:"0xfa",
	// 	rpcUrls: ["https://rpc.ftm.tools/",
	// 	"https://fantom.api.onfinality.io/public"],
	// 	blockExplorerUrls: ["https://ftmscan.com"],
	// 	nativeCurrency: {
	// 	    "symbol": "FTM",
	// 	    "name": "FTM",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x86a": {
	// 	name: "Avalanche C-Chain",
	// 	nameid: "avalanche",
	// 	image: "avalanche",
	// 	id: "43114",
	// 	hex: "0x86a",
	// 	rpcUrls: ["https://api.avax.network/ext/bc/C/rpc",
	// 	"https://rpc.ankr.com/avalanche",
	// 	"https://avalanche.api.onfinality.io/public/ext/bc/P"],
	// 	blockExplorerUrls: ["https://snowtrace.io"],
	// 	nativeCurrency: {
	// 	    "symbol": "AVAX",
	// 	    "name": "AVAX",
	// 	    "decimals": 18,
	// 	}
	// },


};




export function toHex(num){
  return '0x' + Number(num).toString(16);
};


//
// export const chainIds = {
//     '0': 'kardia',
//     '1': 'ethereum',
//     '8': 'ubiq',
//     '10': 'optimism',
//     '19': 'songbird',
//     '20': 'elastos',
//     '25': 'cronos',
//     '30': 'rsk',
//     '40': 'telos',
//     '52': 'csc',
//     '55': 'zyx',
//     '56': 'binance',
//     '57': 'syscoin',
//     '60': 'gochain',
//     '61': 'ethclassic',
//     '66': 'okexchain',
//     '70': 'hoo',
//     '82': 'meter',
//     '88': 'tomochain',
//     '100': 'xdai',
//     '106': 'velas',
//     '108': 'thundercore',
//     '122': 'fuse',
//     '128': 'heco',
//     '137': 'polygon',
//     '200': 'xdaiarb',
//     '246': 'energyweb',
//     '250': 'fantom',
//     '269': 'hpb',
//     '288': 'boba',
//     '321': 'kucoin',
//     '336': 'shiden',
//     '361': 'theta',
//     '592': 'astar',
//     '820': 'callisto',
//     '888': 'wanchain',
//     '1088': 'metis',
//     '1284': 'moonbeam',
//     '1285': 'moonriver',
//     '2020': 'ronin',
//     '4689': 'iotex',
//     '5050': 'xlc',
//     '5551': 'nahmii',
//     '8217': 'klaytn',
//     '10000': 'smartbch',
//     '32659': 'fusion',
//     '42161': 'arbitrum',
//     '42220': 'celo',
//     '42262': 'oasis',
//     '43114': 'avalanche',
//     '71394': 'godwoken',
//     '333999': 'polis',
//     '1313161554': 'aurora',
//     '1666600000': 'harmony',
//     '11297108109': 'palm',
//     '836542336838601': 'curio'
// }
//

// export interface AddEthereumChainParameter {
//   chainId: string;
//   blockExplorerUrls?: string[];
//   chainName?: string;
//   iconUrl?: string;
//   nativeCurrency?: {
//     name: string;
//     symbol: string;
//     decimals: number;
//   };
//   rpcUrls?: string[];
// }



export const chainData = {
	'polygon': {
		params: [{
	        chainId: "0x89",
	        chainName: "Matic Mainnet",
			rpcUrls: ["https://rpc-mainnet.matic.network/"],
	        nativeCurrency: {
	            name: "MATIC",
	            symbol: "MATIC",
	            decimals: 18
	        },
	        blockExplorerUrls: ["https://polygonscan.com/"]
	    }]
	},
	'goerli': {
		params: [{
	      chainId: "0x5",
	      chainName: "Goerli",
	      rpcUrls: [`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
	      nativeCurrency: {
	        name: "Goerli ETH",
	        symbol: "gorETH",
	        decimals: 18
	      },
	      blockExplorerUrls: ["https://goerli.etherscan.io"]
	  	}]
	},
	'harmony': {
		params: [
		 {
		   chainId: "0x63564c40",
		   rpcUrls: ["https://api.harmony.one"],
		   chainName: "Harmony Mainnet",
		   nativeCurrency: { name: "ONE", decimals: 18, symbol: "ONE" },
		   blockExplorerUrls: ["https://explorer.harmony.one"],
		   iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"]
		 }
		],
	},
	'ethereum': {
		params: []
	},
	'fantom': {
		params: []
	},
	'binance': {
		params: []
	},
	'avalanche': {
		params: []
	},
}

export const supportedChains = [
	"ethereum",
	"solana",
	"base",
	"blast",
	"bsc",
	"avalanche"
]

export const supportedChainsPortfolio = {
	"0x1": {
		name: "ethereum",
		key: "eth"
	},
	"0x2105": {
		name: "base",
		key: "base"
	},
	"sol": {
		name: "solana",
		key: "sol"
	}
}

export const coinColorsDefault = {
	"primary": "#38bcea",
	"secondary": "#8247e5",
	"chartLineOpacitySub": .7,
	"chartAreaOpacity": .33,
	"chartAreaOpacitySub": .11,
}

export const coinColors = {
	"btc": "#f69c3d",
	"eth": "#627eea",
	"seth": "#627eea",
	"weth": "#627eea",
	"avax": "#e84142",
	"usdt": "#03ac84",
	"busd": "#f0b90b",
	"usdc": "#2775ca",
	"sol": "#6e36bd",
	"flex": "#815bf4",
	"ada": "#226ad0",
	"unfi": "#35ea8f",
	"trx": "#b32d24",
	"link": "#2959d9",
	"wbtc": "#f69c3d",
	"doge": "#ba9f32",
	"dai": "#fab120",
	"dot": "#e6027a",
	"shib": "#ff0e00",
	"matic": "#8247e5",
	"ape": "#134cd0",
	"sand": "#03adef",
	"etc": "#36b437",
	"mana": "#ff4956",
	"ftm": "#38bcea",
	"op": "#fe041f",
	"wbnb": "#F2B932",
	"bnb": "#F2B932",
	"vet": "#30a3ef",
	"aave": "#8374ac",
	"luna": "#F2E384",
	"tusd": "#1b59ff",
	"uni": "#fd077d",
	"icp": "#ec1d78",
	"lrc": "#1b60ff",
	"cake": "#d1884f",
	"lunc": "#f9d65d",
	"rune": "#00efb6",
	"sushi": "#e658a4",
	"ftt": "#02a6c2",
	"bsv": "#E8B324",
	"neo": "#00e599",
	"crv": "#74fd80",
	"ens": "#699df5",
	"snx": "#03c9f8",
	"grt": "#384ba5",
	"1inch": "#ad252b",
	"mkr": "#1f9f91",
	"anc": "#4bda4a",
	"yfi": "#046ce9",
	"srm": "#5cbed6",
	"comp": "#00d395",
	"steth": "#04a3ff",
	"band": "#4520e6",
	"perp": "#3CEAAB",
	"mim": "#fed955",
	"ohm": "#708b96",
	"amp": "#D62D7B",
	"joe": "#f2716a",
	"quick": "#418aca",
	"boo": "#6665dd",
	"png": "#fdc702",
	"any": "#4F87F0",
	"marshall-rogan-inu": "#cf2624",
	"mri": "#cf2624",
	"mcc": "#606dbb",
	"refi": "#ff2222"

}

export const colorSwatches = ['#5671d4', '#597cd9', '#5c87dd', '#5e92e1', '#5f9de5', '#5fa8e9', '#5eb3ed', '#5cbff1', '#58caf5', '#4fd6fa', '#00e4ff', '#00e4ff77'];

export const dataProviderLogos = [
	// {
	// 	url:'logo_twitter.png',
	// 	href:'https://twitter.com',
	// 	desc:'twitter.com',
	// 	circular: true,
	// },
	{
		url:'logo_tg.png',
		href:'https://telegram.org',
		desc:'telegram.org',
		circular: false,
	},
	{
		url:'logo_alchemy.png',
		href:'https://alchemy.com',
		desc:'alchemy.com',
		circular: false,
	},
	{
		url:'logo_uniswap.png',
		href:'https://uniswap.org',
		desc:'uniswap.org',
		circular: false,
	},
	{
		url:'logo_x.png',
		href:'https://x.com',
		desc:'x.com',
		circular: true,
	},

	// {
	// 	url:'logo_dexscreener.png',
	// 	href:'https://dexscreener.com',
	// 	desc:'dexscreener.com',
	// 	circular: false,
	// },
	{
		url:'logo_etherscan.png',
		href:'https://etherscan.io',
		desc:'etherscan.io',
		circular: false,
	},
	{
		url:'logo_defined.png',
		href:'https://defined.fi',
		desc:'defined.fi',
		circular: true,
	},
	// {
	// 	url:'logo_coingecko.png',
	// 	href:'https://coingecko.com',
	// 	desc:'coingecko.com',
	// 	circular: true,
	// },
	// {
	// 	url:'logo_coinmarketcap.png',
	// 	href:'https://coinmarketcap.com',
	// 	desc:'coinmarketcap.com',
	// 	circular: true,
	// },


	{
		url:'logo_tokensniffer.png',
		href:'https://tokensniffer.com',
		desc:'tokensniffer.com',
		circular: false,
	},

	{
		url:'logo_honeypot.png',
		href:'https://honeypot.is/ethereum',
		desc:'honeypot.is',
		circular: false,
	},

	// {
	// 	url:'logo_opensea.png',
	// 	href:'https://www.opensea.io',
	// 	desc:'opensea.io',
	// 	circular: false,
	// },

	// {
	// 	url:'logo_twilio.png',
	// 	href:'https://www.twilio.com/',
	// 	desc:'twilio.com',
	// 	circular: false,
	// },




]

export const tokenCats = [
	{
		name: 'new pairs',
		id: 'newpairs'
	},
	{
		name: 'trending',
		id: 'trending'
	}
]


export const subDigits = [
  "&#x2080;", // Subscript 0
  "&#x2081;", // Subscript 1
  "&#x2082;", // Subscript 2
  "&#x2083;", // Subscript 3
  "&#x2084;", // Subscript 4
  "&#x2085;", // Subscript 5
  "&#x2086;", // Subscript 6
  "&#x2087;", // Subscript 7
  "&#x2088;", // Subscript 8
  "&#x2089;"  // Subscript 9
];



export const tokenFiltersData = {
	"verified": {
		title: 'verified',
		field: 'verified',
		type: 'toggle'
	},
	"locked": {
		title: 'locked',
		field: 'locked',
		type: 'boolean',
	},
	"renounced": {
		title: 'renounced',
		field: 'renounced',
		type: 'boolean',
	},
	"fdv_min": {
		title: 'fdv',
		field: 'data.fdv',
		type: 'range',
	},
	"fdv_max": {
		title: 'fdv',
		field: 'data.fdv',
		type: 'range',
	},
	"volume_min": {
		title: 'fdv',
		field: 'volume.h24',
		type: 'range',
	},
	"volume_max": {
		title: 'fdv',
		field: 'volume.h24',
		type: 'range',
	},
	"age": {
		title: 'age',
		field: 'liquidityAt',
		type: 'input',
	},
	"csmin": {
		title: 'contract score min',
		field: 'contractScore',
		type: 'input',
		validate: 'Number',
		range: [0,100],
	},

}


export const fdvLabels = ['1k','100k','1M','10M','100M','1B','10B+'];


export const volumeLabels = ['1k','100k','1M','10M+'];


export const mockPortfolio = {
	// weth
	"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2":{
		address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		balance: "9888000000000000000"
	},
	// evermoon
	"0x4ad434b8CDC3AA5AC97932D6BD18b5d313aB0f6f":{
		address: "0x4ad434b8CDC3AA5AC97932D6BD18b5d313aB0f6f",
		balance: "533370000000000000000000"
	},
	// arbitrum
	"0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1":{
		address:"0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
		balance:"1370000000000000000000"
	},
	// matic
	"0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0":{
		address:"0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
		balance:"12440000000000000000000"
	},
	// pepe
	"0x6982508145454Ce325dDbE47a25d4ec3d2311933":{
		address:"0x6982508145454Ce325dDbE47a25d4ec3d2311933",
		balance:"4880000000000000000000000000"
	},
	// pepe 2
	"0xfb66321D7C674995dFcC2cb67A30bC978dc862AD":{
		address:"0xfb66321D7C674995dFcC2cb67A30bC978dc862AD",
		balance:"118000000000000000000000000000"
	},
	// sani
	"0x4521C9aD6A3D4230803aB752Ed238BE11F8B342F":{
		address:"0x4521C9aD6A3D4230803aB752Ed238BE11F8B342F",
		balance:"944000000000000000000000000"
	},
	// metis
	"0x9E32b13ce7f2E80A01932B42553652E053D6ed8e":{
		address:"0x9E32b13ce7f2E80A01932B42553652E053D6ed8e",
		balance:"277700000000000000000"
	},
	// ape
	"0x4d224452801ACEd8B2F0aebE155379bb5D594381":{
		address:"0x4d224452801ACEd8B2F0aebE155379bb5D594381",
		balance:"3230000000000000000000"
	},
	// shib
	"0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE":{
		address:"0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
		balance:"2120000000000000000000000000"
	},
	// bobo
	"0xB90B2A35C65dBC466b04240097Ca756ad2005295":{
		address:"0xB90B2A35C65dBC466b04240097Ca756ad2005295",
		balance:"3792000000000000000000000000"
	},
	// hex
	"0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39":{
		address:"0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
		balance:"16440000000000"
	},
	// 0xdex
	"0xB2Cfc9A054562BCfD854daD55a99E8BC8085A480":{
		address:"0xB2Cfc9A054562BCfD854daD55a99E8BC8085A480",
		balance:"19880000000000000000000000"
	},
	//dogelon
	"0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3":{
		address:"0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3",
		balance:"5324000000000000000000000000"
	},
	// kishu
	"0xA2b4C0Af19cC16a6CfAcCe81F192B024d625817D":{
		address:"0xA2b4C0Af19cC16a6CfAcCe81F192B024d625817D",
		balance:"12770000000000000000000"
	},
	// usdc
	"0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48":{
		address:"0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
		balance:"1146"
	}

}

export const eTrendingNames = {
	"bt": "BuyTech",
	"sg": "SafeGuard",
	"tg": "Trend Guru",
	"dt": "DexTools",
	"mt": "Moontok",
	"soltbirdy": "$BIRDY",
	"soltbb": "SOL TRENDING",
	"baset": "Base BuyTech"
}

export const adminWalletList = [
	"0x0780e25F8e3642A32f23bcbfB6ffe2A1035eeDBa", // jb22
	"0x606B8f7fbbd2038FCd6c906Bd376c0A188477BDE", // 2ndary
	"0xaD924355bE23Fcc9586Fe4703e3B21D25868277A", // d wallet
	"0x95F28179e462CAE4ec3B88c281A66df4bE56B157", // angel
	"0xaA5D76eF315a495252E227687D3Bf944647846b9", // a2
	"0xaD924355bE23Fcc9586Fe4703e3B21D25868277A"
]


export const walletWhiteList = [
	"0x66E23e601252fb289207cEBf66079ceDE6dc4989",
	"0xEBFa1C3350b05187cFea2491C766b6DB1b10b5b0",  // test dev
	"0x0780e25F8e3642A32f23bcbfB6ffe2A1035eeDBa",
	"0xB29681cE0b4669cE6E7Ca2e55863839FCBAD840E",
	"0x60fD3a8B0095Dd41Ae31f718EED7c4466E0D06a3",
	"0x95F28179e462CAE4ec3B88c281A66df4bE56B157",  // angel
	"0x939f1d3bC2a8E0Fafa38037545FC9748EcC737D9", // ant
	"0xc26d9A610C6c4e912FDeD64F0766Bd9de6D28Be5", // marketing
	// "0xaD924355bE23Fcc9586Fe4703e3B21D25868277A", // dman
	"0x0DF60D80ED2F6A076D47Ac82a6BDBFA9233A16ad", // starman
	"0x206062EA296E4E893ed708af61d27B68316F0990", // sentai
	"0xBd0148ab73644480ECf7c02c6c64eE7EF43a226D", // pb
	"0x54821d1B461aa887D37c449F3ace8dddDFCb8C0a", // paal
	"0x29be112d6bc37646e93c18499884c4fb95f99195", // jab
	"0x49d0b3b979C8DB8D91b34A16716264C68771d915", // hodler
	"0xA9Eb4296e2265CA0664961C8Cb55b4c0bA3094e7",
	"Aristhodle.eth",
	"0x534b8531e362Da97D808ba2ab3959fa2597BeF11",  // aristhodle
	"0xC6D4E5C1cd5c2142C4592bBf66766e0f5f588d84",  // eastbird
	"0x5950e45026969440E5Ab6955Ad488509aaA8c3a9",  // psily
	"0x351B8000D53BB464d6afC05e0f00169aC47e7f48",
	"0x85c4cDbBe7cbb7eEcecA0F835E66451De5a61D1C",
	"0xcccaddce78ca4e1d0c02dec47785a4ba9ec6b4c1",
	"0x94d15f76E11986E270fE067e599dE516D8DeBeb6",
	"0x3F17D91Fee30DAe3030080c1D5D2BB5e803906b8",
	"0x22007a8B65033f6719D745635E0b7C89eb68ad4B",
	"0x6892F09a7834E93C84Ae3D0829aD00aEf1094732",
	// "0x07a0d6BC5b63792CbD7bdd62231ee4D077228Ffe", // mitesh og
	"0x4174677Be62EAF287C4d8C4A9E95781Af63D64DC",
	"0x58970a06164d5027B841d7e79344bcE5AfC40651",
	"0xaeEF513b28313c7F8e80891A7434bc2AE5104609",
	"0xFCa5565DAbc899b641b3f24F6A762Fc2351235B5",
	"0x1b076946Ba920E3489C544B45a6ec36595A191b9",
	"0x18DcE61496f9a5c846C6d2462e0116CaF00E494d",  //mitesh
	"0x1E0a2F8001DBE68Ee9a783fBc0802a863f7624d0", // slip
	"0xA837269440708b0aAf414Bc84DcCB2f7e22476F3", // blondie
]



// Network Name: Fantom testnet
// New RPC Url: https://rpc.testnet.fantom.network/
// ChainID: 0xfa2
// Symbol: FTM
