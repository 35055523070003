import React, { useCallback, useState, useEffect, useContext, createContext } from "react";
import { debounce, find } from "lodash";
import { client } from "../../services/api";

import { chains, sfx, heroImages } from "../../utils/data";

import { useIsOnline } from 'react-use-is-online';

// import useSocketContext from '../../hooks/useSocketContext';

// import dingSound from '../../media/sounds/ding1.mp3';

import sonar from '../../media/sounds/sonar.mp3';
import message from '../../media/sounds/message.mp3';
import discord from '../../media/sounds/discord.mp3';
import statics from "../../media/sounds/static.mp3";
import bubble from "../../media/sounds/bubble.mp3";
import elevator from "../../media/sounds/elevator.mp3";
import notification from "../../media/sounds/notification.mp3";
import pen from "../../media/sounds/pen.mp3";
import space from "../../media/sounds/space.mp3";
import store from "../../media/sounds/store.mp3";
import oes from "../../media/sounds/owl_eastern_screech.mp3";
import ole from "../../media/sounds/owl_long_eared.mp3";
import ob from "../../media/sounds/owl_barred.mp3";
import os from "../../media/sounds/owl_snowy.mp3";
import a1 from "../../media/sounds/ape01.mp3";
import g1 from "../../media/sounds/gorilla01.mp3";
import g3 from "../../media/sounds/gorilla03.mp3";

const sonarSound = new Audio(sonar);
const messageSound = new Audio(message);
const discordSound = new Audio(discord);
const staticSound = new Audio(statics);
const bubbleSound = new Audio(bubble);
const elevatorSound = new Audio(elevator);
const notificationSound = new Audio(notification);
const penSound = new Audio(pen);
const spaceSound = new Audio(space);
const storeSound = new Audio(store);
const owlEasternScreechSound = new Audio(oes);
const owlLongEared = new Audio(ole);
const owlBarred = new Audio(ob);
const owlSnowy = new Audio(os);
const ape1 = new Audio(a1);
const gorilla1 = new Audio(g1);
const gorilla3 = new Audio(g3);


const coreContext = createContext();

function useCoreProvider(token) {

    const [loading, setLoading] = useState(false);

    const [websiteLoaded, setWebsiteLoaded] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [ accessAllowed, setAccessAllowed ] = useState(true);
    const [ interactedWith, setInteractedWith ] = useState(null);

    const [lobbyPanel, setLobbyPanel] = useState(false);

    const [ pageSize, setPageSize ] = useState({ width: 0, height: 0 });

    const { isOnline, isOffline, error } = useIsOnline();

    const [ news, setNews ] = useState([]);
    const [ calls, setCalls ] = useState([]);

    const [ethTrending, setEthTrending] = useState(null);

    // const [notifications, setNotifications] = useState([
    //     {
    //         msg: 'Transaction success',
    //         type: 'success',
    //         id: 0,
    //     },
    //     {
    //         msg: 'Transaction rejected.',
    //         type: 'warning',
    //         id: 1,
    //     },
    //     {
    //         msg: 'Transaction failed.',
    //         type: 'failure',
    //         id: 2,
    //     }
    // ]);


    // const socket = useSocketContext();

    const [version, setVersion] = useState('0.9.1')

    const [heroImage, setHeroImage] = useState(null);

    const [notifications, setNotifications] = useState([]);
    const [notificationId, setNotificationId] = useState(1);

    const [soundFx, setSoundFx] = useState(null);
    const [soundVolume, setSoundVolume] = useState(.5);

    const [tokens, setTokens] = useState([]);

    const [chainPanel, setChainPanel] = useState(false);
    const [chain, setChain] = useState({});

    const [settingsPanel, setSettingsPanel] = useState(false);

    const [headerHeight, setHeaderHeight] = useState('44px');
    const [footerHeight, setFooterHeight] = useState('24px');

    const [sidebarLeft, setSidebarLeft] = useState(false);
    const [sidebarRight, setSidebarRight] = useState(false);
    const [sidebarContent, setSidebarContent] = useState(true);

    const [basePage, setBasePage] = useState(null);
    const [listPage, setListPage] = useState(null);

    const [pages, setPages] = useState(["default", "test"]);
    const [page, setPage] = useState("default");
    const [pageLoading, setPageLoading] = useState(false);

    const [socketLog, setSocketLog] = useState('');

    const [qrurl, setQrurl] = useState(false);

    const [modalTitle, setModalTitle] = useState('');

    const [updateTokenData, setUpdateTokenData] = useState(null);


    const [walletModalVisible, setWalletModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const [newPageModal, setNewPageModal] = useState(false);
    const [exchangeModal, setExchangeModal] = useState(false);
    const [ collectionModal, setCollectionModal ] = useState(false);
    const [ tokenListModal, setTokenListModal ] = useState(false);
    const [qrModal, setQrModal] = useState(false);
    const [swapSettingsModal, setSwapSettingsModal] = useState(false);
    const [dexSearchModal, setDexSearchModal] = useState(false);

    const [chartType, setChartType] = useState('candles');
    const [chartDataType, setChartDataType] = useState('price');

    const [fullscreen, setFullscreen] = useState(false);

    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    })

    const [playSound, setPlaySound] = useState(false);
    const [defaultImage, setDefaultImage] = useState('../../icons/logo_0xdex_100.jpg');

    //
    const [acceleration, setAcceleration] = useState({ x: 0, y: 0, z: 0 });


    useEffect(() => {
        window.addEventListener('resize', getWindowDimensions);
        return () => {
          window.removeEventListener('resize', getWindowDimensions);
        };

    }, []);


    useEffect(() => {
        window.addEventListener('load', onContentLoaded)
        return () => {
          window.removeEventListener('load', onContentLoaded);
        };

    }, []);



    useEffect(() => {
        // console.log(window.location);
        if (window.location.protocol!=="https:" && window.location.host.indexOf('localhost:') < 0) {
            let u = `https://${window.location.host}${window.location.pathname}`;
            // console.log(u);
            window.location.replace(u);
        }

        const rindex = Math.floor(Math.random() * heroImages.length);
        setHeroImage(heroImages[rindex]);

    },[])




    // useEffect(() => {
    //     window.addEventListener('devicemotion', handleDeviceMotion);
    //     return () => {
    //       window.removeEventListener('devicemotion', handleDeviceMotion);
    //     };
    // }, []);
    //
    // function handleDeviceMotion(event) {
    //     setAcceleration({
    //       x: event.acceleration.x,
    //       y: event.acceleration.y,
    //       z: event.acceleration.z
    //     });
    // }


    useEffect(() => {
        if(playSound){
            switch(playSound){
                case "bubble":
                    bubbleSound.volume = soundVolume;
                    bubbleSound.play();
                    break;
                case "discord":
                    discordSound.volume = soundVolume;
                    discordSound.play();
                    break;
                case "elevator":
                    elevatorSound.volume = soundVolume;
                    elevatorSound.play();
                    break;
                case "message":
                    messageSound.volume = soundVolume;
                    messageSound.play();
                    break;
                case "notification":
                    notificationSound.volume = soundVolume;
                    notificationSound.play();
                    break;
                case "pen":
                    penSound.volume = soundVolume;
                    penSound.play();
                    break;
                case "sonar":
                    sonarSound.volume = soundVolume;
                    sonarSound.play();
                    break;
                case "space":
                    spaceSound.volume = soundVolume;
                    spaceSound.play();
                    break;
                case "static":
                    staticSound.volume = soundVolume;
                    staticSound.play();
                    break;
                case "store":
                    storeSound.volume = soundVolume;
                    storeSound.play();
                    break;
                case "owl_eastern_screech":
                    owlEasternScreechSound.volume = soundVolume;
                    owlEasternScreechSound.play();
                    break;
                case "owl_long_eared":
                    owlLongEared.volume = soundVolume;
                    owlLongEared.play();
                    break;
                case "owl_barred":
                    owlBarred.volume = soundVolume;
                    owlBarred.play();
                    break;
                case "owl_snowy":
                    owlSnowy.volume = soundVolume;
                    owlSnowy.play();
                    break;
                case "ape01":
                    ape1.volume = soundVolume;
                    ape1.play();
                    break;
                case "gorilla01":
                    gorilla1.volume = soundVolume;
                    gorilla1.play();
                    break;
                case "gorilla03":
                    gorilla3.volume = soundVolume;
                    gorilla3.play();
                    break;
                default:
                    break;
            }


            setPlaySound(null);
        }
    }, [ playSound ]);

    // useEffect(() => {
    //     socket.emit("firstping",{type:'hello',msg:'world'});
    //     socket.emit("roomjoin");
    // }, []);

    function handlePageSize(s){
        setPageSize(s);
    }

    function handleLobbyPanel(v){
        setLobbyPanel(v);
    }

    function onContentLoaded(e){
        setContentLoaded(true);
    }

    function handleNews(n){
        setNews([...n]);
    }

    function handleCalls(n){
        setCalls([...n]);
    }

    function handleEthTrending(v){
        setEthTrending(v);
    }

    function getWindowDimensions(){
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    function handlePlaySound(v){
        setPlaySound(v);
    }

    function handleBasePage(v){
        setBasePage(v);
    }

    function handleListPage(v){
        setListPage(v);
    }

    function handleSettingsPanel(v){
        setSettingsPanel(v);
    }

    function newNotification(n){
        let not = {
            ...n,
            time: 10,
            id: notificationId
        }
        setNotifications([...notifications, not]);
        let nid = notificationId + 1;
        setNotificationId(nid);
        notificationSound.play();
    }

    function closeNotification(n){
        let nots = [];

        for(var i = 0;i<notifications.length;i++){
            if(notifications[i].id !== n.id){
                nots.push(notifications[i]);
            }
        }

        setNotifications(nots);
    }

    function createPage(v){
        let tp = [ ...pages, v];
        setPages(tp);
    }
    function removePage(value){
        let pa = [];
        for(var i=0;i<pages.length;i++){
            if(pages[i] !== value){
                pa.push(pages[i]);
            }
        }
        setPages(pa);
        setPage(pa[pa.length-1]);
    }

    function handleChain(passchainid){
        let passchain = {};
        for(var i=0;i<chains.length;i++){
            if(passchainid == chains[i].id){
                setChain(chains[i]);
            }
        }
    }

    function handleModalVisible(value) {
        setModalVisible(value);
    }

 
    function handleChainPanel(value) {
        setChainPanel(value);
    }

    function handleSidebarLeft(value) {
        setSidebarLeft(value);
    }
    function handleSidebarRight(value) {
        setSidebarRight(value);
    }
    function handleSidebarContent(value) {
        setSidebarContent(value);
    }

    function handleWindowDimensions(data){
        setWindowDimensions(data);
    }

    function handleChangePage(value) {
        setPage(value);
    }

    function handleWalletModalVisible(value) {
        setWalletModalVisible(value);
    }

    function handleModalVisible(value) {
        setModalVisible(value);
    }

    function handleNewPageModal(value) {
        setNewPageModal(value);
    }

    function handleExchangeModal(value) {
        setExchangeModal(value);
    }

    function handleQrModal(value) {
        setQrModal(value);
    }

    function handleTokenListModal(value) {
        setTokenListModal(value);
    }

    function handleSwapSettingsModal(value) {
        setSwapSettingsModal(value);
    }

    function handlePageLoading(value) {
        setPageLoading(value);
    }

    function handleChartType(v){
        setChartType(v);
    }

    function handleChartDataType(v){
        setChartDataType(v);
    }

    function handleQrurl(v) {
        setQrurl(v);
    }

    function handleModalTitle(v){
        setModalTitle(v);
    }

    function handleCollectionModal(v){
        setCollectionModal(v);
    }

    function handleDexSearchModal(v){

        setDexSearchModal(v);
    }

    function toggleFullscreen(){
        if(fullscreen){
            document.body.classList.remove('fullscreen');
        }else{
            document.body.classList.add('fullscreen');
        }
        setFullscreen(!fullscreen);
    }

    function handleError(e, entity="Transaction"){
        if(e.code == 4001){
            newNotification({
                type: 'failure',
                msg: `${entity} rejected.`
            })
        }else if(e.message.status == 502){
            console.log('502 error')
            newNotification({
                type: 'failure',
                msg: `${entity} failed.`
            })
        }
        console.log(e.message)
        console.log(e.error)
    }


    const addToSocketLog = useCallback( async (m) => {
        let sl = `${socketLog} ${m}`;
        setSocketLog(sl);
    }, [socketLog]);

    const handleUpdateTokenData = (v) => {
        setUpdateTokenData(v);
    }

    const handleWebsiteLoaded = (v) => {
        setWebsiteLoaded(v);
    }

    const handleAccessAllowed = (v) => {
        setAccessAllowed(v);
    }



    return {
        // chains,
        version,
        isOnline,
        chain,
        handleChain,
        tokens,
        chainPanel,
        handleChainPanel,
        basePage,
        listPage,
        handleBasePage,
        handleListPage,
        handleEthTrending,
        ethTrending,
        pageSize,
        handlePageSize,
        sidebarLeft,
        handleSidebarLeft,
        sidebarRight,
        handleSidebarRight,
        sidebarContent,
        handleSidebarContent,
        windowDimensions,
        handleWindowDimensions,
        pages,
        page,
        createPage,
        removePage,
        handleChangePage,
        modalTitle,
        handleModalTitle,
        modalVisible,
        newPageModal,
        handleModalVisible,
        walletModalVisible,
        handleWalletModalVisible,
        handleQrModal,
        qrModal,
        qrurl,
        handleQrurl,
        handleNewPageModal,
        pageLoading,
        handlePageLoading,
        chartType,
        handleChartType,
        chartDataType,
        handleChartDataType,
    
        collectionModal,
        handleCollectionModal,
        exchangeModal,
        handleExchangeModal,
        swapSettingsModal,
        handleSwapSettingsModal,
        dexSearchModal,
        handleDexSearchModal,
        newNotification,
        notifications,
        closeNotification,
        handleError,
        fullscreen,
        toggleFullscreen,
        playSound,
        handlePlaySound,
        tokenListModal,
        handleTokenListModal,
        addToSocketLog,
        socketLog,
        handleUpdateTokenData,
        updateTokenData,
        acceleration,
        websiteLoaded,
        handleWebsiteLoaded,
        contentLoaded,
        accessAllowed,
        handleAccessAllowed,
        interactedWith,
        handleNews,
        news,
        handleCalls,
        calls,
        settingsPanel,
        handleSettingsPanel,
        heroImage,
        defaultImage,
        lobbyPanel,
        handleLobbyPanel,
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideCore({ token, children }) {
    const core = useCoreProvider(token);
    return (
        <coreContext.Provider value={core}>{children}</coreContext.Provider>
    );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useCore = () => {
    return useContext(coreContext);
};
