import React, { createContext, useEffect, useMemo, useRef } from 'react';
import EventEmitter from 'eventemitter3';

import { useCore } from "../../hooks/useCore";

export const SocketContext = createContext();

export default function SocketProvider({ client, uri, ...props }) {
  const socketRef = useRef();
  const currentNamespace = useRef();
  const eventEmitterRef = useRef(new EventEmitter());
  const queuedEvents = useRef([]);

  const { inApp } = useCore();

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const value = useMemo(() => {


    return {
      init: (namespace) => {

        // console.log(currentNamespace.current,namespace);

        if (currentNamespace.current === namespace && socketRef.current) {
          // console.log('resolving??');
          return Promise.resolve();
        }
        //
        // console.log('socket init lets go', uri, namespace)

        return new Promise((resolve, reject) => {
          const socket = client(`${uri}/${namespace || ''}`, {
            transports: ['websocket', 'polling'],
            withCredentials: true,
          });
          // const socket = client(`${uri}/${namespace || ''}`);
          currentNamespace.current = namespace;
          socketRef.current = socket;


          socket.on('dextunnel', (msg) => {
              // console.log('TUNNEL YO >>>>>>>>>>>>>>>>>>>>>>>>>>>>')
              eventEmitterRef.current.emit(msg.type, msg);
          });

          socket.on('connect', () => {

            resolve();

            eventEmitterRef.current.emit("RECONNECT_ROOMS", {});

            queuedEvents.current.forEach((evt) => {
              socket.emit(...evt);
            });
            queuedEvents.current = [];
          });

          socket.on('connect_error', (err) => {
            reject();
          });

          socket.on('reconnect', () => {
              // console.log('reconnect')
          });

          socket.on('disconnect', (e) => {
              // console.log('disconnect?!')
          });

          socket.on('session', ({ sessionId }) => {
              socket.auth = { sessionId };
          });
        });
      },

      on: (...args) => {
        eventEmitterRef.current.on(...args);
      },

      off: (...args) => {
        eventEmitterRef.current.off(...args);
      },

      emit: (...args) => {
        if (!socketRef.current) {
          queuedEvents.current.push(args);
          return;
        }
        socketRef.current.emit(...args);
      },
    };



}, [client, uri, inApp]);

  return <SocketContext.Provider value={value} {...props} />;
}
