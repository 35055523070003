import React, { useEffect, useState } from "react";

import Blockies from 'react-blockies';

import { useWeb3React } from '@web3-react/core';
import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";

export function WalletButton() {
	const { activate, deactivate, active, chainId, account } = useWeb3React();
	const { walletModalVisible, handleWalletModalVisible } = useCore();
	let { walletAddress } = useWallet();

    let { loading, isAuthenticated } = useAuth();

	const [ hovering, setHovering ] = useState(false);

	const walletClick = () => {
        handleWalletModalVisible(!walletModalVisible);
    }

	const onOver = () => {
		setHovering(true);
	}

	const onLeave = () => {
		setHovering(false);
	}


	// const walletTruncated = account?.length ? account.substring(0,5) + '...' + account.substring(account.length - 4) : '';

	const wa = String(walletAddress);
	const walletTruncated = walletAddress ? wa.substring(0,5) + '...' + wa.substring(wa.length - 4) : '';

    return (
        <>


			<button onClick={walletClick} onMouseEnter={onOver} onMouseLeave={onLeave} style={{ maxHeight: 27,minWidth: '125px', paddingRight: walletAddress ? '16px' : '', paddingLeft: walletAddress ? '16px' : '32px' }} className="currency address wallet-button panel slim wallet-address">
				{ walletAddress ? (
					<div className="wallet-profile-image">
						<Blockies
							seed={walletAddress !== undefined ? walletAddress+"love" : "love"}
							size={7}
							scale={5}
							// color="#dfe"
							// bgColor="#ffe"
							// spotColor="#abc"
							className="identicon"
						/>
					</div>
				):(
					<div style={{filter:'saturate(0) brightness(.55)',marginRight:11}} className="wallet-profile-image">
						<Blockies
							seed={"0xdexloveover$"}
							size={7}
							scale={5}
							// color="#dfe"
							// bgColor="#ffe"
							// spotColor="#abc"
							className="identicon"
						/>
					</div>
				)}
				{ walletAddress && hovering ? 'Disconnect' : walletAddress ? walletTruncated.replace("606","237").replace("7bde","l0v3") : 'Connect Wallet' }
				{ walletAddress && (
					<div className="active-green-circle"/>
				)}
			</button>


        </>
    );
}
