import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";

export function InputTextField({ title, value, placeholder = null, validate=null, checkmark=false, checkClass, onChange, disabled, focus=null, onKeyPress=null, igstyle=null }) {
    const [content, setContent] = useState(value || "");

    const inputField = useRef(null);

    useEffect(() => {
        if(content !== value){
            setContent(value);
        }
    }, [ value ]);

    useEffect(() => {
        if(focus){
            inputField.current.focus();
        }
    }, []);

    function handleChange(e) {
        
        if(validate){
            
            if(validate(e.target.value)){
                
                setContent(e.target.value);
                onChange(e.target.value);
            }
        }else{
            setContent(e.target.value);
            onChange(e.target.value);
        }

    }

    return (
        <div style={ igstyle ? igstyle : {}} className="input-group">
            {title && <label>
                {title}
                {checkmark ? (
                    <div style={{marginLeft:7}} className={classNames("icon-check", checkClass )}/>
                ):null}
            </label>}
            <input
                value={content}
                className="outline"
                placeholder={placeholder}
                type="text"
                disabled={disabled}
                onChange={handleChange}
                onKeyPress={onKeyPress}
                ref={inputField}
            />
        </div>
    );
}
