import React, { useEffect, useState, useCallback, useRef } from "react";
// import { useNavigate, NavLink } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useCore } from "../../hooks/useCore";
import { useNavigator } from "../../hooks/useNavigator";
import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";
import { AppHome } from "../../components/Layout/AppHome";
import { PageHeader } from "../../components/Layout/PageHeader";
import { TickerBlock } from "../../components/Dex/TickerBlock";
import { DexSwap } from "../../components/Dex/DexSwap";
import { DexSwapDraggable } from "../../components/Dex/DexSwapDraggable";
import { FiltersPanel } from "../../components/Dex/FiltersPanel";
import { DexPairItemList } from "../../components/Dex/DexPairItemList";
import { Portfolio } from "../Portfolio";

import { LoaderSpinner } from "../../components/Layout/LoaderSpinner";

import { MarketChart } from "../../components/MarketChart";

import ResizePanel from "react-resize-panel";

import { TwitterDashboard } from "../../components/Social/TwitterDashboard";

import { InputToggle } from "../../components/Input/InputToggle";
import { TokenList } from "../../components/Dex/TokenList";
import { PairList } from "../../components/Dex/PairList";

import { formatCurrency, condenseZerosTitle } from "../../utils/currency";

import { replaceSubDigits } from "../../utils/tools";
import { supportedChains, listViews } from "../../utils/data";

import useSocketContext from '../../hooks/useSocketContext';

import classNames from "classnames";

var parse = require('html-react-parser');

export function ContentDashboard() {

    const navigator = useNavigator();
    const { contractAddress, handleContractAddress, screens, pathname, handleSearchParams, searchParams } = useNavigator();

    let { 
        windowDimensions,
        handleSidebarRight,
        isOnline,
        basePage,
        handleBasePage,
        listPage,
        handleListPage,
        ethTrending,
    } = useCore();

    const [ bottomSize, setBottomSize ] = useState("50%");

    const {
        token,
        caToken,
        newPairs, // depr
        tokenPairs, // depr
        fetchNewPairs,
        pairs,
        handleTokenPair,
        fetchTokenPair,
        loading,
        dexView,
        handleDexView,
        listView,
        handleListView,
        editFilters,
        tokenLists,
        listTokens,
        tokenList,
        handleTokenList,
        fetchListTokens,
        dexViewChartInit,
        dexViewSwapInit,
        handleDexViewChartInit,
        handleDexViewSwapInit,
        ethTrendingKey,
        handleEthTrendingKey,
        ethTrendingTokens,
        fetchEthTrendingTokens,
        showTicker,
        handleShowTicker
    } = useDex();


    const [ listType, setListType ] = useState(null);
    const pageWrapper = useRef(null);

 
    const [loadingIframe, setLoadingIframe] = useState(false);

   
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);

        if(currentParams.view !== undefined){
            
            handleDexView("list");
            handleListView(currentParams.view);
            if(currentParams.tc !== undefined){
                handleEthTrendingKey(currentParams.tc);
            }
           
            if(currentParams.list !== undefined){
                handleListPage(currentParams.list);
            }
            // handleListView(currentParams.view);
        }

        fetchNewPairs();
        // handleSearchParams({
        //     ...currentParams,
        //     ...filters
        // });

        // handleTokenFilters(currentParams);
    },[])

    useEffect(() => {

        if( ethTrending ){
            // console.log("eth trending",ethTrending)
            fetchEthTrendingTokens();
        }

    }, [ ethTrending, ethTrendingKey ]);


    useEffect( () => {

        if(listPage){
            fetchListTokens(listPage);
            for(var i=0;i<tokenLists.length;i++){
                if(listPage == tokenLists[i].slug){
                    handleTokenList(tokenLists[i])
                }
            }
        }

    }, [ listPage ]);

    useEffect(() => {
        // console.log(screens)
        if(screens[1] == undefined || !screens[1].length){
            handleBasePage(null);
            handleDexView("list");
            handleListView("home");
            handleTokenPair(null);
        }else if(listViews.includes(screens[1])){
            
            if(token){
                handleTokenPair(null);
            }
            if(listViews.includes(screens[1])){
                handleBasePage(screens[1]);
                handleListView(screens[1]);
                handleSidebarRight(false);
                if(screens[1] == "trending" && screens[2] !== undefined){
                    handleEthTrendingKey(screens[2]);
                }
            }else{
                handleBasePage(null);
                handleDexView("list");
            }
            
        }else{
            let bp = screens[1];
            if( supportedChains.includes(screens[1]) ){
                
                bp = "token";
                handleBasePage("token");

                let p = screens[2].split("?")[0];
                if(token && token.pair.address !== p){
                    handleTokenPair(null);
                }

            }else{
                console.log('list update', screens[1]);
                
                handleBasePage(screens[1]);
                handleListView(screens[1]);
                if(screens[1] == "list" && screens[2] !== undefined){
                    handleListPage(screens[2]);
                   
                }
                
                
            }
        }

        if(supportedChains.includes(screens[1]) && screens[2] !== undefined){
            let p = screens[2].split("?")[0];
            if(!token || token.pair.address !== p){
                handleTokenPair(null);
                if(!loading.dex){
                    // console.log('load it up')
                    fetchTokenPair(screens[1], p);
                }

            }
        }

    }, [ screens, listTokens ]);


    useEffect(() => {
        if(dexView !== "chart"){
            handleDexViewChartInit(false);
        }
        if(dexView !== "swap"){
            handleDexViewSwapInit(false);
        }
    }, [ caToken ]);


    useEffect(() => {

        // if(listTokens && listTokens.length){
        //     const interval = setInterval(getCurrentDex, 60000);
        //     return () => clearInterval(interval);
        // }

    }, [ listTokens ]);


    const toggleBtmHeight = (newSize) => {
        setBottomSize(newSize + "px");
    }


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          // handleSubmit();
        }
    }

    const closeTicker = () => {
        handleShowTicker(false);
    }

    const handleOnLoad = () => {
		// setLoadingIframe(false);
	}


    //↓ ↑

    return (
        <Layout stylePassPage={{ overflowY: 'hidden' }}>


            { showTicker && (

                <TickerBlock closeTicker={closeTicker} stylePass={{position:'relative'}}/>

            )}

            { (token || supportedChains.includes(screens[1])) ? (
                <PageHeader/>
            ):null}
                
           


            {/*{ tokenPair && (
                <>
                    {condenseZerosTitle(tokenPair.data.baseToken.symbol, tokenPair.data.priceUsd)}
                </>
            )}*/}

            <Helmet>

            </Helmet>


            {editFilters ? (
                <FiltersPanel/>
            ):null}



            {token && dexViewChartInit && (

                <>
                    <div
                    className="dex-chart"
                    style={{
                        height: showTicker && !token ? 'calc( 100% - 320px )' : 'calc( 100% - 6px )',
                        display: dexView == "chart" ? 'flex' : 'none'}}
                    >
                        {token && token.pair.data !== undefined && (


                            <iframe onLoad={handleOnLoad} style={{height: 'calc( 100% - 6px )'}}
                            src={`https://dexscreener.com/${token.pair.data.chainId}/${token.pair.data.pairAddress}?theme=dark&embed=1&info=0`}
                            />
                        )}


                    </div>

                    <div className="dex-footer" style={{opacity:1,display: dexView == "chart" ? 'flex' : 'none'}}>

                        <div style={{width: 18,height: 18,opacity: .5,marginLeft: 5}} className="dex-icon">
                            <img style={{maxWidth:'100%',maxHeight:'100%'}} src={require(`../../icons/data/logo_dexscreener_mark.png`)}/>
                        </div>

                    </div>
                </>
            )}

          

            {/*{ token && !loading.dex && dexViewSwapInit && (
                <DexSwapDraggable token={token}/>
            )}*/}


            { token && !loading.dex && dexViewSwapInit && (
                <DexSwap visible={dexView == "swap"} token={token}/>
            )}

            {/* <TwitterDashboard visible={dexView == "social"}/> */}

            {/*{token && dexViewChartInit && !loading.dex && (

                <>
                    <div
                    className="dex-chart"
                    style={{
                        height: tickerBlock && !token ? 'calc( 100% - 320px )' : 'calc( 100% - 6px )',
                        display: dexView == "chart" ? 'flex' : 'none'}}
                    >
                        {token && token.pair.candles !== undefined && (

                            <MarketChart candles={token.pair.candles}/>
                        )}


                    </div>

                    <div className="dex-footer" style={{display: dexView == "chart" ? 'flex' : 'none'}}>

                        <div style={{width: 18,height: 18,opacity: .5,marginLeft: 5}} className="dex-icon">
                            <img style={{maxWidth:'100%',maxHeight:'100%'}} src={require(`../../icons/data/logo_dexscreener_mark.png`)}/>
                        </div>

                    </div>
                </>
            )}*/}

            

            {/*{ loading.dex ? (*/}

            {/*):null}*/}

            {/*<TokenList visible={dexView == "list"} height={bottomSize} tokens={listTokens}/>*/}
            
            { dexView == "list" ? (
                 <div className={classNames("page-content-wrapper visible")} >

                    { loading.list ? (
                        <div className="load-panel">
                            <LoaderSpinner/>
                        </div>
                    ):null}
    
                    {/* { screens[2] == undefined && !screens[1]?.length && !loading.dex ? (
                        <AppHome visible={true}/>
                    ):null} */}
    
                    <DexPairItemList
                    height={bottomSize}
                    // addStyle={{minWidth:640}}
                    addClass="feature-panel"
                    />
                  
    
                </div>
            ):null}
           
            {/*<h1>{dexView}{listView}</h1>*/}
            

            {/* { (listView == "newpairs" || basePage == "newpairs") ? (
                <PairList visible={dexView=="list"} height={bottomSize} pairs={pairs}/>

            ):null} */}

           
            {/* { ethTrendingTokens[ethTrendingKey] ? (
                <PairList visible={dexView == "list"} height={bottomSize} pairs={ethTrendingTokens[ethTrendingKey]}/>
            ):null} */}
          

            {/*<p>++{dexView}++</p>*/}
            {/*<MarketChart candles={coinCurrent.candles == undefined ? [] : coinCurrent.candles }/>*/}

            {/* <SwapCurrency/>
            <CoinList height={bottomSize}/>*/}


        </Layout>
    );
}
