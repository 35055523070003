import React, { useEffect, useState, useRef } from "react";
// import { useNavigate, NavLink } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { useWallet } from "../../hooks/useWallet";
import { useDex } from "../../hooks/useDex";
import { useCore } from "../../hooks/useCore";
import { useNavigator } from "../../hooks/useNavigator";
import { Helmet } from "react-helmet-async";

import { walletWhiteList, heroImages } from "../../utils/data";
import { getRandomLoudColorRgba } from "../../utils/tools";

import toast from 'react-simple-toasts';

import { Layout } from "../../components/Layout";
import { HomeMeta } from "../../components/Layout/HomeMeta";
import { GridCanvas } from "../../components/Home/GridCanvas";
import { IconActionButton } from "../../components/IconActionButton";

import logo0x from "../../icons/logo_0xdex.png";
import { ListPresentation } from "../../components/Dex/ListPresentation";

export function AppLobby() {

    const { walletAddress, zeroXBalance } = useWallet();
    const { token } = useDex();
    const { windowDimensions, websiteLoaded, handleWebsiteLoaded, accessAllowed, walletModalVisible, handleWalletModalVisible, lobbyPanel, handleLobbyPanel } = useCore();
    const { contractAddress, handleContractAddress, screens, pathname, handleSearchParams, searchParams } = useNavigator();

    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);

    const [heroImage, setHeroImage] = useState(null);
    const [heroColor, setHeroColor] = useState(null);

    const [xSpace, setXSpace] = useState(true);
    const [urlRendered, setUrlRendered] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(0);

    const imageRef = useRef();

    useEffect(() => {
      
        if(screens[1] !== undefined && screens[1] == "spaces"){
            if(screens[2] !== undefined){
                
                console.log(screens[2]);

               
                setXSpace(screens[2]);
                
            }
            
        }else{
            setXSpace(null)
        }
        setInterval(() => {
            setUrlRendered(true)
        },10)
        
        
        
    }, [ screens ]);

    useEffect(() => {

        // document.body.classList.add('inWebsite');
        let lc = getRandomLoudColorRgba(.33);
        setHeroColor(lc);

        const pageWrapper = document.querySelector('.page-wrapper');

        if(pageWrapper !== undefined && pageWrapper){

            function moveImage(event) {
                // const { clientX: x, clientY: y } = isMobile ? event.touches[0] : event; // Use the first touch's coordinates

                if(imageRef.current){
                    const { clientX: x, clientY: y } = event;
                    let mx = x / windowDimensions.width;
                    let my = y / windowDimensions.height;
                    setMouseX(mx);
                    setMouseY(my);
                    const { offsetWidth: width, offsetHeight: height } = imageRef.current;

                    const sp = pageWrapper.scrollTop / pageWrapper.clientHeight;

                    let spp = scrollPosition/windowDimensions.height;
                    // console.log(sp)
                    let ytemp = spp + (my-spp);

                    if(imageRef.current !== undefined){
                        imageRef.current.style.transform = `translate(${-((20 * x) / width)}px, ${-((40 * sp))}px)`;
                    }
                }


            }

            // Add event listeners for both mousemove and touchmove events
            if ('ontouchstart' in window) {
                // For touch devices, remove mousemove event listener and add touchmove event listener
                document.removeEventListener('mousemove', moveImage);
                // document.addEventListener('touchmove', moveImage);
            } else {
                // For non-touch devices, remove touchmove event listener and add mousemove event listener
                // document.removeEventListener('touchmove', moveImage);
                document.addEventListener('mousemove', moveImage);
            }

            const rindex = Math.floor(Math.random() * heroImages.length);
            setHeroImage(heroImages[rindex]);

            return () => {
                // Cleanup function to remove event listeners on component unmount
                document.removeEventListener('mousemove', moveImage);
                // document.removeEventListener('touchmove', moveImage);
            };
        }

       
        
    }, [ ]);


    const walletClick = async () => {
        if (typeof window.ethereum !== 'undefined') {

            try{
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts"
                });
                const lcWalletList = walletWhiteList.map(item => item.toLowerCase());

                // if(lcWalletList.includes(accounts[0])){
                    handleWalletModalVisible(!walletModalVisible);
                // }else{
                //     toast('Wallet not whitelisted.');
                // }
            }catch(e){
                toast('Check your wallet.');
                // console.log(e);
            }


        }else{
            toast('No wallet connected.');
        }

    }

    const buyClick = async () => {
        
        window.open(
            `${process.env.REACT_APP_WEBSITE_URL}#howtobuy`,
            "_blank"
        )

    }

    const webClick = async () => {
        
        window.open(
            `${process.env.REACT_APP_WEBSITE_URL}`,
            "_blank"
        )

    }

    const toggleLobbyPanel = async () => {
        handleLobbyPanel(!lobbyPanel);
    }

    
   
    return (
        <Layout>
            <Helmet>
                <title>Welcome to 0xDΞX</title> 
            </Helmet>


            <div style={{zIndex:9}} className="flex-row">

                { lobbyPanel ? (

                    <div style={{position:"relative",height:'100vh',width:'37%',overflow:'auto', background:"#0a324c94", maxWidth: 420}} className="flex-column full-height">
                                        
                        {/* <div className="hero-image-stack">
                            <img src={require(`../../media/photos/website/qrcode.jpg`)} alt="First Image" className="first-image" style={{ top: `22px`, left: `0%` }}/>
                            <img src={require(`../../media/photos/website/security.jpg`)} alt="Second Image" className="second-image" style={{ top: `-22px`, left: `40%` }} />
                            <img src={require(`../../media/photos/website/twitter_post.jpg`)} alt="Third Image" className="third-image" style={{ top: `120px`, left: `50%` }} />
                        </div> */}


                        <IconActionButton
                            passStyle={ lobbyPanel ? { zIndex:333, top: '5px',right: '5px', transform: 'scale(.9)'} : { display: "none",top: '3px',left: '3px', transform: 'scale(.9)'} }
                            type={ lobbyPanel ? "arrow-left" : "arrow-right" }
                            passClick={toggleLobbyPanel}
                        />


                        <div className="hero-hue">

                            <div style={{top:"initial"}} className="hero-logo">

                                <img style={{maxWidth:222,marginTop:77}} src={logo0x} alt="0xdex logo" />   
                                <h1 style={{fontSize:37,fontWeight:"500",lineHeight:'42px',marginTop:22}}>The future of crypto is DeFi.</h1>
                                
                                <h2>We're building structure to the chaos of Decentralized Finance (DeFi).</h2>
                                <p>Our platform is a first-of-its-kind DeFi explorer and portfolio manager on Ethereum, Base and Solana chains.</p>
                                {/* <img style={{maxWidth:222,marginTop:33}} src={logo0x} alt="0xdex logo" />
                                <h1 style={{color:"#3897f0",fontSize:33,lineHeight:'53px'}}>The future of money is Defi.</h1>
                                <h1 style={{color:"#3897f0",fontSize:33,lineHeight:'53px'}}>We bring order to DeFi chaos.</h1> */}
                                
                                {/*<p style={{margin:0,paddingLeft:25, fontSize:16, color: '#97d9f4'}}>Ze·​ro·X·Dex</p>*/}
                                {/* <h2 style={{}}>0xdex is a Decentralized Finance (DeFi) investment<br/>workflow <span style={{fontSize:16, color: '#97d9f4',fontWeight:100}}>+</span> portfolio manager.</h2>
                                <p>Our focus is on Ethereum, Base and sufficent Solana support firstly.</p> */}
                                <div style={{marginTop:22}}>
                                    <button onClick={walletClick} className="large outline">Connect Wallet</button>
                                </div>
                                <p style={{fontSize:11,lineHeight:'13px',marginBottom:0}} className="small">To access the DeFi app (dApp), your Metamask wallet must hold at least 1 million 0xdex tokens. You can then connect your wallet and utilize the platform.</p>
                                
                                <div className="flex-row">
                                    <div style={{marginRight:7}}>
                                        <button onClick={webClick} className="small outline">Learn more</button>
                                    </div>

                                    <div style={{}}>
                                        <button onClick={buyClick} className="small outline">How to buy</button>
                                    </div>

                                </div>
                                
                            
                            
                            </div>

                            

                            <div style={{background:`radial-gradient(circle at 99% 98%, ${heroColor} 0%, rgb(167 170 196 / 0%) 52%)`}} className="hero-hue-blue">
                            
                            </div>
                            <div className="hero-hue-dark-corner"/>
                        </div>

                    </div>


                ):null}
                



                <div style={{position:"relative",height:'100vh',flex: 1, overflow:'auto'}} className="flex-column">
                    
                    { urlRendered ? (
                        <>
                        
                        { xSpace ? (
                            <ListPresentation xSpaceToken={xSpace}/>
                        ):(
                            <HomeMeta/>
                        )}

                        </>
                    ):null}
                    
                    
                    
                    
                    
                </div>

            </div>
           

            <div style={{position:"absolute",left:22,bottom:22,zIndex:33}}>
                <div className="flex-row">
                    <img className="chain-icon large" src={require(`../../icons/logo_chain_ethereum.png`)}/>
                    <img style={{marginLeft:3}} className="chain-icon large" src={require(`../../icons/logo_chain_base.png`)}/>
                    <img style={{marginLeft:7}} className="chain-icon large" src={require(`../../icons/logo_chain_solana.png`)}/>
                    <img data-tip={`metamask`} style={{marginLeft:44,marginTop:3,filter:"grayscale(1)",transform:'scale(1.33)'}} className="chain-icon" src={require(`../../icons/logo_metamask.png`)}/>
                    {/* <img style={{marginLeft:44, width:55,height:55,marginTop:-27}} src={require(`../../media/logo_dexter_bot.png`)}/> */}
                </div>
            </div>

            

            <div style={{position:"absolute"}} className="hero-image-container">

                
                {/* <div className="hero-page">
                    <div className="hero-left">

                    </div>

                    <div className="hero-left">
                        
                    </div>


                </div> */}

                
                

            


                {/* <GridCanvas addClass={"lobby"} mouseX={mouseX} mouseY={mouseY} width={windowDimensions.width+100} height={windowDimensions.height+100}/> */}

                {/* { heroImage && (
                    <div className="hero-image" style={{filter:'brightness(.22)',backgroundImage:`url(${require(`../../media/photos/${heroImage}`)})`}} ref={imageRef}/>
                )} */}


            </div>

        </Layout>
    );
}
