import React, { useEffect, useState } from "react";

import { IconActionButton } from "../IconActionButton";

import { useCore } from "../../hooks/useCore";

import classNames from "classnames";

import { sleep } from "../../utils/tools";

import dingSound from '../../media/sounds/message.mp3';

const notificationSound = new Audio(dingSound);
notificationSound.volume = .44;


export function Notification({ n }) {

    let { closeNotification } = useCore();
    const [ addShow, setAddShow ] = useState(false);
    const [ intervalTimer, setIntervalTimer ] = useState(0);

    var closeInterval;
    var timer = 0;
    var hover = false;

    const frameInterval = 33;


    let [count, setCount] = useState(0);






    useEffect(() => {
        let interval = setInterval(() => {
            // setCount(count + 1)
            setCount(count => count + 1)

        }, frameInterval)

        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {

        console.log(n)

        setTimeout(() => {
            setAddShow(true);
            notificationSound.play();
        },100);



    }, []);

    useEffect(() => {

        // console.log(count, (time * frameInterval))
        if(count > n.time * frameInterval){
            closeNot();
        }

    }, [ count ]);





    const closeNot = async () => {

        setAddShow(false);
        await sleep(500);
        setAddShow(true);
        closeNotification(n);
        // setTimeout(() => {
        //     closeNotification(n);
        //
        // },1000);
    }

    const onHover = () => {
        // clearInterval(closeInterval);
        hover = true;
    }

    const onHoverOut = () => {
        // startCloseIntervalTimer();
        hover = false;
    }

    const notificationClick = (not) => {
        console.log('close notification')
    }

    const pwidth = ( 100 - ( count / (n.time * frameInterval)*100 ));


    return (

        <div
        className={classNames('notification', n.type, n.id, addShow && 'show' )}
        onClick={() => notificationClick(n)}
        onMouseEnter={onHover}
        onMouseLeave={onHoverOut}
        >
            <div style={{marginLeft:5,marginRight:5}}>
                { n.type == "success" ? (
                    <div className="icon-button-check-circle"/>
                ) : n.type == "warning" ? (
                    <div className="icon-button-triangle"/>
                ) : (
                    <div className="icon-button-minus-circle"/>
                )}
            </div>

            <IconActionButton passStyle={{right:'5px',top:'5px'}} type="close" passClick={() => closeNot()}/>

            <div className="flex-column">
                {n.msg}
            </div>
            <div className="progress-bar">
                <div style={{width: pwidth + '%'}} className="progress-indicator"/>
            </div>
        </div>

    )

}
