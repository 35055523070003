import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useMarket } from "../../hooks/useMarket";
import { useWallet } from "../../hooks/useWallet";
import { useDex } from "../../hooks/useDex";

import useSocketContext from '../../hooks/useSocketContext';

import toast from 'react-simple-toasts';

export function SocketDexTunnel() {

    const [requestingAccess, setRequestingAccess] = useState(false);
    const [error, setError] = useState(null);
    const socket = useSocketContext();
    const { handleNewPair, newPairs, fetchNewPairs } = useDex();
    const { walletAddress, user } = useWallet();
    const [ socketConnect, setSocketConnect ] = useState(false);

    const { handlePlaySound, newNotification, addToSocketLog, socketLog, handleUpdateTokenData } = useCore();


    useMemo(() => {
        if(walletAddress && user && !socketConnect){
            initDexTunnel("lobby");
            setSocketConnect(true);
        }
        // notificationSound.play();
  }, [ walletAddress, user ]);

    async function initDexTunnel(name) {
        setRequestingAccess(true);

        try {
          await socket.init(name); // namespace being passed, can be id of another room
        } catch (error) {
          setRequestingAccess(false);
        }

        socket.on('NEW_PAIR', (msg) => {
          // console.log(newPairs)
          // console.log('***new_pair', msg);
          handleNewPair(msg.pair);
          handlePlaySound(user.sfx.newpair);

          // setParticipants([msg.newParticipant]);
        });

        socket.on('NEW_CALL', (msg) => {
          console.log('new call',msg)
          // console.log('***new_pair', msg);
          // handleNewPair(msg.pair);
          handlePlaySound(user.sfx.call);

          // setParticipants([msg.newParticipant]);
        });

        socket.on('TOKEN_DATA_UPDATE', (msg) => {
          // console.log('*** token update *********************************************', msg.type,msg.data);
          handleUpdateTokenData(msg.msg.data);
        });

        socket.on('NEW_PAIR_FILTERED', (msg) => {
          console.log('***  new_pair filtered', msg);
          // handlePlaySound("blip");

          addToSocketLog(msg.msg);
          let d = {
              msg: msg.msg,
              type: 'warning',
              id: 1
          }
          newNotification(d);

          // notificationSound.play();
          // alert(msg.pair.baseToken.name + "   " + msg.pair.pairAddress )
          // setParticipants([msg.newParticipant]);
        });










        socket.on('ROOM_PING', (msg) => {

          // setParticipants([msg.newParticipant]);
          // console.log('room ping')
          socket.emit(
            'JOIN_ROOM',
            {
              wa: walletAddress,
              room: "newpairs"
              // roomUrl: `${process.env.REACT_APP_URL_VIDEO}/${spaceContext.token}`,
              // domain: 'http://localhost:3000',
            }
          );


        });

        // socket.on('ROOM_PING', joinRoom);
        //
        // function joinRoom(){
        //     console.log('room ping')
        //       socket.emit(
        //         'JOIN_ROOM',
        //         {
        //           wa: walletAddress,
        //           room: "newpairs"
        //           // roomUrl: `${process.env.REACT_APP_URL_VIDEO}/${spaceContext.token}`,
        //           // domain: 'http://localhost:3000',
        //         }
        //       );
        // }



        // TODO: MOVE THESE
        // socket.on('roomrequest', (msg) => {
        //   console.log('roomrequest');
        //   console.log(msg);
        //
        //   const Msg = ({ closeToast, toastProps }) => {
        //     function handleApprove() {
        //       socket.emit('roomapproval', {
        //         approved: true,
        //         participantId: msg.user._id,
        //       });
        //       closeToast();
        //     }
        //
        //     function handleDeny() {
        //       socket.emit('roomapproval', {
        //         approved: false,
        //         participantId: msg.user._id,
        //       });
        //       closeToast();
        //     }
        //
        //     return (
        //       <div>
        //         {msg.user.name} + ' would like to join!'
        //         <button onClick={handleApprove}>Apprrove</button>
        //         <button onClick={handleDeny}>Deny</button>
        //       </div>
        //     );
        //   };
        //
        //   toast(Msg);
        //   notificationSound.play();
        // });
        //

        //
        // socket.on('CHANGE_NAME', (msg) => {
        //   updateParticipant(msg.participantId, { name: msg.name });
        // });
        //
        socket.on('ROOM_JOINED', (msg) => {
          // console.log('room joined', msg);
          // removeParticipant(msg.id);
        });
        //
        // socket.on('ROOM_APPROVAL', async (msg) => {
        //   const { participant, approved } = msg;
        //
        //   //TODO: handle not approved
        //   if (!approved) {
        //     toast('The host has denied access.');
        //   } else {
        //     try {
        //       console.log('connecting to twilio stream');
        //       await videoContext.connect(participant.twilioAccessToken);
        //     } catch (error) {
        //       console.log('error connecting to channel');
        //     }
        //   }
        //
        //   setAccessPending(false);
        // });

        // socket.emit(
        //   'ROOM_JOIN_REQUEST',
        //   {
        //     videoInstanceId: spaceContext._id,
        //     roomId: roomId,
        //     inviteToken: inviteToken,
        //     isHidden: false,
        //     userName: name || viewerName,
        //     roomUrl: `${process.env.REACT_APP_URL_VIDEO}/${spaceContext.token}`,
        //     domain: 'http://localhost:3000',
        //   },
        //   handleRoomJoinResponse
        // );

        socket.on('RECONNECT_ROOMS', () => {
            firstPing();
        })


        function firstPing(){
            socket.emit(
              'FIRST_PING',
              {
                wa: walletAddress,
                room: "lobby",
                // roomUrl: `${process.env.REACT_APP_URL_VIDEO}/${spaceContext.token}`,
                // domain: 'http://localhost:3000',
              },
              handleJoinResponse
            );
        }

        firstPing();




    }


    async function handleJoinResponse(data) {

        console.log('***data', data);


        if (data.error) {
          return setError(data.error);
        }



        // // TODO: ADD TO STATE
        // const participantId = data.participant._id;
        // const { approvalState, twilioAccessToken } = data.participant;
        // const conversationSID = data.videoRoom.twilioConversation.sid;
        //
        // setViewerParticipantId(participantId);
        //
        // if (approvalState === 'pending') {
        //   setAccessPending(true);
        // }
        //
        // if (approvalState === 'approved') {
        //   await videoContext.connect(twilioAccessToken);
        // }
        //
        // if (vfeatures.allowTextMessaging) {
        //   await chatContext.connect(twilioAccessToken, conversationSID);
        // }
        //
        // setParticipants(data.roomUsers);
        //
        // setRequestingAccess(false);
    }

    return (
        <div style={{pointerEvents: 'none'}} className="socket-panel">
            {/*<p>{socketLog}</p>*/}
        </div>
    );
}
