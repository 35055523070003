import React, { useEffect } from "react";


import {ReactComponent as UniswapLogo} from '../../icons/svgs/logo_uniswap.svg';
import {ReactComponent as ApeswapLogo} from '../../icons/svgs/logo_apeswap.svg';
import {ReactComponent as BiswapLogo} from '../../icons/svgs/logo_biswap.svg';
import {ReactComponent as SushiswapLogo} from "../../icons/svgs/logo_sushiswap.svg";
import {ReactComponent as FraxswapLogo} from "../../icons/svgs/logo_fraxswap.svg";
import {ReactComponent as PancakeLogo} from '../../icons/svgs/logo_pancakeswap.svg';
import {ReactComponent as ShibaswapLogo} from "../../icons/svgs/logo_shibaswap.svg";
import {ReactComponent as KibaswapLogo} from '../../icons/svgs/logo_kibaswap.svg';

import sushilogo from "../../icons/logo_sushi.png";
import otsealogo from "../../icons/wallets/logo_otsea.png";
import x7logo from "../../icons/logo_x7.png";


export function SwapIcon({ swap }) {


   

    const getIcon = () => {
        if(swap == "uniswap"){
            return (
                <UniswapLogo/>
            )
        }else if(swap=="x7swap"){
            return (
                <img src={x7logo}/>
            )
        }else if(swap=="kibaswap"){
            return (
                <KibaswapLogo/>
            )
        }else if(swap=="otsea"){
            return (
                <img src={otsealogo}/>
            )
       
        }else if(swap=="fluxbeam"){
            return (
                <img src={require(`../../icons/logo_fluxbeam.png`)}/>
            )
        }else if(swap=="raydium"){
            return (
                <img src={require(`../../icons/logo_raydium.png`)}/>
            )
        }else if(swap=="meteora"){

            return (
                <img src={require(`../../icons/logo_meteora.png`)}/>
            )

        }else if(swap=="orca"){
            return (
                <img src={require(`../../icons/logo_orca.png`)}/>
            )
        }else if(swap=="pancakeswap"){
            return (
                <PancakeLogo/>
            )
        }else if(swap=="traderjoe"){

            return (
                <img src={require(`../../icons/logo_traderjoe.png`)}/>
            )

        }else if(swap=="mimo"){

            return (
                <img src={require(`../../icons/logo_mimo.png`)}/>
            )

        }else if(swap=="thruster"){

            return (
                <img src={require(`../../icons/logo_thruster.png`)}/>
            )

        }else if(swap=="sushiswap"){

            return (
                <img src={sushilogo}/>
            )

        }else if(swap=="shibaswap"){

            return (
                <ShibaswapLogo/>
            )

        }else if(swap=="fraxswap"){

            return (
                <FraxswapLogo/>
            )

        }else if(swap=="jupiterswap"){

            return (
                <img src={require(`../../icons/logo_jupiter.png`)}/>
            )

        }else if(swap=="raydiumswap"){

            return (
                <img src={require(`../../icons/logo_raydium.png`)}/>
            )

        }else if(swap=="baseswap"){

            return (
                <img src={require(`../../icons/logo_baseswap.png`)}/>
            )

        }else if(swap=="apeswap"){

            return (
                <ApeswapLogo/>
            )

        }else{
            return (
                <p>{swap}</p>
            )
        }
    }



    return (

        <>
            {getIcon(swap)}
        </>

    )


}




