import React, { useEffect, useState } from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";
import { addCommas, blankAddress, deadAddress, getTimeDisplay, getTimeDiff, buildToken } from "../../utils/tools";

import { TokenSymbolIcon } from "./TokenSymbolIcon";

import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import classNames from "classnames";

import {ReactComponent as TrendingIcon} from '../../icons/svgs/trending.svg';
import { ReactComponent as Seedling } from "../../icons/svgs/seedling.svg";

var parse = require('html-react-parser');

export function DexPairCard({t, addStyle=null, dataTime="h24", dataTimeLabel="24h"}) {

    const {
		handleScreenClickDims,
        loading,
        fetchTokenPair,
        handleTokenPair,
        caToken,
        token,
        
        ethTrendingKey,
        tokenLists,
        handleAddToTokenList,
        removeTokenFromList,
        fetchPortfolioTokens,
        ethFactor,
        
        handleDexView
	} = useDex();

    const {
        modalVisible,
        sidebarRight,
        handleSidebarRight,
        ethTrending,
        listPage
    } = useCore();

    const navigator = useNavigator();

    const handleClick = async () => {
        // let ex = e.clientX;
            // let ey = e.clientY + 33;
            // console.log(ex,ey)
            // handleScreenClickDims(ex,ey);
                    // console.log(t);
    
       

        setTimeout(() => {
            navigator.push(`/${t.defaultPair.data.chainId}/${t.defaultPair.address}?view=home`);
        },200)

        handleSidebarRight(true);

        // if(passClick){
        //     passClick();
        // }
        

        // fetchTokenPair(tpair.data.pairAddress);
    }

    const now = new Date();

    const pair = t.defaultPair || t.pair;

    return (
        <>

            
            <div onClick={handleClick} style={addStyle ? addStyle : {}} className={classNames("feature-card-token data-panel interactive solid", t && token && token.address == t.address && "active", t.trending !== undefined ? "trending" : "")}>
                {t.trending !== undefined ? (
                    <div style={{}} className="feature-card-token-header">
                        <div style={{justifyContent:'flex-end',padding:'0 2px'}} className="data-row">
                            { t.trending.map((t, i) => (
                                <div key={i} style={{marginLeft:4}} className="data-tab-image">
                                    <img src={require(`../../icons/trending/logo_${t}.png`)}/>
                                </div>
                            ))}
                            
                        </div>

                    </div>
                    
                ):null}
                
                <TokenSymbolIcon
                token={t}
                ca={t.defaultPair.ta}
                symbol={t.defaultPair.symbol}
                name={t.defaultPair.name}
                addClass="large"
                />
                <div style={{position:"absolute",display:"flex",zIndex:3,alignItems:"flex-end",bottom:4,left:7}}>
                    
                    <img data-tip={`${pair.data.chainId}`} className="chain-icon" src={require(`../../icons/logo_chain_${t.defaultPair.data.chainId}.png`)}/>

                    { getTimeDiff(now,pair.data.pairCreatedAt) < 24 * 7 ? (
                        <div style={{width:15,height:15,marginRight:1,marginLeft:2}} className="svg-icon">
                            <Seedling/>
                        </div>
                    ):(
                        <div style={{opacity:.7,marginRight:2,marginLeft:5}} className="icon-button-clock"/>
                    )}
                    <div style={{lineHeight:'11px',transform:'scale(.88)',opacity:.77}} className="data-value">{getTimeDisplay(pair.data.pairCreatedAt)}</div>
                </div>
                
                <div style={{position:"absolute",bottom:29,right:7,zIndex:33}} className="data-item">
                    {/* <div className="data-label no-pad">
                        USD
                    </div>
                    <div style={{lineHeight:'10px',marginBottom:11}} className="data-value no-prefix">
                        {parse(condenseZeros(formatCurrency(t.pair.data.priceUsd, "USD")))}
                    </div> */}
                    {/* <div className="flex-row"> */}
                        {/* <div className="data-column align-left">
                            <div className="data-label no-pad">
                                USD
                            </div>
                            <div style={{lineHeight:'10px',marginBottom:3}} className="price data-value">
                                {parse(condenseZeros(formatCurrency(t.defaultPair.data.priceUsd, "USD")))}
                            </div>
                        </div> */}
                        <div className="data-column align-right">
                            <div className="data-label no-pad">
                                fdv
                            </div>
                            <div style={{lineHeight:'10px',marginBottom:11}} className="data-value">
                                <span className="small">$</span>{parse("" + formatCurrencyScale(t.defaultPair.data.fdv))}
                            </div>
                        </div>
                    {/* </div> */}
                    
                    <div className="data-label no-pad align-right">
                        {dataTimeLabel}
                    </div>
                    <div style={{lineHeight:'13px'}} className="data-value green percent large align-right">
                    { parse(formatPercent(String(t.defaultPair.data.priceChange[`${dataTime}`]))) }
                    </div>
                </div>

                <div style={{marginTop:73}} className="feature-card-token-footer">
                    <div style={{justifyContent:'flex-end',padding:'0 2px'}} className="data-row">
                      
                        
                    </div>

                </div>
                
            </div>      
            
        
        </>
        
    );
}


