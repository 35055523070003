import React, { useState, useRef, useEffect, useMemo } from "react";

import { Modal } from "./";
import { FormTokenList } from "../Form/FormTokenList";
import { InputTextField } from "../Input/InputTextField";
import { InputTextArea } from "../Input/InputTextArea";
import { InputTabs } from "../Input/InputTabs";
import { InputToggle } from "../Input/InputToggle";
import { InputColorPick } from "../Input/InputColorPick";
import { CurrencyItem } from "../SwapCurrency/CurrencyItem";
import { NFTItem } from "../Wallet/NFTItem";
import { DexPairItem } from "../Dex/DexPairItem";
import { TokenSymbolIcon } from "../Dex/TokenSymbolIcon";
import { LoaderSpinner } from "../Layout/LoaderSpinner";

// import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useForm } from "react-hook-form";
// import { useExchange } from "../../hooks/useExchange";
import { useCollection } from "../../hooks/useCollection";
import { useDex } from "../../hooks/useDex";
import { useSocial } from "../../hooks/useSocial";

import logo0x from "../../icons/logo_0xdex.png";

import { QRCodeSVG } from 'qrcode.react';
import classNames from "classnames";
import { debounce } from "lodash";
import { DebounceInput } from 'react-debounce-input';
import { addCommas } from "../../utils/tools";
// import { client } from "../../services/api";

export function ModalCore({ navigator }) {

    const { modalVisible,
        handleModalVisible,
        newPageModal,
        handleNewPageModal,
        createPage,
        exchangeModal,
        handleExchangeModal,
        qrModal,
        handleQrModal,
        collectionModal,
        handleCollectionModal,
        tokenListModal,
        handleTokenListModal,
        dexSearchModal,
        handleDexSearchModal,
        qrurl,
        modalTitle,
        swapSettingsModal,
        handleSwapSettingsModal,
        fullscreen,
        toggleFullscreen,

    } = useCore();


    // const {
    //     collections,
    //     createCollection
    // } = useCollection();


    const { register, handleSubmit, errors } = useForm();

    const [ pageTitle, setPageTitle ] = useState('');
    const [ collectionTitle, setCollectionTitle ] = useState('');
    const [ collectionColor, setCollectionColor ] = useState('#0079FF');

    const [ tokenListTitle, setTokenListTitle ] = useState('');
    const [ tokenListColor, setTokenListColor ] = useState('#0079FF');

    const [ qrDownRoll, setQrDownRoll] = useState(false);

    // const { tokens,
    //         chainTokens,
    //         tokenInput,
    //         tokenOutput,
    //         handleTokenInput,
    //         handleTokenOutput,
    //         fetchQuote,
    //         selectTokenMode,
    //         tokenSearch,
    //         handleTokenSearch,
    //         tokenLoading,
    //         tokenSearchMeta,
    //         slippage,
    //         handleSlippage
    // } = useExchange();

    let {
        dexSearchPairs,
        dexSearch,  // string
        searchDex, // function to search
        handleDexSearch,
        handleTokenPair,
        handleTokenToolbarVisible,
        loading,
        tokenLists,
        createTokenList,
        tokenList,
        handleTokenList,
        token,
        caToken
    } = useDex();

    const pairData = token && token !== undefined && token.pair?.data !== undefined ? {...token.pair.data} : null;

    const {
        chainName,
        walletAddress,
        balances,
        nft,
        assetCheck,
        handleAssetCheck,
        nftAssets,
        inApp,
        adminWallet
    } = useWallet();

    const [qrType, setQrType] = useState('view');
    const [qru, setQru] = useState(null);

    const exchangeSearchField = useRef(null);
    const dexSearchField = useRef(null);

    useEffect(() => {
        if(qrurl){
            setQru(qrurl);
        }
    }, [ qrurl ]);

    useEffect(() => {
        const handleKeyPress = (event) => {
          if (event.keyCode === 27) {
            closeModal();
          }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
    }, [  ]);


    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if(collectionModal){
                onCollectionSubmit();
            }else if(newPageModal){
                onPageSubmit();
            }
        }
    };

    const onCollectionTitleChange = (v) => {
        setCollectionTitle(v);
    }

    const onCollectionColorChange = (v) => {
        setCollectionColor(v);
    }

    const onCollectionSubmit = async () => {
        let c = {
            name: collectionTitle,
            walletAddress: walletAddress,
            color: collectionColor
        }
        // createCollection(c);
        setCollectionTitle('');
        closeModal();
    };


    

    const onPageTitleChange = (v) => {
        setPageTitle(v);
    }

    const onPageSubmit = async (v) => {
        createPage(pageTitle);
        setPageTitle('');
        closeModal();
    };

    // const selectToken = (v) => {
    //     console.log(v);
    //     if(selectTokenMode=="input"){
    //         handleTokenInput({...v, "value": ""});
    //         handleTokenOutput({...tokenOutput, "value": ""});
    //     }else if(selectTokenMode=="output"){
    //         handleTokenOutput({...v, "value": ""});
    //         if(tokenInput.value !== undefined && tokenInput.value.length){
    //             fetchQuote(tokenInput.value);
    //         }
    //         // handleTokenInput({...tokenInput, "value": ""})
    //     }
    //     closeModal();
    // }
    //
    // const changeTokenSearch = (e) => {
    //     handleTokenSearch(e.target.value);
    // }
    //
    // const changeTokenSearchDebounced = useMemo(
    //     () => debounce(changeTokenSearch, 500)
    // , []);

    const changeDexSearch = (e) => {
        handleDexSearch(e.target.value);
      
        if(e.target.value.length > 1){
            searchDex(e.target.value);
        }

    }

    // const changeDexSearchDebounced = useMemo(
    //     () => debounce(changeTokenSearch, 500)
    // , []);

    const selectPair = (p) => {
        console.log(p);
    }

    const closeModal = () => {
        handleModalVisible(false);
        handleNewPageModal(false);
        handleExchangeModal(false);
        handleCollectionModal(false);
        handleSwapSettingsModal(false);
        handleDexSearchModal(false);
        handleTokenListModal(false);
        handleQrModal(false);
        // handleTokenSearch('');
        handleAssetCheck(false);
        handleDexSearch('');
    }

    // const handleSearchMetaClick = () => {
    //
    //     let t = {
    //         address: tokenSearchMeta.address,
    //         decimals: tokenSearchMeta.decimals,
    //         logoURI:'',
    //         name: tokenSearchMeta.name,
    //         symbol: tokenSearchMeta.symbol,
    //         degen: true
    //     }
    //     selectToken(t);
    //     closeModal();
    // }

    // const changeSlippage = (e) => {
    //     handleSlippage(e.target.value);
    // }

    const downloadQR = () => {
        let svgtext = document.querySelector('.qr-code-container svg');
        let xmlmeta = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ';
        let svgstring = `<?xml version="1.0" encoding="utf-8"?>
        <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`
        svgstring += svgtext.outerHTML.replace('<svg ', xmlmeta);
        console.log(svgstring);
        const element = document.createElement("a");
        const file = new Blob([svgstring], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        let mtitle = modalTitle.replace(' - ', '_').replace("#","");
        let dfile = `${mtitle}_qrcode.svg`;
        element.download = dfile.replace(/ /g, '+');
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const handleQrType = (v) => {
        let q = qrurl;
        if(v=='view'){
            setQru(q)
        }else{
            q = qrurl.replace('/assets/',`/assets/${v}/`);
            setQru(q);
        }
        setQrType(v);
    }

    // const sortedTokens = Object.keys(tokens[chainName])
    // .filter((key) => true)
    // .reduce((obj, key) => {
    //     return Object.assign(obj, {
    //         [key]: tokens[chainName][key]
    //     });
    // }, {});

    // const sortedTokens = Object.keys(tokens[chainName])
    // .filter((key) => true)
    // .reduce((obj, key) => {
    //     return Object.assign(obj, {
    //         [key]: tokens[chainName][key]
    //     });
    // }, {});


   //  const filteredTokens = !tokenSearch
   //  ? chainTokens
   //  : chainTokens.filter((t) =>
   //      (t.name.toLowerCase().includes(tokenSearch.toLowerCase()) || t.symbol.toLowerCase().includes(tokenSearch.toLowerCase()))
   //      // && t.name !== tokenInput.name && t.name !== tokenOutput.name
   //  )
   //  // .sort((a, b) => a["name"].indexOf(tokenSearch.toLowerCase()) > b["name"].indexOf(tokenSearch.toLowerCase()) ? 1 : -1)
   //  // .sort((a, b) => a["symbol"].indexOf(tokenSearch.toLowerCase()) < b["symbol"].indexOf(tokenSearch.toLowerCase()) ? 1 : -1);
   //  .sort(function(a, b) {
   //      let ascore = -1, bscore = -1;
   //      let aname = a["name"].toLowerCase().indexOf(tokenSearch.toLowerCase()) + 1;
   //      let bname = b["name"].toLowerCase().indexOf(tokenSearch.toLowerCase()) + 1;
   //      let asymbol = a["symbol"].toLowerCase().indexOf(tokenSearch.toLowerCase()) + 1;
   //      let bsymbol = b["symbol"].toLowerCase().indexOf(tokenSearch.toLowerCase()) + 1;
   //      if( a["symbol"].toLowerCase() == tokenSearch.toLowerCase() ){
   //          ascore = 5000;
   //      }else if( aname > 0 && asymbol > 0){
   //
   //          ascore = ((500/asymbol) + (80/aname)) * 2;
   //      }else{
   //          if( aname > 0 ){
   //              ascore = 80 / aname;
   //          }
   //          if( asymbol > 0 ){
   //              ascore = 400 / asymbol;
   //          }
   //      }
   //      if( b["symbol"].toLowerCase() == tokenSearch.toLowerCase() ){
   //          bscore = 5000;
   //      }else if( bname > 0 && bsymbol > 0){
   //          bscore = ((500/bsymbol) + (80/bname)) * 2;
   //      }else{
   //          if( bname > 0 ){
   //              bscore = 80 / bname;
   //          }
   //          if( asymbol > 0 ){
   //              bscore = 400 / bsymbol;
   //          }
   //      }
   //
   //      return ascore > bscore ? 1 : -1;
   // });


    const onDexTokenClick = (p) => {
        // handleTokenPair(p);
        // handleTokenToolbarVisible(true);
        closeModal();
    }

    

    const dexSearchPairsFiltered = dexSearchPairs.filter((t) => (
        t.liquidity !== undefined
        && (t.liquidity.usd > 5000)
        && t.fdv/t.liquidity.usd > .02
        && ( t.baseToken.symbol.toLowerCase().indexOf(dexSearch.toLowerCase()) > -1
            || t.baseToken.symbol.toLowerCase() == dexSearch.toLowerCase()
            || t.baseToken.name.toLowerCase().indexOf(dexSearch.toLowerCase()) > -1
            || t.baseToken.name.toLowerCase() == dexSearch.toLowerCase()
            )
    )).sort((a, b) => ( (b.baseToken.symbol.toLowerCase() == dexSearch.toLowerCase() && a.baseToken.symbol.toLowerCase() !== dexSearch.toLowerCase()) || a["volume"].h24 < b["volume"].h24 ? 1 : -1));
    // )).sort((a, b) => ( a.baseToken.symbol.toLowerCase() == dexSearch.toLowerCase() && b.baseToken.symbol.toLowerCase() !== dexSearch.toLowerCase()) || a["volume"].h24 > b["volume"].h24 ? 1 : -1);

    return (
        <>
            <Modal init={modalVisible}
            close={() => closeModal()}>

                { loading.dexsearch && (
                    <LoaderSpinner/>
                )}


                { newPageModal && (
                    <>

                        <InputTextField
                            title={'Page Title'}
                            onChange={onPageTitleChange}
                            placeholder='e.g. "My Tokens"'
                            value={pageTitle}
                            onKeyPress={_handleKeyDown}
                            autoComplete="off"
                            // focus={true}
                        />

                        {/*    <div className="input-group">
                            <input
                                name="sceneTitle"
                                // ref={register({ required: true })}
                                className="large"
                                placeholder='e.g. "New Scene"'
                                type="text"
                                onKeyDown={_handleKeyDown}
                            />
                            <label>Scene Name</label>

                            {errors.title && (
                                <span class="error">This field is required</span>
                            )}

                        </div>*/}
                        <div className="input-group">
                            <button
                                className="large action"
                                onClick={handleSubmit(onPageSubmit)}
                            >
                                Create
                            </button>
                        </div>
                    </>
                )}


                { collectionModal && (
                    <>

                        <div style={{height:22,width:'100%'}}/>

                        <InputTextField
                            title={'Collection name'}
                            onChange={onCollectionTitleChange}
                            placeholder='e.g. "My Favorites"'
                            value={collectionTitle}
                            onKeyPress={_handleKeyDown}
                            autoComplete="off"

                            // focus={true}
                        />

                        <InputColorPick
                        title="Collection Color"
                        onChange={onCollectionColorChange}
                        value={collectionColor}
                        />

                        {/*    <div className="input-group">
                            <input
                                name="sceneTitle"
                                // ref={register({ required: true })}
                                className="large"
                                placeholder='e.g. "New Scene"'
                                type="text"
                                onKeyDown={_handleKeyDown}
                            />
                            <label>Scene Name</label>

                            {errors.title && (
                                <span class="error">This field is required</span>
                            )}

                        </div>*/}
                        <div className="input-group">
                            <button
                                className="large action"
                                onClick={handleSubmit(onCollectionSubmit)}
                            >
                                Create
                            </button>
                        </div>
                    </>
                )}

                { tokenListModal ?(
                    <FormTokenList mode={tokenListModal} closeModal={closeModal} tokenList={tokenList}/>
                ):null}


                { dexSearchModal && (
                    <>

                        <h6 style={{margin:16}}>Find Pair</h6>



                        <div className="input-group">
                            <DebounceInput
                              className="large"
                              placeholder="search dex"
                              autoComplete="off"
                              autoFocus
                              minLength={1}
                              debounceTimeout={700}
                              value={dexSearch}
                              onChange={e => changeDexSearch(e)} />
                            <label className="hidden">Search</label>
                        </div>


                        {/*{ tokenSearchMeta && (
                            <CurrencyItem passClick={handleSearchMetaClick} token={tokenSearchMeta} key={'9999'}/>
                        )}

                        { tokenLoading && (
                            <div className="loader-spinner"/>
                        )}*/}



                        <div className="coins-items-container exchange">
                            { dexSearchPairsFiltered
                                //.sort((a, b) => a["balance"]*1 < b["balance"]*1 ? 1 : -1)
                                .map((tp, i) => (
                                    <DexPairItem key={`sdpi${i}`} hideOptions={true} withSpace={true} hideRank={true} passClick={onDexTokenClick} pairData={tp} coreToken={token}/>
                                )
                            )}
                        </div>



                        {/*{ chainTokens !== undefined && (
                            <div className="coins-items-container exchange">
                                { filteredTokens
                                    .sort((a, b) => a["balance"]*1 < b["balance"]*1 ? 1 : -1)
                                    .map((t, i) => (
                                        <CurrencyItem passClick={selectToken} token={t} key={i}/>
                                    )
                                )}
                            </div>
                        )}*/}




                    </>
                )}




                {/*{ exchangeModal && (
                    <>

                        <h6 style={{margin:16}}>Select Token</h6>

                        <div className="input-group">
                            <input
                                name="search"
                                // ref={register({ required: true })}
                                onChange={changeTokenSearchDebounced}
                                className="large"
                                placeholder="search"
                                type="text"
                                autoComplete="off"
                                // value={tokenSearch}
                                // ref={exchangeSearchField}
                                ref={el => { console.log(el); exchangeSearchField.current = el; }}
                                focus="true"
                                // onKeyDown={_handleKeyDown}
                            />
                            <label className="hidden">Search</label>

                            {errors.title && (
                                <span class="error">This field is required</span>
                            )}

                        </div>

                        { tokenSearchMeta && (
                            <div onClick={handleSearchMetaClick} className="coin-item">

                                <div className="coin-meta">
                                    <p className="coin-name">{tokenSearchMeta.name}</p>
                                    <p className="coin-symbol">{tokenSearchMeta.symbol}</p>
                                </div>
                            </div>
                        )}

                        { tokenSearchMeta && (
                            <CurrencyItem passClick={handleSearchMetaClick} token={tokenSearchMeta} key={'9999'}/>
                        )}

                        { tokenLoading && (
                            <div className="loader-spinner"/>
                        )}


                        { chainTokens !== undefined && (
                            <div className="coins-items-container exchange">
                                { filteredTokens
                                    .sort((a, b) => a["balance"]*1 < b["balance"]*1 ? 1 : -1)
                                    .map((t, i) => (
                                        <CurrencyItem passClick={selectToken} token={t} key={i}/>
                                    )
                                )}
                            </div>
                        )}




                    </>
                )}*/}



                {/*{ swapSettingsModal && (
                    <>

                        <h6 style={{margin:16}}>Settings</h6>

                        <div style={{marginBottom:20}} className="input-group">



                            <input
                                name="slippage"
                                // ref={register({ required: true })}
                                onChange={changeSlippage}
                                className="large number slippage"
                                placeholder=""
                                type="text"
                                autoComplete="off"
                                value={slippage}
                                // ref={exchangeSearchField}
                                // ref={el => { console.log(el); exchangeSearchField.current = el; }}
                                focus="true"
                                // onKeyDown={_handleKeyDown}
                            />
                            <label>Slippage (%)</label>

                        </div>

                    </>

                )}*/}

                { qrModal && inApp && walletAddress && qru &&  (
                    <>

                        {/*<h6 style={{margin:16}}>{modalTitle}</h6>
                        <div style={{margin:16}}>
                            <InputTabs
                                onChange={handleQrType}
                                value={qrType}
                                tabs={['view', 'check', 'get']}
                                tabsValues={['view', 'check', 'get']}
                            />
                        </div>*/}
                        { qru.indexOf('/list/' > -1) ? (
                            <div style={{paddingLeft:11,paddingTop:11}}>
                                <p>{tokenList.name}</p>>
                            </div>
                        ):(
                            <div style={{paddingLeft:11,paddingTop:11}}>
                                <TokenSymbolIcon
                                    token={token}
                                    ca={pairData.baseToken.address}
                                    symbol={pairData.baseToken.symbol}
                                    name={pairData.baseToken.name}
                                    // addClass="large"
                                />
                            </div>
                        )}
                       
                        
                        {/*<p style={{fontSize:9}}>{qru}</p>*/}

                        <div style={{marginBottom:20}} className="qr-code-container">
                            <QRCodeSVG
                            value={qru}
                            size="512"
                            bgColor="#FFFFFF"
                            fgColor="#1d1d1d" />
                        </div>
                        <div style={{paddingLeft:11,}}>
                            <button onClick={downloadQR} className="icon-download"></button>
                        </div>
                    </>

                )}


                { qrModal && (!inApp || (inApp && !walletAddress)) &&  (
                    <>

                        {/*<h6 style={{margin:16}}>{modalTitle}</h6>
                        <div style={{margin:16}}>
                            <InputTabs
                                onChange={handleQrType}
                                value={qrType}
                                tabs={['view', 'check', 'get']}
                                tabsValues={['view', 'check', 'get']}
                            />
                        </div>*/}

                        <div style={{paddingLeft:11,paddingTop:11}}>
                            <div style={{maxWidth:99,}}>
                                <img style={{marginTop:0,maxWidth:'100%'}} src={logo0x} alt="0xdex logo" />
                            </div>
                        </div>

                        {/*<p style={{fontSize:9}}>{qru}</p>*/}

                        <div style={{marginBottom:20}} className="qr-code-container">
                            <QRCodeSVG
                            value={qru}
                            size="512"
                            bgColor="#FFFFFF"
                            fgColor="#1d1d1d" />
                        </div>
                        <div style={{paddingLeft:11,}}>
                            <button
                            onMouseEnter={() => setQrDownRoll(true)}
                            onMouseLeave={() => setQrDownRoll(false)}
                            style={{fontWeight:'bold'}} onClick={downloadQR} className={classNames(qrDownRoll ? "icon-button-smile" : "icon-download")}></button>
                        </div>
                    </>

                )}


                { assetCheck && (
                    <>

                        { nftAssets && assetCheck !== "empty" ? (
                            <>

                                <div style={{margin:16}} className="flex-column">
                                    <div className="data-item row">
                                        <div className="data-value">Assets confirmed <span className="mute"> &nbsp;::&nbsp;</span></div>
                                        <div className="icon-check"></div>
                                        <div style={{marginLeft:22}} className="count-circle">
                                            {nftAssets.length}
                                        </div>
                                    </div>
                                    {/*<h6>{nftAssets[0].collection.name}</h6>*/}

                                </div>

                                <div style={{backgroundImage: `url(${nft.collection.banner_image_url})`}} className="nft-project-banner">
                                    <div className={classNames('nft-project-meta-container', 'transition', fullscreen && 'full')}>
                                        <div onClick={toggleFullscreen} style={{backgroundImage: `url(${nft.collection.image_url})`}} className="nft-project-image">

                                        </div>
                                        <div className="nft-project-meta">

                                            <div className="flex-row">
                                                <p className="nft-project-title">{nft.collection.name}</p>

                                                { nft.collection.safelist_request_status == "verified" && (
                                                    <div className="svg-icon bluecheckmark">
                                                        <svg aria-label="verified-icon" class="sc-50keu7-0 eWEXKZ" fill="none" viewBox="0 0 30 30"><path d="M13.474 2.80108C14.2729 1.85822 15.7271 1.85822 16.526 2.80108L17.4886 3.9373C17.9785 4.51548 18.753 4.76715 19.4892 4.58733L20.9358 4.23394C22.1363 3.94069 23.3128 4.79547 23.4049 6.0278L23.5158 7.51286C23.5723 8.26854 24.051 8.92742 24.7522 9.21463L26.1303 9.77906C27.2739 10.2474 27.7233 11.6305 27.0734 12.6816L26.2903 13.9482C25.8918 14.5928 25.8918 15.4072 26.2903 16.0518L27.0734 17.3184C27.7233 18.3695 27.2739 19.7526 26.1303 20.2209L24.7522 20.7854C24.051 21.0726 23.5723 21.7315 23.5158 22.4871L23.4049 23.9722C23.3128 25.2045 22.1363 26.0593 20.9358 25.7661L19.4892 25.4127C18.753 25.2328 17.9785 25.4845 17.4886 26.0627L16.526 27.1989C15.7271 28.1418 14.2729 28.1418 13.474 27.1989L12.5114 26.0627C12.0215 25.4845 11.247 25.2328 10.5108 25.4127L9.06418 25.7661C7.86371 26.0593 6.6872 25.2045 6.59513 23.9722L6.48419 22.4871C6.42773 21.7315 5.94903 21.0726 5.24777 20.7854L3.86969 20.2209C2.72612 19.7526 2.27673 18.3695 2.9266 17.3184L3.70973 16.0518C4.10824 15.4072 4.10824 14.5928 3.70973 13.9482L2.9266 12.6816C2.27673 11.6305 2.72612 10.2474 3.86969 9.77906L5.24777 9.21463C5.94903 8.92742 6.42773 8.26854 6.48419 7.51286L6.59513 6.0278C6.6872 4.79547 7.86371 3.94069 9.06418 4.23394L10.5108 4.58733C11.247 4.76715 12.0215 4.51548 12.5114 3.9373L13.474 2.80108Z" class="sc-50keu7-1 jQQIn"></path><path d="M13.5 17.625L10.875 15L10 15.875L13.5 19.375L21 11.875L20.125 11L13.5 17.625Z" fill="white" stroke="white"></path></svg>

                                                    </div>
                                                )}



                                            </div>
                                            { nft.collection.stats?.total_supply && (
                                                <p className="nft-project-title-sub">{addCommas(nft.collection.stats.total_supply)} <span className="mute">items</span></p>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                <div className="nft-thumbs">
                                    { nftAssets.map((n, i) => (
                                        <NFTItem type="static" ca={n.asset_contract.address} includeInfo={false} nft={n}/>
                                    ))}

                                </div>

                            </>
                        ):(

                            <>

                                <div className="flex-row align center">
                                    <h6 style={{margin:16}}>No assets found.</h6>

                                </div>

                            </>
                        )}





                    </>

                )}




            </Modal>

            {/*
            <div className="input-group">
                <label>Save current styles as a preset theme</label>

                <div className="input-combo over">
                    <input
                        value={newThemeName}
                        className="small outline"
                        placeholder="Name this preset"
                        type="text"
                        onChange={handleNewThemeName}
                        onKeyDown={_handleKeyDown}
                    />

                    <button
                        className="xsmall neutral"
                        onClick={handleCreateTheme}
                    >
                        Save
                    </button>
                </div>

                {themeError && (
                    <div className="error">{themeError}</div>
                )}
            </div>

            <div className="themes-container">
                {themes.map((theme, i) => (
                    <MapSettingsThemePreset theme={theme} key={i} />
                ))}
            </div>


            */}
        </>
    );
}
