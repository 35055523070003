import React, { useEffect, useState, useRef } from "react";


import { useCore } from "../../hooks/useCore";
import { useCollection } from "../../hooks/useCollection";
import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";
import { useSocial } from "../../hooks/useSocial";


import classNames from "classnames";

import * as tools from "../../utils/tools";



import {ReactComponent as WalletIcon} from '../../icons/svgs/wallet.svg';



export function CollectionOptionsCard() {

	const { assetToAdd,
		handleAssetToAdd,
		handleAddToCollection,
		collections,
		cox,
		coy,
		handleShowCollectionsOptions,
		showCollectionsOptions,
		handleTokenListCollectionModal,
		tokenListCollectionModal,
		twitterUserListCollectionModal,
		handleTwitterUserListCollectionModal,
	} = useCollection();

	const { user, addTokenToPortfolio, removeTokenFromPortfolio, hideToken, walletAddress, adminWallet, updateAdminSettings  } = useWallet();

	const { tokenLists, handleAddToTokenList, token, fetchPortfolioTokens, homeMeta } = useDex();

	let { tokenListModal, handleCollectionModal} = useCore();

	let { twitterUserLists, twitterSearchedUser, handleAddToTwitterUserList } = useSocial();

	const [ inPortfolio, setInPortfolio ] = useState(null);

	const coptions = useRef(null);

	const setTokenAsFeatured = () => {
		console.log(token)
		updateAdminSettings({
			featuredMetaToken: token._id
		});
	}


	useEffect(() => {

		if(tokenListCollectionModal){
			if(user && user.portfolioTokens !== undefined){
				let tempin = false;
				for(var i=0;i<user.portfolioTokens.length;i++){
					if(user.portfolioTokens[i] == token._id){
						tempin=true;
						setInPortfolio(true)
					}
				}
				if(!tempin && inPortfolio){
					setInPortfolio(false);
				}
			}
		}

	}, [ user, token ]);

	useEffect(() => {
		const checkIfClickedOutside = e => {
		  console.log(e.target.id);
		  if ( showCollectionsOptions && coptions.current && !coptions.current.contains(e.target) ) {
		    handleShowCollectionsOptions(false);
			handleCollectionModal(false);
			handleTokenListCollectionModal(false);
		  }
		}

		document.addEventListener("mouseup", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("mouseup", checkIfClickedOutside)
		}

	}, [showCollectionsOptions, handleCollectionModal]);

	const addToCollection = (c) => {
		if(tokenListCollectionModal){
			console.log(c)
			handleAddToTokenList(c,token);
		}else if(twitterUserListCollectionModal){
			handleAddToTwitterUserList(c, twitterSearchedUser);
		}else{
			handleAddToCollection(c, assetToAdd);
		}

	}

	let twitterUserListst = {
		"test": {
			name: 'alpha',
			color: '#3333bb'
		}

	}

	let list = tokenListCollectionModal ? tokenLists : twitterUserListCollectionModal ? twitterUserLists : collections;

	const addToWallet = async () => {
		try {
			// wasAdded is a boolean. Like any RPC method, an error may be thrown.
			const wasAdded = await window.ethereum.request({
				method: 'wallet_watchAsset',
				params: {
				  type: 'ERC20', // Initially only supports ERC20, but eventually more!
				  options: {
				    address: token.pair.data.baseToken.address, // The address that the token is at.
				    symbol: token.pair.data.baseToken.symbol, // A ticker symbol or shorthand, up to 5 chars.
				    decimals: token.decimals, // The number of decimals in the token
				    image: token.cg ? token.cg.image.small : '', // A string url of the token logo
				  },
				},
			});

		} catch (error) {
			console.log(error);
		}

	}

	const addToPortfolio = async () => {
		await addTokenToPortfolio(token);
		setTimeout(() => {
			fetchPortfolioTokens(walletAddress);
		},500)

	}
	// let inPortfolio = false;



    return (

		<div ref={coptions} style={ coy < window.screen.height/2 ? { left: cox+'px', top: coy+'px'} : { left: cox+'px', bottom: '55px'}} className={classNames("collections-options options-menu",coy < window.screen.height/2 ? "arrowtop" : "arrowbottom")}>
			<div className="options-header">
				<i className="icon-button-plus"/>
				add to
			</div>

			{tokenListCollectionModal && (
				<div
					//style={{ backgroundColor: collections[key].color }}
					className={classNames("option", inPortfolio && "disabled")}
					onClick={() => addToPortfolio()}

				>
					<div className="svg-icon">
						<WalletIcon/>
					</div>
					<p>portfolio</p>

					<div className="plus-sign">
						<i className="icon-button-plus"/>
					</div>
				</div>
			)}

			{adminWallet ? (
				<div
					//style={{ backgroundColor: collections[key].color }}
					className={classNames("option",( homeMeta.fmp?.token._id == token._id || homeMeta.featuredMetaToken?._id == token._id ) && "disabled" )}
					onClick={() => setTokenAsFeatured()}

				>
					
					<p>set as featured token</p>

					<div className="plus-sign">
						<i className="icon-button-plus"/>
					</div>
				</div>
			):null}



				<>


					{ Object.keys(list).map(( key, i ) => {

						let inc = false;

						if(tokenListCollectionModal){
							let ts = tokenLists[key].tokens;

							for(var k=0;k<ts.length;k++){

								if(ts[k].address){
									// console.log(ts[k].pair.data.baseToken.address,token.pair.data.baseToken.address)
									if(ts[k].address == token.pair.data.baseToken.address){
										inc = true;
									}
								}

							}
						}else if(twitterUserListCollectionModal){
							let ts = twitterUserLists[key].twitterUsers;

							for(var k=0;k<ts.length;k++){

								if(ts[k] == twitterSearchedUser._id){
									inc = true;
								}
							}

						}else{

							let cassets = collections[key].assets;
							for(var k=0;k<cassets.length;k++){
								if(cassets[k].ca == assetToAdd.asset_contract.address && cassets[k].token_id == assetToAdd.token_id){
									inc = true;
								}
							}
						}


						// if( !inc ){
							return (
								<div
									//style={{ backgroundColor: collections[key].color }}
									className={classNames("option", inc && "disabled")}
									onClick={() => addToCollection(list[key])}
									key={i}
								>
									<div style={{
										backgroundColor: list[key].color,
										borderColor: list[key].color,
										marginRight: 7
									}} className="color-box"/>
									<p>{list[key].name}</p>

									<div className="plus-sign">
										<i className="icon-button-plus"/>
									</div>
								</div>
							)
						// }
					}
					)}


				</>


				{tokenListCollectionModal && (
					<>
						<div
							//style={{ backgroundColor: collections[key].color }}
							className={classNames("option")}
							onClick={() => addToWallet()}

						>
							<div className="svg-icon">
								<WalletIcon/>
							</div>
							<p>wallet</p>

							<div className="plus-sign">
								<i className="icon-button-plus"/>
							</div>
						</div>

						{token.pair.isNewPair && (
							<div
		                        //style={{ backgroundColor: collections[key].color }}
		                        className={classNames("option")}
		                        onClick={() => hideToken(token)}

		                    >
		                        {/*<div className="svg-icon">
		                            <WalletIcon/>
		                        </div>*/}

								{!user.hideTokens.includes(token._id) ? (
									<>
										<p className="red">hide</p>
										<div className="plus-sign">
											<i className="icon-button-minus"/>
										</div>
									</>

								) : (
									<>
										 <p>unhide</p>
										<div className="plus-sign">
											<i className="icon-button-plus"/>
										</div>
									</>

								)}



		                    </div>

						)}

					</>
				)}


			<div className="arrow-up"/>
			<div className="arrow-down"/>


		</div>

    );
}
