import React, {useState, useEffect} from "react";

import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useNavigator } from "../../hooks/useNavigator";

import { DexPairCard } from "../Dex/DexPairCard";
import { TokenDataTabsInteractive } from "../Dex/TokenDataTabsInteractive";
import { TickerBlock } from "../../components/Dex/TickerBlock";
import { DexPairItem } from "../../components/Dex/DexPairItem";
import { ContractAddressToolbar } from "./ContractAddressToolbar";

import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";
import { ReactComponent as MicIcon } from '../../icons/svgs/mic.svg';

import xlogo from "../../icons/news/logo_x.png";
import ytlogo from "../../icons/news/logo_youtube.png";
import toast from 'react-simple-toasts';

import { dataTimeLabels } from "../../utils/tools";

var parse = require('html-react-parser');

export function ListPresentation({ list=null, listTitle=null, xSpaceToken=null, ytLiveToken=null, homePage=null }) {

    const {
        pageSize
    } = useCore();
    const { contractAddress, handleContractAddress, screens, pathname, handleSearchParams, searchParams } = useNavigator();

    const [listDataTime,setListDataTime] = useState('h24');
    const [listDataSort, setListDataSort ] = useState('desc');

    const [xSpace, setXSpace] = useState(null);

    const {
        walletAddress
    } = useWallet();

    useEffect(() => {

        if(xSpaceToken){
            setXSpace(xSpaceToken);
        }else{
            if(screens[1] !== undefined && screens[1] == "spaces"){
                if(screens[2] !== undefined){
                    
                    console.log(screens[2]);
    
                    setXSpace(screens[2]);
                    
                }
                
            }
        }
        
        
    }, [ ]);

    const onListTimeClick = (v) => {
        console.log(v, listDataTime, listDataSort);
        if(listDataTime == v){
            if(listDataSort=='desc'){
                setListDataSort('asc');
            }else{
                setListDataSort('desc');
            }
            
        }else{
            setListDataTime(v);
        }
        
    }

    const toggleListDataSort = () => {
        let fmls = '';
        if(listDataSort == "desc"){
            fmls = "asc";
        }else{
            fmls = "desc";
        }
        setListDataSort(fmls);
    }

    const onFeaturedTokenClick = () => {
        console.log('lets go please')
    }

    const copyAddress = () => {
        toast(parse('<span className="mute">link</span> copied'));
    }

    const xLink = `https://x.com/i/spaces/${list.xSpace}`;
    const ytLink = `https://youtube.com/watch?v=${list.ytLive}`;
    const listLink = `${process.env.REACT_APP_ARTEMIS_URL}/list/${list.slug}`;

    const openSpace = () => {
        window.open(
            `https://x.com/i/spaces/${list.xSpace}`,
            "_blank"
        )
    }

    const openYoutube = () => {
        window.open(
            `https://youtube.com/watch?v=${list.ytLive}`,
            "_blank"
        )
    }

    return (
        <>
            { list && list.showFeaturedToken && list.featuredToken ? (
                <div style={{margin: !walletAddress ? 22 : 7}} className="flex-column">
                    <div style={{marginBottom:7}} className="feature-card">
                        <div className="feature-card-title">
                            <div className="ticker-overlay"/>
                            <div style={{display:"flex"}} className="text-content">
                                <p>featured token</p>
                            </div>
                        </div>
                    </div>
                    <p>page width{pageSize.width}</p>

                    { pageSize.width < 720 ? (
                        <DexPairCard t={list.featuredToken}/>
                    ):(
                        <DexPairItem
                            withSpace={true}
                            rankAnimation={false}
                            hideRank={true}
                            passClick={onFeaturedTokenClick}
                            pairData={list.featuredToken.pair.data}
                            pair={list.featuredToken.pair}
                            // coreToken={token}
                            addClass={'no-margin'}
                        />
                    )}
                    
                    
                    
                </div>
            ):null}

            { list && list.tokens.length ? (
                
                <div style={{margin: !walletAddress ? 22 : 7}} className="flex-column">
                    <div className="feature-card">
                        <div style={{minHeight: 22}} className="feature-card-title">
                            <div style={{zIndex:-1}} className="ticker-overlay"/>
                            <div style={{paddingTop: list.tags?.length ? 4 : 4 }} className="text-content">
                                { listTitle ? (
                                    <p>{listTitle}</p>
                                ): list?.title? (
                                    <p>{list.title}</p>
                                ):null}
                                
                                {list.name ? (
                                    <div className="flex-row">
                                        <h6 style={{margin:0,marginRight:7}}>{list.name}</h6>
                                        { !homePage ? (
                                            <div style={{marginTop:-3,marginLeft:0,transform:'scale(.84)'}} className="coin-links small">
                                                <CopyToClipboard
                                                            text={listLink}
                                                            onCopy={copyAddress}
                                                            style={{cursor:"pointer"}}
                                                        
                                                >
                                                    <div onClick={null} data-tip={`copy link`} className="coin-link">

                                                        <div style={{fontSize:14}} className="icon-button-copy"/>

                                                    </div>
                                                </CopyToClipboard>

                                            </div>
                                        ):null}
                                        
                                        
                                    </div>
                                ):null}

                                { list.xSpace ? (
                                    <div style={{marginTop:3    }} className="flex-row">
                                        
                                        { list.live ? (
                                            <div style={{marginTop:6}} className="green-circle animated"/>
                                        ):null}
                                        <div data-tip={`open X space`} className="flex-row" style={{cursor:"pointer"}} onClick={openSpace}>
                                            <div  style={{width:19,height:19,opacity:.7}} >
                                                <img src={xlogo}/>
                                            </div>

                                            <div style={{marginTop:0,marginLeft:-2,width:15,height:15}}>
                                                <MicIcon/>
                                            </div>
                                        </div>
                                        
                                        <div style={{alignItems:'flex-start',marginLeft:3}} className="flex-row">
                                            <CopyToClipboard
                                                text={xLink}
                                                onCopy={copyAddress}
                                            >
                                                <div data-tip={`copy X space link`} style={{marginLeft:7,cursor: "pointer"}} className={classNames("flex-row address")}>
                                                    
                                                    <p style={{fontSize:11, color: "inherit"}} className="data-value currency contract">{list.xSpace}</p>
                                                </div>
                                            </CopyToClipboard>
                                            <div style={{marginTop:-3,marginLeft:7,transform:'scale(.84)'}} className="coin-links small">
                                                <CopyToClipboard
                                                    text={xLink}
                                                    onCopy={copyAddress}
                                                >
                                                    <div onClick={null} data-tip={`copy X space link`} style={{cursor:"pointer",padding:4}} className="coin-link">

                                                        <div style={{fontSize:14}} className="icon-button-copy"/>

                                                    </div>
                                                </CopyToClipboard>
                                            
                                                <div onClick={openSpace} data-tip={`open X space`} style={{cursor:"pointer",padding:4}} className="coin-link">
                                                    <div style={{fontSize:14}} className="icon-button-external-link"/>
                                                </div>
                                            </div>
                                        </div>
                                       

                                       
                                    </div>

                                ):null}


                                { list.ytLive ? (
                                    <div style={{marginTop: list.xSpace ? 0 : 3}} className="flex-row">
                                        
                                        { list.live ? (
                                            <div style={{marginTop:8,background:"red"}} className="green-circle animated delay"/>
                                        ):null}
                                        <div data-tip={`open Youtube`} className="flex-row" style={{cursor:"pointer"}} onClick={openYoutube}>
                                            <div style={{marginLeft:2,width:22,height:22,opacity:.7}} >
                                                <img src={ytlogo}/>
                                            </div>
                                        </div>
                                        
                                        <div style={{alignItems:'flex-start',marginLeft:3}} className="flex-row">
                                            <CopyToClipboard
                                                text={ytLink}
                                                onCopy={copyAddress}
                                            >
                                                <div data-tip={`copy Youtube link`} style={{marginLeft:7,cursor: "pointer"}} className={classNames("flex-row address")}>
                                                    
                                                    <p style={{fontSize:11, color: "inherit"}} className="data-value currency contract">{list.ytLive}</p>
                                                </div>
                                            </CopyToClipboard>
                                            <div style={{marginTop:-3,marginLeft:7,transform:'scale(.84)'}} className="coin-links small">
                                                <CopyToClipboard
                                                    text={ytLink}
                                                    onCopy={copyAddress}
                                                >
                                                    <div onClick={null} data-tip={`copy Youtube link`} style={{cursor:"pointer",padding:4}} className="coin-link">

                                                        <div style={{fontSize:14}} className="icon-button-copy"/>

                                                    </div>
                                                </CopyToClipboard>
                                            
                                                <div onClick={openYoutube} data-tip={`open Youtube`} style={{cursor:"pointer",padding:4}} className="coin-link">
                                                    <div style={{fontSize:14}} className="icon-button-external-link"/>
                                                </div>
                                            </div>
                                        </div>
                                       

                                       
                                    </div>

                                ):null}

                                <div style={{marginTop:7}} className="flex-row">
                                
                                
                                    <TokenDataTabsInteractive
                                        title="price"
                                        bookend={true}
                                        addStyle={{borderRadius:'4px',overflow:'hidden'}}
                                        passClick={onListTimeClick}
                                        buttonValue={listDataTime}
                                        buttonValues={['m5','h1','h6','h24']}
                                        values={ ['5m','1h','6h','24h']}
                                        valuesFormat={['string','string','string','string']}
                                        labels={['','','','']}
                                    />

                                    <div style={{borderRadius:'4px',overflow:'hidden', marginLeft:7}} className="data-row interactive-items">
                                        <div onClick={toggleListDataSort} style={{display:"flex",alignItems:'center',justifyContent:'center'}} className="data-item">
                                            <div className={listDataSort == "desc" ? "icon-arrow-down" : "icon-arrow-up"}/>
                                        </div>
                                    </div>

                                    { list.tokens.length > list.viewLimit ? (
                                        <div style={{marginLeft:11}} className="flex-row align center">
                                            <p>{list.viewLimit} <span className="mute">of</span> {`${list.tokens.length}`}</p>
                                        </div>
                                    ):null}
                                    
                                </div>
                                
                            </div>

                            { list.tags?.length ? (
                                <div style={{position:"absolute",top:4,right:0,zIndex:111}} className="flex-row">
                            
                                    { list.tags
                                
                                    .map((tag,i) => (
                                        
                                        <div key={i} style={{pointerEvents:'none', background: list.color }} className="social-item-tag hash"><span className="mute">#</span>{tag}</div>
                                        
                                    ))}

                                
                                </div>
                            ):null}

                            
                            
                        </div>

                        
                        
                    </div>

                    
                    { list.tokens.length ? (
                        <div className="feature-card-tokens">
                        
                            { list.tokens
                            .filter(n => n.pair?.data || n.defaultPair?.data )
                            .sort((a, b) => {
                                if(listDataSort == "desc"){
                                    return b.defaultPair.data.priceChange[`${listDataTime}`] > a.defaultPair.data.priceChange[`${listDataTime}`] ? 1 : -1;
                                }else{
                                    return b.defaultPair.data.priceChange[`${listDataTime}`] < a.defaultPair.data.priceChange[`${listDataTime}`] ? 1 : -1;
                                }
                            })
                            .slice(0, list.viewLimit)
                            .map((t,i) => (
                                
                                <DexPairCard key={`list${i}`} dataTime={listDataTime} dataTimeLabel={dataTimeLabels[`${listDataTime}`]} addStyle={ walletAddress ? {} : {pointerEvents:'none'}} t={t}/>
                                
                            ))}
                        </div>
                    ):null}
                    
                </div>
            ):null}
        </>
    );
}





