

//   ,--.           ,------.  ,------.,--.   ,--.
//  /    \,--.  ,--.|  .-.  \ |  .---' \  `.'  /
// |  ()  |\  `'  / |  |  \  :|  `--,   .'    \
//  \    / /  /.  \ |  '--'  /|  `---. /  .'.  \
//   `--' '--'  '--'`-------' `------''--'   '--'
//


import React from 'react';
import ReactDOM from 'react-dom/client';

import { ProvideAuth } from "./hooks/useAuth";
import { ProvideCore } from "./hooks/useCore";

import { CookiesProvider } from 'react-cookie';


import { getApp } from "./utils/helpers";

import './index.css';
import App from './App';
import Web3 from 'web3/dist/web3.min.js';

import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import reportWebVitals from './reportWebVitals';
import Div100vh from 'react-div-100vh';
import ReactGA from 'react-ga';

import './bi_polyfill';

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const GetApp = getApp();

function getLibrary(provider, connector) {
  return new Web3Provider(provider);
}

root.render(
        <Web3ReactProvider getLibrary={getLibrary}>
            <CookiesProvider>
                <ProvideAuth>
                    <ProvideCore>
                        <>
                            <GetApp/>
                        </>
                        {/*<SocketProvider client={io} uri={process.env.REACT_APP_URL_SOCKET}>
                            <ProvideWallet>
                                <ProvideDex>
                                    <ProvideSocial>
                                        <ProvideMarket>
                                            <ProvideExchange>
                                                <ProvideCollection>
                                                    <HelmetProvider>
                                                        <Div100vh>
                                                            <App />
                                                        </Div100vh>
                                                    </HelmetProvider>
                                                </ProvideCollection>
                                            </ProvideExchange>
                                        </ProvideMarket>
                                    </ProvideSocial>
                                </ProvideDex>
                            </ProvideWallet>
                        </SocketProvider>*/}
                    </ProvideCore>
                </ProvideAuth>
            </CookiesProvider>
        </Web3ReactProvider>

);

// root.render(
//   <React.StrictMode>
//     <Web3ReactProvider getLibrary={getLibrary}>
//         <ProvideAuth>
//             <ProvideCore>
//                 <ProvideWallet>
//                     <ProvideMarket>
//                         <HelmetProvider>
//                             <App />
//                         </HelmetProvider>
//                     </ProvideMarket>
//                 </ProvideWallet>
//             </ProvideCore>
//         </ProvideAuth>
//     </Web3ReactProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
