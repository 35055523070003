import React, { useEffect, useState, useRef } from "react";

import { DexPairItem } from "./DexPairItem";
import { CallItem } from "../Social/CallItem";
import { TokenDataTabsInteractive } from "./TokenDataTabsInteractive";
import { Portfolio } from "../../pages/Portfolio";
import { HomeMeta } from "../Layout/HomeMeta";
import { NewsItem } from "../Social/NewsItem";
import { useDex } from "../../hooks/useDex";
import { PairList } from "./PairList";
import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";

import { InputSelect } from "../../components/Input/InputSelect";

import classNames from "classnames";

import { useNavigator } from "../../hooks/useNavigator";

import { LoaderSpinner } from "../Layout/LoaderSpinner";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ReactComponent as Newspaper} from "../../icons/svgs/newspaper.svg";
import { ReactComponent as Seedling} from "../../icons/svgs/seedling.svg";
import { ReactComponent as Wallet} from '../../icons/svgs/wallet.svg';
import { ReactComponent as Rocket} from "../../icons/svgs/rocket.svg";
import { ReactComponent as Flame} from "../../icons/svgs/flame2.svg";
import { ReactComponent as Megaphone} from "../../icons/svgs/megaphone.svg";
import { ReactComponent as Marquee} from '../../icons/svgs/marquee.svg';
import { ReactComponent as QRCodeIcon} from "../../icons/svgs/qrcode.svg";

import { wrapLineBeforeBuy } from "../../utils/tools";
import { adminWalletList } from "../../utils/data";

import toast from 'react-simple-toasts';


var parse = require('html-react-parser');


export function DexPairItemList({ addStyle=null, addClass=null, height='100%'}) {

	const navigator = useNavigator();

	const featureContent = useRef(null);

	let {
		user,
		adminWallet
	} = useWallet();

	
	let {
		ethTrending,
		pageSize,
		calls,
		handleCalls,
		basePage,
		listPage,
		handleListPage,
		news,
		modalVisible,
		handleModalTitle,
		handleModalVisible,
		handleTokenListModal,
		handleQrurl,
		handleQrModal
	} = useCore();

	let {
        dexView,
        
        ethTrendingKey,
        handleEthTrendingKey,
        ethTrendingTokens,
        fetchEthTrendingTokens,
        token,
		pairs,
		handleDexView,
		listView,
		handleListView,
		tokenLists,
		listTokens,
		tokenList,
		newPairs,
		showTicker,
		handleShowTicker,
		loading
        // editFilters,
        // handleEditFilters,
        // tokenFilters,
    } = useDex();

	useEffect(() => {
		
		// console.log(ethTrending,ethTrending)
		if( ethTrending ){
			fetchEthTrendingTokens();
		}
		
	}, [ ethTrending, ethTrendingKey ]);

	useEffect(() => {
		
		scrollToInitial();	
		
	}, [ listView, ethTrendingKey ]);

	const scrollToInitial = () => {
		if (featureContent.current) {
		  featureContent.current.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const onTrendingChannelClick = (v) => {
        // setTrendingChannel(v);
        handleEthTrendingKey(v);
		toggleListView("trending",false,v);
    }

	const onDexTokenClick = (p) => {
        // handleTokenPair(p);
        // // handleTokenToolbarVisible(true);
        // closeModal();
    }

	const toggleShowTicker = () => {
		handleShowTicker(!showTicker);
	}

	const handleEditList = () => {
		handleModalVisible(true);
		handleModalTitle("Edit List");
		handleTokenListModal("edit");
	}

	const openQRModal = (e) => {
		e.preventDefault();
        e.stopPropagation();
        // handleModalTitle(nft.name + ' - #' + nft.token_id);
        // let qu = `${process.env.REACT_APP_ARTEMIS_URL_IP}/assets/${ca}/${nft.token_id}`;
        // console.log(qu);
        // handleQrurl(qu);

        let mt = "0xDΞX_list";
        console.log(mt);
        handleModalTitle(mt);


        handleQrurl(`${process.env.REACT_APP_ARTEMIS_URL}/list`);


        handleModalVisible(true);
        handleQrModal(true);
	}

	const toggleListView = (view, slug=null, tc=null) => {
		
		handleListView(view);

		if(slug){
			handleListPage(slug);
		}else{
			handleListPage(null);
		}
		if(view == "homeasdf"){
			navigator.push(`/`);
		}else{
			if(token){
				if(slug){
					navigator.push(`/${token.pair?.chainId}/${token.pair?.address}?view=${view}&list=${slug}`);
				}else{
					navigator.push(`/${token.pair?.chainId}/${token.pair?.address}?view=${view}${tc ? '&tc=' + tc : ''}`);
				}
				
			}else{
				if(view == "home"){
					navigator.push(`/`);
				}else if(slug){
					navigator.push(`/${view}/${slug}`);
				}else{
					navigator.push(`/${view}${tc ? '/' + tc : ''}`);
				}
				
			}
		}
		
		
	}

	const onTokenListChange = (v) => {

		
		if(v == "new" && (tokenLists.length < 3 || adminWallet)){
			handleCreateList();
		}else{
			handleListView("list");

			if(token){
				navigator.push(`/${token.pair.chainId}/${token.pair.address}?view=list&list=${v}`);
			}else{
				navigator.push(`/list/${v}`);
			}

			toggleListView("list",v);
		}
		
		
	}

	const listSelectorClick = (e) => {
		if(!tokenList?.slug){
			if(tokenLists[0] !== undefined){
				toggleListView("list",tokenLists[0].slug);
			}
			
		}else{
			toggleListView("list",tokenList?.slug);
		}
		// handleListView("list");
		// toggleListView("list",tokenList?.slug);
	}

	const handleCreateList = () => {
		handleModalVisible(true);
		handleModalTitle("Create List");
		handleTokenListModal("create");
	}

	const onTokenListFocus = () => {
		if(tokenLists[0] == undefined){
			handleCreateList();
		}
	}

	const tListstemp = tokenLists.map((t, i) => {
        return `${t.name} (${t.tokens.length})`;
    });
    const tListsValuestemp = tokenLists.map((t, i) => {
        return t.slug;
    });

	let tLists = [
		...tListstemp,
		"++ new list ++"
	];

	let tListsValues = [
		...tListsValuestemp,
		"new"
	]

	const copyLink = () => {
        toast(parse('<span className="mute">link</span> copied'));
    }


    return (
        <>

			<div style={addStyle && addStyle} className={classNames("feature-panel", addClass && addClass)}>
				
				{/* { loading.trending ? (
					<LoaderSpinner/>
				):null} */}
				
				
				<div style={pageSize ? { maxWidth: pageSize.width } : {} } className="feature-title flex-row justify space-between">
					{/* <h1>{basePage}{listPage}</h1> */}
					<div>
						<div style={{alignItems:'center', maxWidth: pageSize.width}} className={classNames("coin-links bw list-nav")}>

							<div onClick={() => { toggleListView("home") } } data-tip="home" style={{maxWidth:120}} className={classNames("coin-link", listView == "home" && 'active')}>
								<div style={{width:14,height:14}} className="svg-icon">
									<div style={{fontSize:14}} className="icon-button-home"/>
								</div>
							</div>

							


							<div onClick={() => { toggleListView("portfolio") } } data-tip="portfolio" style={{maxWidth:120}} className={classNames("coin-link", listView == "portfolio" && 'active')}>
								<div style={{marginRight:7,transform:'scale(.8)'}} className="svg-icon">
									<Wallet/>
								</div>
								<p style={{margin:0,fontSize:13,fontWeight:'600',marginRight:7}}>portfolio</p>
								{ user && user.portfolioTokens !== undefined && (
									<div className="count-circle" style={user?.portfolioTokens?.length < 1 ? {  filter: 'saturate(0)', opacity: .3 } : {}}>
										{user.portfolioTokens.length}
									</div>
								)}
							</div>

							<div onClick={listSelectorClick} style={{marginLeft:7}} className={`flex-row align center`}>
								<div style={{fontSize:14}} className="icon-button-list"/>
								<div className="color-box" style={{marginLeft:7,marginRight:0,backgroundColor: tokenList?.color ? tokenList.color : "#444",borderColor: tokenList?.color ? tokenList.color : "#444"}}/>
								<InputSelect
									title={null}
									onChange={onTokenListChange}
									value={tokenList?.slug || null}
									options={tLists}
									optionsValues={tListsValues}
									onFocus={onTokenListFocus}
									addClass={`button-icon-select xsmall ${listView == "list" ? "active" : ""}`}
									addStyleInputGroup={{padding:"0 8px", minWidth:144}}
									noChangeValues={['new']}
									
								/>
							</div>

							<div onClick={() => { toggleListView("news") } } data-tip="news" style={{maxWidth:120}} className={classNames("coin-link", listView == "news" && 'active')}>
								<div style={{marginRight:7,width:14,height:14}} className="svg-icon">
									<Newspaper/>

								</div>
								<p style={{margin:0,fontSize:13,fontWeight:'600'}}>news</p>
							</div>

							<div onClick={() => { toggleListView("trending") } } data-tip="trending" style={{maxWidth:120}} className={classNames("coin-link", listView == "trending" && 'active')}>
								<div style={{marginRight:7,marginTop:2,width:18,height:18}} className="svg-icon large">
									<Flame/>

								</div>
								<p style={{margin:0,fontSize:13,fontWeight:'600'}}>trending</p>
							</div>

							<div onClick={() => { toggleListView("calls") } } data-tip="calls" style={{maxWidth:120}} className={classNames("coin-link", listView == "calls" && 'active')}>
								<div style={{marginRight:7,width:14,height:14,transform:'scaleX(-1)'}} className="svg-icon no-fill">
									<Megaphone/>

								</div>
								<p style={{margin:0,fontSize:13,fontWeight:'600'}}>calls</p>
							</div>

							<div onClick={() => { toggleListView("newpairs") } } data-tip="new pairs" style={{minWidth:108,marginRight:14}} className={classNames("coin-link", listView == "newpairs" && 'active')}>
								<div style={{marginRight:7}} className="svg-icon">
									<Seedling/>
								</div>
								<p>new pairs</p>
							</div>

							
							


							{/* { tokenLists.map((l,i) => (

								<div key={i} onClick={() => { toggleListView("list",l.slug) } } data-tip={`${l.name}`} style={{maxWidth:144}} className={classNames("coin-link", listView == "list" && listPage == l.slug && 'active')}>
									<div style={{fontSize:14}} className="icon-button-list"/>
									<div className="color-box" style={{marginLeft:7,marginRight:7,backgroundColor: l.color,borderColor: l.color}}/>

									<p style={{margin:0,fontSize:13,fontWeight:'600',marginRight:7}}>{l.name}</p>
									<div className="count-circle" style={l.tokens.length < 1 ? {  filter: 'saturate(0)', opacity: .3 } : {}}>
										{l.tokens.length}
									</div>
								</div>


							))} */}


						</div>


					

					</div>

					{ !token ? (
						<div className="coin-links">
							<div onClick={() => { toggleShowTicker() } } data-tip={!showTicker ? "show marquee" : "hide marquee"} style={{maxWidth:120}} className={classNames("coin-link", showTicker && 'active')}>
								<div style={{width:14,height:14}} className="svg-icon">
									<Marquee/>
								</div>
							</div>
						</div>
					):null}
						
				
				</div>

				
				{ listView == "list" && listPage && tokenList ? (
					<div className="list-title">
						<div style={{marginLeft:4,fontSize:14}} className="icon-button-list"/>
						<div style={{
							marginLeft:7,
                            backgroundColor: tokenList.color,
                            borderColor: tokenList.color
                            }} className="color-box"></div>
						<div style={{marginLeft:7,marginRight:7}} className="data-value">
							{tokenList.name}
						</div>
						<div className="count-circle">{tokenList.tokens.length}</div>

						<div style={{marginTop:0,marginLeft:3,transform:'scale(.84)'}} className="coin-links small">
							<CopyToClipboard
								text={`${process.env.REACT_APP_ARTEMIS_URL}/list/${tokenList.slug}`}
								onCopy={copyLink}
								style={{cursor:"pointer"}}
									
							>
								<div onClick={null} data-tip={`copy link`} className="coin-link">

									<div style={{fontSize:14}} className="icon-button-copy"/>

								</div>
							</CopyToClipboard>

						</div>

						<div style={{marginLeft:11}} className="coin-links">

							<div data-tip={`edit list`} onClick={handleEditList} className="coin-link">
								<div style={{fontSize:20}} className="icon-pencil"/>
							</div>
							<div data-tip={`QR code`} onClick={openQRModal} className="coin-link">
								<div className="svg-icon">
                                     <QRCodeIcon/>

                                </div>
							</div>

							
						</div>
					</div>
				):null}
				
				{ listView == "trending" ? (
					<div style={{width: '100%', background: '#09131587'}}>
						<TokenDataTabsInteractive
							title="trending"
							passClick={onTrendingChannelClick}
							buttonValue={ethTrendingKey}
							buttonValues={['baset','bt','sg','mt','tg','dt','mtsol','mtbase','soltbb','soltbirdy']}
							values={ ["Base","BuyTech","SafeGuard","Moontok","Trend Guru","DexTools Hot Pairs","Moontok SOL","Moontok Base","SOL","$BIRDY"]}
							showValues={false}
							images={ [
								require(`../../icons/trending/logo_baset.png`),
								require(`../../icons/trending/logo_bt.png`),
								require(`../../icons/trending/logo_sg.png`),
								require(`../../icons/trending/logo_mt.png`),
								require(`../../icons/trending/logo_tg.png`),
								require(`../../icons/trending/logo_dt.png`),
								require(`../../icons/trending/logo_mtsol.png`),
								require(`../../icons/trending/logo_mtbase.png`),
								require(`../../icons/trending/logo_soltbb.png`),
								require(`../../icons/trending/logo_soltbirdy.png`)
								
							] }
							subImages={ [
								null,
								null,
								null,
								require(`../../icons/logo_chain_ethereum.png`),
								null,
								null,
								require(`../../icons/logo_chain_solana.png`),
								require(`../../icons/logo_chain_base.png`),
								null,
								require(`../../icons/logo_chain_solana.png`),
							]}
							valuesFormat={['string', 'string', 'string', 'string','string','string','string','string','string','string']}
							addStyle={{borderRadius:0,borderBottom:"1px solid #1d1d1d77", maxWidth:999,marginTop:40}}
							addClass="active-underline trending-nav"
							// labels={['5m','1h','6h','24h']}
						/>
					</div>
				):null}
				
				


				
				
				{ listView == "newpairs" ? (
					<PairList visible={true} height={height} pairs={pairs}/>

				):null}
			

				
				

				{ listView !== "newpairs" ? (
					<div ref={featureContent} style={listView == "newpairs" || listView == "trending" ? {paddingBottom: 23} : {padding:'44px 4px 44px 4px'}} className="feature-content">

						{ listView == "portfolio" ? (
							<Portfolio noTitle={true}/>
						):null}

						{ listView == "list" && listPage && listTokens.length ? (
							<>
								
								{ listTokens.filter(n => n.pair?.data).map((t,i) => (
									<DexPairItem
										key={`list${i}`}
										i={i}
										withSpace={true}
										// view="portfolio"
										hideRank={true}
										passClick={onDexTokenClick}
										removeOption="list"
										pairData={t.pair?.data}
										token={t}
										list={tokenList}
										pair={null}
										coreToken={token}
									/>

								))}

								
							</>
						):(
							<></>
						)}

						

						{ listView == "calls" ? (
									
							<>
								{ calls.map((c,i) => (
									<CallItem key={`call${i}`} item={c} />
								))}
							</>
							
						):null}

						{ listView == "home" ? (
							<HomeMeta/>
						):null}
						
						

						{ listView == "news" ? (


							<div className="grid-container">
								{ news ? (
									<>
										{news.filter(n => n.message?.length).map((t, i) => (

											<NewsItem addClass={'grid-item'} item={t} key={`ni${i}`}/>

										))}
									</>
								):null}

							</div>
						):null}

						{/* { listView=="trending" && ethTrending && ethTrendingTokens[ethTrendingKey] !== undefined ? (
							<>
								<PairList pairs={ethTrendingTokens[ethTrendingKey]}/>
							</>

						):null} */}


						{ listView=="trending" && ethTrending && ethTrendingTokens[ethTrendingKey] !== undefined ? (
							<>
								{ethTrendingTokens[ethTrendingKey].map((tp,i) => {
									if(tp.address && tp.data ){
										return (
											<DexPairItem
												key={`tdpi${i}`}
												withSpace={true}
												i={i}
												rankAnimation={false}
												passClick={onDexTokenClick}
												pairData={tp.data}
												pair={tp}
												coreToken={token}
											/>
										)
									}else{
										return (
											<p>{tp.address}</p>
										)
									}
								}

								)}
							</>

						):null}

						
						

					</div>
				):null}
				
			</div>



        </>
    );
}
