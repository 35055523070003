import React from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";

import classNames from "classnames";

import { addCommas } from "../../utils/tools";

var parse = require('html-react-parser');



export function TokenDataTabsInteractive({ passClick, buttonValue, buttonValues, values, showValues=true, valuesFormat, labels=[], images=[], subImages=[], addClass, addStyle=null, bookend=null }) {

    const handleClick = (v) => {
        passClick(v);
    }

    return (
        <div style={addStyle ? addStyle : {}} className={classNames("data-row interactive-items bg flex-row justify space-around", bookend && "bookend", addClass && addClass)}>
            { buttonValues.map(( v, i) => (
                <div key={`ti${i}`} data-tip={`${labels[i] || values[i]}`} onClick={() => handleClick(v)} style={{minWidth: `${(100/buttonValues.length)}%`}} className={classNames("data-item", v == buttonValue && 'active' )}>

                {images && images[i] !== undefined  ? (
                    <div className="data-tab-image-container">
                        <div className="data-tab-image"><img src={images[i]}/></div>
                    </div>
                ):null}

                {subImages && subImages[i] !== undefined && subImages[i]  ? (
                    <div style={{position:"absolute",width:'17px',right:3,top:3}} className="data-tab-image-container">
                        <div className="data-tab-image"><img src={subImages[i]}/></div>
                    </div>
                ):null}
                    { labels[i] !== undefined ? (
                        <div className="data-title">{labels[i]}</div>
                    ):null}
                    
                    { showValues ? (

                        <div style={{
                            fontSize: Math.abs(values[i])>=10000 ? '.7em' : Math.abs(values[i])>=1000 ? '.8em' : '13px',
                            marginTop: Math.abs(values[i])>=10000 ? 2 : Math.abs(values[i])>=1000 ? 1 : 0
                        }} className="data-value percent">
                            { valuesFormat[i] == "percent" ? (
                                <>
                                    { (values[i] + '').indexOf('e+') > -1 ? (
                                        <>

                                        </>

                                    ) : (
                                        <>
                                            {values[i] < 777777 ? parse(formatPercent(String(values[i]))) : "--"}
                                        </>

                                    )}

                                </>
                            ) : valuesFormat[i] == "currency" ? (
                                <>
                                    {parse(condenseZeros(formatCurrency(values[i], "USD")))}
                                </>
                            ) : valuesFormat[i] == "currencymin" ? (
                                <>
                                    {parse(formatCurrencyScale(values[i]))}
                                </>
                            ) : (
                                <>
                                    {addCommas(values[i])}
                                </>
                            )}

                        </div>
                    ):null}

                    {/*{images && images[i] !== undefined  ? (
                        <div className="data-tab-image-container">
                            <div className="data-tab-image"><img src={images[i]}/></div>
                        </div>
                    ):null}*/}


                </div>
            ))}
        </div>
    );
}
