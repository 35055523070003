import React, { useState, useEffect, useContext, createContext } from "react";
import Cookies from "js-cookie";

import { client } from "../../services/api";
import { client_twitter } from "../../services/api_twitter";


import { useWallet } from "../useWallet";
import { useCore } from "../useCore";
import { useDex } from "../useDex";

import { isInArray } from "../../utils/tools";

import toast from 'react-simple-toasts';

import useSocketContext from '../useSocketContext';

const socialContext = createContext();

function useProvideSocial() {

    const { walletAddress, user, handleUser } = useWallet();
    const {
        sidebarRight,
        handleSidebarRight,
        handleModalVisible,
        dexSearchModal,
        handleDexSearchModal
    } = useCore();

    const { handleDexSearchPairs, dexSearchPairs, handleScreenClickDims } = useDex();

    const { emit } = useSocketContext();


    const [view, setView] = useState("panel");

    // const [loading, setLoading] = useState(!!authToken);
    const [loadingSocial, setLoadingSocial] = useState(false);

    const [twitterUsers, setTwitterUsers] = useState([]);
    const [twitterUser, setTwitterUser] = useState({});

    const [twitterUserLists, setTwitterUserLists] = useState([]);
    const [listSlug, setListSlug] = useState(null);

    const [cashtags, setCashTags] = useState([]);
    const [tagRoll, setTagRoll] = useState(null);

    const [ timeline, setTimeline] = useState(null);
    const [ timeFilter, setTimeFilter ] = useState('3');

    const [twitterSearchedUser, setTwitterSearchedUser ] = useState(null);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //     setDexTimer(dexTimer + 1);
    //     }, 1000);
    //     console.log(dexTimer);
    //     if(dexTimer > 5){
    //         setDexTimer(0);
    //     }
    //     return () => clearInterval(interval)
    // }, [dexTimer]);

    useEffect(() => {
        // console.log(user)

        // if(user && !twitterUserLists.length){
        //     fetchTwitterUserLists(user.walletAddress);
        //     emit('PING', { wa: walletAddress });
        // }


        // if(user && user._id && user.twitterUsers.length !== twitterUsers.length){
        //     fetchTwitterUsers();
        //     console.log(user);
        //     // setTwitterUsers(user.twitterUsers);
        // }




        // setTwitterUserLists(user.twitterUserLists);


    }, [ user ]);


    async function fetchTwitterUserLists(wa){
        let tls = await client.getTwitterUserLists(wa);
        let l = tls.data;
        let twlists = {};
        for(var i=0;i<l.length;i++){
            twlists[l[i].slug] = {...l[i]};
        }
        // console.log(twlists)
        setTwitterUserLists(twlists);

        // console.log('get twitter user lists success ?? >>>>>>>>>>>>>>>>>>>>',tls.data);
    }

    async function fetchTwitterUsers(u){
        setLoadingSocial(true);
        let d = {
            wa: walletAddress,
            twuids: user.twitterUsers
        }

        const twusers = await client.getTwitterUsers(d);
        console.log(twusers)
        setTwitterUsers(twusers.data);
        setLoadingSocial(false);
        // console.log(twusers)
        // setProfile(p.data);
    }


    async function fetchTwitterUserByUsername(u, search=true){
        setLoadingSocial(true);
        let d = {
            wa: walletAddress,
            un: u,
            addToList: false,
        }

        const twuseradd = await client.getTwitterUserByUsername(d);
        console.log(twuseradd)

        const twuser = twuseradd.data;

        // const twuser = await client.getTwitterUserByUsername(d);
        // console.log(twuser);

        setTwitterUser(twuser);
        setLoadingSocial(false);
        // console.log(twusers)
        // setProfile(p.data);
    }

    async function searchTwitterUsers(q){
        setLoadingSocial(true);

        const userSearch = await client.searchTwitterUsers(q);
        console.log(userSearch)
        if(userSearch.data.error !== undefined){
            toast(userSearch.data.error.detail)
        }else{
            setTwitterSearchedUser(userSearch.data);
        }
        // console.log(userSearch);


        // setTwitterUser(twuser);
        setLoadingSocial(false);

    }


    async function fetchTwitterUsersByList(s){
        setLoadingSocial(true);

        const users = await client.getTwitterUsersByList(s);
        console.log(users);
        setListSlug(s);
        let twur = [...users.data];
        let twu = [...twitterUsers];

        for(var i=0;i<twu;i++){
            if(!isInArray(twur,'_id',twu[i])){
                twur.push(twu[i]);
            }
        }
        // let twu = {...twitterUsers};
        // for(var i=0;i<twur.length;i++){
        //     if(twu[twur[i]] == undefined){
        //         twu[twur[i]._id] = {...twur[i]};
        //     }
        // }
        console.log(twur)
        setTwitterUsers(twur);

        // setTwitterUser(twuser);
        setLoadingSocial(false);

    }

    async function fetchConversation(id){
        setLoadingSocial(true);

        const convo = await client.getTwitterPostConversation(id);

        setLoadingSocial(false);
    }


    async function handleAddTwitterUsers(u){
        setLoadingSocial(true);
        let d = {
            wa: walletAddress,
            un: u,
            addToList: true
        }

        const addu = await client.addTwitterUsers(d);

        if(!addu.data){

        }else{
            if(addu.data.status == 500){
                toast('user has no tweets');
            }else if(addu.data.success){
                handleUser(addu.data.user);
                console.log(addu.data.user);
                for(var i=0;i<addu.data.usernames.length;i++){
                    toast(addu.data.usernames[i] + ' added');
                }

            }else{
                toast(addu.data.msg);
            }
        }

        console.log(addu)
        setLoadingSocial(false);
        // setProfile(p.data);
    }

    async function handleAddToTwitterUserList(l, twu){
        let d = {
            wa: walletAddress,
            lid: l._id,
            twuid: twu._id,
        }
        let listupdate = await client.addTwitterUserToList(d);
        console.log(listupdate);
        toast(`added to ${listupdate.data.name}`);
        fetchTwitterUserLists(walletAddress);

    }

    async function removeFromTwitterUserList(twu){
        let d = {
            wa: walletAddress,
            lslug: listSlug,
            twuid: twu._id,
        }
        let twua = [];
        for(var i=0;i<twitterUsers.length;i++){
            if(twitterUsers[i]._id !== twu._id){
                twua.push(twitterUsers[i])
            }
        }
        setTwitterUsers(twua);
        let listupdate = await client.removeTwitterUserFromList(d);
        console.log(listupdate);
        toast(`removed from ${listupdate.data.name}`);
        fetchTwitterUserLists(walletAddress);

    }


    async function updateTwitterUserList(ud){
        let lu = await client.updateTwitterUserList(ud);
        console.log(lu);
    }



    const handleTagRoll = (v) => {
        setTagRoll(v);
    }


    const handleView = (v) => {
        setView(v);
    }

    const handleTimeFilter = (v) => {
        setTimeFilter(v);
    }

    const handleTimeline = (v) => {
        setTimeline(v);
    }

    const handleTwitterUserLists = (tls) => {
        setTwitterUserLists(tls);
    }

    const handleTwitterUsers = (u) => {
        setTwitterUsers(u);
    }



    // async function getTwitterUsers(u){
    //     let d = {
    //         twuserids: u.twitterUsers
    //     }
    //     console.log(d)
    //
    //     const p = await client.getTwitterUsers(d);
    //     setTwitterUsers(p.data);
    //
    //
    //     console.log(p.data)
    //     // setProfile(p.data);
    // }





    // Return the user object and auth methods
    return {
        view,
        handleView,
        loadingSocial,
        twitterUsers,
        searchTwitterUsers,
        twitterUserLists,
        listSlug,
        handleAddToTwitterUserList,
        removeFromTwitterUserList,
        fetchTwitterUserByUsername,
        fetchTwitterUsersByList,
        updateTwitterUserList,
        handleTwitterUserLists,
        handleTwitterUsers,
        // twitterUsers,
        cashtags,
        tagRoll,
        handleTagRoll,
        timeline,
        handleTimeline,
        timeFilter,
        handleTimeFilter,
        searchTwitterUsers,
        twitterSearchedUser,
        twitterUser,
        fetchConversation
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideSocial({ children }) {
    const social = useProvideSocial();
    return <socialContext.Provider value={social}>{children}</socialContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSocial = () => {
    return useContext(socialContext);
};
